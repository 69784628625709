import { Box, Flex, Icon, Image, Text, useBreakpointValue } from '@chakra-ui/react'
import { LuGripVertical } from 'react-icons/lu'
import { DraggableProvided } from 'react-beautiful-dnd'
import { useStorage } from 'hooks'
import { getFileIconFromNodeContent, extractFilename, formatBytes } from 'helpers/utils'
import { TiptapEditor, AudioPlayer, VideoPlayer, IconButton, PDFViewer } from 'components'
import { icons, images } from 'theme'
import { Block, BlockUpdateInput, BlockType, User } from 'types/graphqlSchema'
import { BlockInfoBanner, blockInfoBannerStyles } from './BlockInfoBanner'
import BlockTitle from './BlockTitle'
import BlockDeleteBtn from './BlockDeleteBtn'

type BlockBodyProps = {
  block: Block
  user: User
  lastBlock: boolean
  isHovered: boolean
  isReadOnly?: boolean
  onRemoveBlock: (blockId: string) => void
  onBlockClick: (block: Block) => void
  onTextBlockChange?: (input: BlockUpdateInput) => void
  onTextBlockBlur?: (input: BlockUpdateInput) => void
  setShowSavingIndicator?: React.Dispatch<React.SetStateAction<boolean>>
  provided?: DraggableProvided
}

const BlockBody = ({
  block,
  user,
  lastBlock,
  isHovered,
  isReadOnly,
  onRemoveBlock,
  onBlockClick,
  onTextBlockChange,
  provided,
}: BlockBodyProps) => {
  const { downloadBlockFile } = useStorage()
  const isMobile = useBreakpointValue({ base: true, md: false })
  const isTablet = useBreakpointValue({ base: false, sm: true, md: false })
  const extension = block.extension
  const extensionIcon = getFileIconFromNodeContent(block)
  const visibleNameChars = isTablet ? 23 : 10
  const name = extractFilename(block.content)

  switch (block.type) {
    case BlockType.Text:
      return (
        <>
          <TiptapEditor
            content={block.content}
            editable={!isReadOnly}
            autofocus={!block.content && lastBlock}
            onChange={(value) => {
              if (!value || !onTextBlockChange) return
              onTextBlockChange({ id: block.id, content: value })
            }}
            onBlur={() => {}}
            onRemove={() => onRemoveBlock(block.id)}
          />
        </>
      )
    case BlockType.Image:
      return (
        <Flex flexDir="column" pos="relative" align="center" minH="4.15rem">
          <BlockInfoBanner
            block={block}
            user={user}
            name={name}
            extensionIcon={extensionIcon}
            isReadOnly={isReadOnly}
            isMobile={isMobile}
            isHovered={isHovered}
            downloadBlockFile={downloadBlockFile}
            onRemoveBlock={onRemoveBlock}
            provided={provided}
          />
          <Image
            alt="myImage"
            src={typeof block.contentSecureUrl === 'string' ? block.contentSecureUrl : undefined}
            objectFit="contain"
            borderRadius={6}
            px={'1px'}
            cursor={'pointer'}
            onClick={() => onBlockClick(block)}
          />
        </Flex>
      )
    case BlockType.Audio:
      return (
        <Flex flexDir="column" pos="relative" align="center" minH="4.15rem">
          <BlockInfoBanner
            block={block}
            user={user}
            name={name}
            extensionIcon={extensionIcon}
            isReadOnly={isReadOnly}
            isMobile={isMobile}
            isHovered={isHovered}
            downloadBlockFile={downloadBlockFile}
            onRemoveBlock={onRemoveBlock}
            provided={provided}
          />
          <AudioPlayer id={block.id} src={block.contentSecureUrl || ''} />
        </Flex>
      )
    case BlockType.Video:
      return (
        <Flex flexDir="column" pos="relative" borderRadius={6} minH={'4.15rem'}>
          <BlockInfoBanner
            block={block}
            user={user}
            name={name}
            extensionIcon={extensionIcon}
            isReadOnly={isReadOnly}
            isMobile={isMobile}
            isHovered={isHovered}
            downloadBlockFile={downloadBlockFile}
            onRemoveBlock={onRemoveBlock}
            provided={provided}
          />
          <VideoPlayer url={block.contentSecureUrl || ''} width="100%" height="100%" />
        </Flex>
      )
    case BlockType.File:
      if (extension === 'pdf' || block.preview) {
        return (
          <Flex flexDir="column" pos="relative" align="center" minH="4.15rem" h="82.5svh">
            <BlockInfoBanner
              block={block}
              user={user}
              name={name}
              extensionIcon={extensionIcon}
              isReadOnly={isReadOnly}
              isMobile={isMobile}
              isHovered={isHovered}
              downloadBlockFile={downloadBlockFile}
              onRemoveBlock={onRemoveBlock}
              provided={provided}
            />
            {block.contentSecureUrl && <PDFViewer url={block.contentSecureUrl} height="full" />}
          </Flex>
        )
      } else {
        return (
          <Flex {...blockInfoBannerStyles}>
            <BlockTitle
              name={extractFilename(block.content)}
              isMobile={isMobile}
              visibleNameChars={visibleNameChars}
              extensionIcon={extensionIcon}
            />
            {provided && block?.owner?.id === user?.id && (
              <Box {...provided.dragHandleProps} pos="absolute" right={'50%'} display="grid" placeContent="center">
                <Icon as={LuGripVertical} h="1.25rem" w="1.25rem" />
              </Box>
            )}
            <Flex align="center" gap={'1.25rem'} justify={'flex-end'}>
              {block.size && <Text>{formatBytes(parseFloat(block.size.toString()), 2)}</Text>}
              <button onClick={() => downloadBlockFile({ ...block, name })}>
                <img src={images.download} alt="download file" />
              </button>
              {block?.owner?.id === user?.id && (
                <IconButton
                  isDisable={user.id !== block.owner?.id}
                  variant="iconBtn"
                  ariaLabel="Remove"
                  icon={icons.close}
                  onClick={() => onRemoveBlock(block.id)}
                />
              )}
            </Flex>
          </Flex>
        )
      }
    default:
      return (
        <Flex {...blockInfoBannerStyles}>
          <BlockTitle
            name={extractFilename(block.content)}
            isMobile={isMobile}
            visibleNameChars={visibleNameChars}
            extensionIcon={extensionIcon}
          />
          {!isReadOnly && <BlockDeleteBtn block={block} onRemoveBlock={onRemoveBlock} user={user} />}
        </Flex>
      )
  }
}

export default BlockBody
