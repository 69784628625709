import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Box, Spinner, useDisclosure } from '@chakra-ui/react'
import { Draggable } from 'react-beautiful-dnd'
import { useClipboard } from 'hooks'
import { NodeDetailsInfo, useNodeMenuActions } from 'features/node'
import { useAppSlice, useProjectSlice, useBoxSlice, useTipSlice, useLinkShareSlice } from 'features/redux'
import { TagsModal } from 'features/tags/components'
import { menuActions, menuBox, updateValues } from 'helpers/constant'
import CONFIG from 'helpers/config'
import { getResizedImage, uploadFile } from 'helpers/storage'
import {
  ModalExternalButton,
  UpdateModal,
  ShareModalV2,
  UpdateCoverImageOrColorModal,
  Toast,
  MoveToMenu,
  AlertWithAction,
} from 'components'
import { BoxGridCard, BoxListCard } from './components'
import { EntityType, Node } from 'types/graphqlSchema'
import { minDelay } from 'helpers/utils'

interface BoxCardProps {
  boxInfo: Node
  alerts: number
  pageType?: string
  noBorder?: boolean
  index: number
  shouldBoxReorder?: boolean
  setDroppingIn?: any
  dragEnterIndex?: number
  setDragEnterIndex?: React.Dispatch<React.SetStateAction<number | undefined>>
  draggingTip?: string
  setDraggingTip?: React.Dispatch<React.SetStateAction<string>>
  startIndex?: any
  setStartIndex?: any
  setDuplicatingFolder: React.Dispatch<React.SetStateAction<string>>
  setDeletingFolder: React.Dispatch<React.SetStateAction<string>>
  deletingFolder?: string
}

const BoxCard = ({
  boxInfo,
  pageType,
  index,
  noBorder,
  shouldBoxReorder,
  setDragEnterIndex,
  dragEnterIndex,
  setDroppingIn,
  draggingTip,
  setDraggingTip,
  startIndex,
  setStartIndex,
  setDuplicatingFolder,
  setDeletingFolder,
  deletingFolder,
}: BoxCardProps) => {
  const {
    openModal,
    setOpenModal,
    updateNode,
    deleteNode,
    updateFollowedNode,
    duplicateNode,
    downloadNode,
    createUrlPath,
    handleLeaveSharedNode,
  } = useNodeMenuActions()
  const {
    isOpen: isOpenLeaveSharedNode,
    onOpen: onOpenLeaveSharedNode,
    onClose: onCloseLeaveSharedNode,
  } = useDisclosure()
  const { isOpen: isOpenDeleteNode, onOpen: onOpenDeleteNode, onClose: onCloseDeleteNode } = useDisclosure()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { copyToClipBoard } = useClipboard()
  const {
    dispatch,
    viewType,
    user: { followedNodes },
    selectedNode,
    popupMenuBoxes,
    setIsLoading,
    setPopupMenuBoxes,
  } = useAppSlice()
  const { currentProject, addNewChildtoProjectBox, getProject } = useProjectSlice()
  const { boxes, getBox, clearBoxes, moveBox, setBoxes, addNewChildtoBox } = useBoxSlice()
  const { tips, clearTips, moveTip, setTips } = useTipSlice()
  const {
    publicLink: { randomPath },
    getRandomPathNodeChild,
  } = useLinkShareSlice()
  // const tips = useAppSelector((state) => state.tip.tips)
  // const boxes = useAppSelector((state) => state.box.boxes)

  const [menuOpen, setMenuOpen] = useState(false)
  const [signedUrl, setSignedUrl] = useState('')
  const [check, setCheck] = useState(true)
  const { name, coverImage, coverImageSecureUrl, color, id, parent } = boxInfo

  const handleUndoMove = async (item: any) => {
    if (!item) return {}
    const params = {
      resourceId: item.id,
      destinationId: item.parent.id,
    }
    // Undoing a move folder
    if (item.type === 'BOX') {
      await dispatch(moveBox(params))
      // Add back the box into the boxes arr
      const newBoxArr = [...boxes]
      dispatch(setBoxes(newBoxArr))
      return {}
    }
    // Undoing a move tip
    await dispatch(moveTip(params))
    // Add back the tip into the tips arr
    // const newTipsArr = [...tips, res.payload]
    const newTipsArr = [...tips]
    dispatch(setTips(newTipsArr))
    return {}
  }

  const moveErrorToast = () => {
    Toast.show({
      icon: 'error',
      message: `Something went wrong with moving your item.`,
    })
  }

  const handleUpdate = async (updatedValue: Partial<Node>) => {
    await updateNode(updatedValue, boxInfo)
    setOpenModal('')
  }

  const handleDelete = async () => {
    setDeletingFolder(boxInfo.id)
    const filteredBoxes = boxes.filter((b) => b.id !== boxInfo.id)

    try {
      await minDelay(deleteNode(boxInfo), 400)
      Toast.show({
        icon: 'check',
        message: 'Folder "' + boxInfo?.name + '" was moved to Trash.  You can restore it there.',
      })
      setDeletingFolder('')
      dispatch(setBoxes(filteredBoxes))
    } catch (error) {
      setDeletingFolder('')
      console.error(error)
    }
  }

  const handleBoxClick = async () => {
    if (pageType === 'publicLink') {
      dispatch(setIsLoading(true))
      dispatch(getRandomPathNodeChild({ randomPath, nodeId: id }))
      return
    }
    // Current project is null when in followedNodes
    if (!currentProject) {
      await dispatch(getProject(boxInfo?.project?.id))
    }
    dispatch(setIsLoading(true))
    dispatch(clearBoxes())
    dispatch(clearTips())
    dispatch(getBox(id))
    const redirectInfo = createUrlPath(boxInfo, pathname)
    navigate(redirectInfo)
  }

  const handleCopyLinkShare = () => {
    if (boxInfo?.id) copyToClipBoard(`${CONFIG.APP.URL}/link/${boxInfo.id}`)
  }

  const settingMenuClose = () => {
    const array = popupMenuBoxes?.map((item: IPupMenu) => ({
      itemId: item.itemId,
      isOpen: false,
    }))
    dispatch(setPopupMenuBoxes(array))
  }

  const handleDuplicate = async () => {
    setDuplicatingFolder(boxInfo.id)
    await minDelay(duplicateNode(id, 'box'), 1500)
    setDuplicatingFolder('')
  }

  const handleMenuAction = async (action: string): Promise<void> => {
    setOpenModal(action)
    switch (action) {
      case menuActions.tag:
        setOpenModal(menuActions.tag)
        settingMenuClose()
        break
      case menuActions.follow:
        await updateFollowedNode(id)
        setOpenModal('')
        settingMenuClose()
        break
      case menuActions.duplicate:
        await handleDuplicate()
        break
      case menuActions.download:
        downloadNode(boxInfo)
        setOpenModal('')
        settingMenuClose()
        break
      case menuActions.copyLink:
        handleCopyLinkShare()
        setOpenModal('')
        break
      case menuActions.delete:
        setOpenModal('')
        onOpenDeleteNode()
        break
      case menuActions.unshare:
        setOpenModal('')
        onOpenLeaveSharedNode()
        break
      default:
        break
    }
    setMenuOpen(false)
  }

  const handleOnDragStart = (ev: any) => {
    ev.stopPropagation()
    setStartIndex(index)
  }
  const handleOnDragEnter = (ev: any) => {
    ev.stopPropagation()
    if (index !== startIndex) {
      setDragEnterIndex?.(index)
    } else if (index === startIndex) {
      setDragEnterIndex?.(index)
    }
  }

  const handleOnDrop = async () => {
    if (typeof dragEnterIndex !== 'number') {
      moveErrorToast()
      return
    }

    if (draggingTip) {
      // Drag a document into a folder
      const sourceTipIndex = tips.findIndex((t: Node) => t.id === draggingTip)
      const movingTip = tips[sourceTipIndex]
      const destinationFolder = boxes[dragEnterIndex]

      if (!movingTip || !destinationFolder) {
        moveErrorToast()
        return
      }

      const { payload } = await dispatch(moveTip({ resourceId: movingTip.id, destinationId: destinationFolder.id }))

      Toast.show({
        icon: 'check',
        message: `Moved ${movingTip.name} into the folder ${destinationFolder.name}.`,
        onUndo: () => handleUndoMove(movingTip),
      })
      setDraggingTip?.('')
      if (parent?.type === 'PROJECT') {
        await dispatch(
          addNewChildtoProjectBox({
            moved: payload,
            parent: destinationFolder.id,
          }),
        )
      } else {
        await dispatch(addNewChildtoBox({ moved: payload, parent: destinationFolder.id }))
      }
    } else {
      // Drag a folder into another folder

      if (startIndex === dragEnterIndex) {
        // Drag a folder on to itself: return
        setDragEnterIndex?.(undefined)
        return
      }

      const movingFolder = boxes[startIndex]
      const destinationFolder = boxes[dragEnterIndex]

      if (!movingFolder || !destinationFolder) {
        moveErrorToast()
        return
      }

      const { payload } = await dispatch(moveBox({ resourceId: movingFolder.id, destinationId: destinationFolder.id }))

      Toast.show({
        icon: 'check',
        message: `Moved ${movingFolder.name} into the folder ${destinationFolder.name}.`,
        onUndo: () => handleUndoMove(movingFolder),
      })
      if (parent?.type === 'PROJECT') {
        dispatch(
          addNewChildtoProjectBox({
            moved: payload,
            parent: destinationFolder.id,
          }),
        )
      } else {
        dispatch(addNewChildtoBox({ moved: payload, parent: destinationFolder.id }))
      }
    }

    // Clear the dragging state
    setDragEnterIndex?.(undefined)
  }

  const handleOnDragOver = (ev: any) => {
    ev.preventDefault()
  }

  useEffect(() => {
    let active = true
    if (!coverImage) {
      setSignedUrl('')
      return
    }
    if (pageType === 'publicLink') {
      setSignedUrl(
        `https://${process.env.REACT_APP_S3_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/public/${coverImage}`,
      )
    } else if (coverImageSecureUrl) {
      if (!active) return undefined
      const sizeSuffix = viewType === 'grid' ? 'box.grid_view' : 'box.list_view'
      const resizedImage = getResizedImage(coverImageSecureUrl, sizeSuffix)
      if (resizedImage) {
        setSignedUrl(resizedImage)
      }
    }

    return () => {
      active = false
    }
  }, [openModal, coverImage, pageType, viewType])

  useEffect(() => {
    if (!check) return

    const timer = setTimeout(() => {
      setCheck(false)
    }, 1000)
    return () => {
      clearTimeout(timer)
    }
  }, [check])

  // * Variables
  // * Renders BoxGridCard OR BoxListCard based on view type
  const boxesLayout =
    viewType === 'grid' ? (
      <Box pos="relative" width="100%" pb="100%" h={0}>
        <BoxGridCard
          boxInfo={{ ...boxInfo, signedUrl }}
          followedNodes={followedNodes}
          alerts={0}
          selected={id === selectedNode?.id}
          boxClick={handleBoxClick}
          handleMenuAction={handleMenuAction}
          index={index}
          pageType={pageType !== undefined ? menuBox[pageType] : menuBox.base}
          noBorder={noBorder}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          isLinkShare={pageType === 'publicLink'}
          onDragStart={handleOnDragStart}
          onDragEnter={handleOnDragEnter}
          onDragOver={handleOnDragOver}
          onDrop={handleOnDrop}
          dragEnterIndex={dragEnterIndex}
        />
      </Box>
    ) : (
      <BoxListCard
        boxInfo={{ ...boxInfo, signedUrl }}
        followedNodes={followedNodes}
        alerts={0}
        selected={id === selectedNode?.id}
        boxClick={handleBoxClick}
        handleMenuAction={handleMenuAction}
        index={index}
        pageType={pageType !== undefined ? menuBox[pageType] : menuBox.base}
        noBorder={noBorder}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        isLinkShare={pageType === 'publicLink'}
      />
    )
  const boxDraggable = (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => {
        if (snapshot.combineWith) {
          setDroppingIn(snapshot.combineWith)
        } else if (snapshot.draggingOver) {
          setDroppingIn('')
        }
        return (
          <Box
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...provided.draggableProps.style,
              border: '1px solid transparent',
              borderColor: noBorder && pageType !== 'personal' ? 'textSoft' : 'transparent',
              borderRadius: '8px',
              transform: shouldBoxReorder ? provided.draggableProps.style?.transform : '',
              transition: shouldBoxReorder ? provided.draggableProps.style?.transition : '',
            }}
            onMouseEnter={() => {
              setCheck(true)
            }}
            onMouseLeave={() => {
              setCheck(true)
            }}
          >
            {boxesLayout}
          </Box>
        )
      }}
    </Draggable>
  )
  const boxNonDraggable = (
    <Box
      onMouseEnter={() => {
        setCheck(true)
      }}
      onMouseLeave={() => {
        setCheck(true)
      }}
    >
      {boxesLayout}
    </Box>
  )

  if (deletingFolder === id) {
    return <Spinner placeSelf="center" thickness="4px" speed="0.65s" emptyColor="gray.200" color="accent" size="lg" />
  }

  return (
    <>
      {/** Disable dnd in Grid view only */}
      {viewType === 'grid' ? boxNonDraggable : boxDraggable}
      {/* Update Cover Image / color */}
      <ModalExternalButton
        header="Add a cover photo or colour"
        isOpen={openModal === menuActions.coverImage}
        close={() => {
          setOpenModal('')
        }}
      >
        <UpdateCoverImageOrColorModal
          currentCoverImage={coverImageSecureUrl}
          currentFolderColor={color}
          onCancel={() => setOpenModal('')}
          onUpdateColor={(newColor) => {
            // update folder color
            setOpenModal('')
            updateNode({ color: newColor, coverImage: null }, boxInfo)
          }}
          onUpdateCoverImage={async (newCoverImage) => {
            // update cover image
            setOpenModal('')
            const uploadResponse = await uploadFile(newCoverImage)
            updateNode({ color: null, coverImage: uploadResponse.fileUrl }, boxInfo, 'box')
          }}
        />
      </ModalExternalButton>

      <TagsModal
        nodeType="Folder"
        entityName={boxInfo.name}
        entityId={boxInfo.id}
        entityType={EntityType.Node}
        isOpen={openModal === menuActions.tag}
        onClose={() => setOpenModal('')}
      />

      {/* Update name */}
      <ModalExternalButton
        header={updateValues[openModal]?.header}
        isOpen={openModal === menuActions.rename}
        close={() => {
          setOpenModal('')
        }}
      >
        <UpdateModal
          placeholder={updateValues[openModal]?.placeholder}
          label={updateValues[openModal]?.label}
          name="boxCover"
          startValue={name}
          onCancel={() => {
            setOpenModal('')
          }}
          onUpdate={handleUpdate}
          isImage={updateValues[openModal]?.isImage}
          image={coverImageSecureUrl}
        />
      </ModalExternalButton>

      {/* DELETE FOLDER */}
      <AlertWithAction
        action={handleDelete}
        isOpen={isOpenDeleteNode}
        onClose={onCloseDeleteNode}
        actionText={`Delete the folder "${boxInfo.name}"?`}
        warningText={[`"${boxInfo.name}" will be moved to the trash.`]}
        confirmButtonText="Delete"
      />

      {/* Remove Myself */}
      <AlertWithAction
        action={() => {
          handleLeaveSharedNode(id)
          const filteredBoxes = boxes.filter((b: any) => b.id !== id)
          dispatch(setBoxes(filteredBoxes))
          onCloseLeaveSharedNode()
        }}
        isOpen={isOpenLeaveSharedNode}
        onClose={onCloseLeaveSharedNode}
        actionText="Remove Myself"
        warningText={['You will no longer have access to this folder.']}
      />

      {/* Move Model */}
      <ModalExternalButton
        header={`Move ${name}`}
        modelWidth="580px"
        modelHeight="723px"
        isOpen={openModal === 'moveTo'}
        close={() => {
          setOpenModal('')
        }}
      >
        <MoveToMenu
          closeAction={() => {
            setOpenModal('')
            // refreshParentBoxes()
          }}
          movingResource={boxInfo}
        />
      </ModalExternalButton>

      {/* MOBILE DETAILS */}
      <ModalExternalButton
        header="Box Details"
        isOpen={openModal === menuActions.details}
        close={() => {
          setOpenModal('')
        }}
        size="full"
        modelWidth="full"
        rounded={0}
        headerBg="background"
        isCentered={false}
      >
        <NodeDetailsInfo node={boxInfo} collapsed={false} setOpenModal={setOpenModal} />
      </ModalExternalButton>

      {/* Share Box */}
      <ShareModalV2
        isOpen={openModal === menuActions.share}
        onClose={() => {
          setOpenModal('')
        }}
        node={boxInfo}
      />
    </>
  )
}

export default BoxCard
