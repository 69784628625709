import { Box, Flex, Icon, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { FlowTodoCards, useFlowFilters } from 'features/workflow'
import { useEffect, useState } from 'react'
import { customIcons, icons } from 'theme'
import InfoPanelDetails from './InfoPanelDetails'
import { Node } from 'types/graphqlSchema'
import { Dot } from 'components/elements'

const tabStyles = {
  color: 'textRegular',
  fontWeight: 400,
  _hover: { color: 'textSoft', fontWeight: 500 },
  _active: { color: 'textSoft', fontWeight: 500 },
  _selected: { color: 'textSoft', fontWeight: 500 },
}
const iconStyles = {
  color: 'inherit',
  h: 5,
  w: 5,
  mr: 2,
}

interface InfoPanelProps {
  node?: Node
}

const InfoPanel = ({ node }: InfoPanelProps) => {
  const [tabIndex, setTabIndex] = useState<number | undefined>(undefined)
  const handleTabIndex = (index: number) => setTabIndex(index)

  const { todoFlows } = useFlowFilters()
  const hasFlows = todoFlows.some((flow) => flow.projectId === node?.id || flow.node?.projectId === node?.id)

  // workaround for https://github.com/chakra-ui/chakra-ui/issues/7450
  // indicator in wrong position on initial load
  useEffect(() => {
    const index = hasFlows ? 1 : 0

    const timer = setTimeout(() => {
      setTabIndex(index)
    }, 500)
    return () => clearTimeout(timer)
  }, [hasFlows])

  return (
    <Flex
      w="100%"
      minW="40dvw"
      flex={1}
      rounded={6}
      border="1px solid var(--chakra-colors-borderRegular)"
      bg="menuBackground"
      overflow="hidden"
    >
      <Tabs
        index={tabIndex}
        onChange={handleTabIndex}
        variant="unstyled"
        pos="relative"
        align="center"
        w="full"
        bg="inherit"
        pt="1.5rem"
        minH={{ base: '300px', xl: '400px' }}
      >
        <TabList bg="inherit" display="flex" gap="4rem">
          <Tab {...tabStyles}>
            <Icon as={icons.infoIcon} {...iconStyles} />
            Details
          </Tab>
          <Tab {...tabStyles}>
            <Icon as={customIcons.flowIcon} {...iconStyles} />
            Project Tasks - To Do
            <Box transform={'translateY(-10px)'}>
              <Dot visible={hasFlows} size="12px" />
            </Box>
          </Tab>
        </TabList>
        {tabIndex !== undefined && (
          <TabIndicator height="2px" bg="textSoft" borderRadius="1px" maxW="20px" transform="translateX(1rem)" />
        )}
        <TabPanels pt={{ base: '1rem', xl: '3rem' }} px={{ base: '1rem', xl: '3rem' }}>
          <TabPanel>
            <InfoPanelDetails node={node} />
          </TabPanel>
          <TabPanel>
            <FlowTodoCards project={node} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  )
}

export default InfoPanel
