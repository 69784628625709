import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Editable,
  EditableInput,
  Tooltip,
  EditablePreview,
  Image,
} from '@chakra-ui/react'
import { AnimatePresence, motion, Reorder, useDragControls } from 'framer-motion'
import { SAVE_SEARCH, DELETE_SEARCH, UPDATE_SEARCH } from 'features/graphql'
import { useMutation } from '@apollo/client'
import styles from './ElasticSearchSaveModal.module.css'
import { SearchItem, DateRange } from 'features/search/Search.types'
import { images } from 'theme'

interface ESSMProps {
  loadedSearch: {
    name: string
    id: string
  }
  searchItems: SearchItem[]
  savedSearches?: SearchItem[]
  isOpenSaveModal: boolean
  onCloseSaveModal: () => void
  getSavedSearches: () => void
  timeframe: DateRange
  urgency: string | undefined
  setLoadedSearch: Dispatch<
    SetStateAction<{
      name: string
      id: string
    }>
  >
}

const ElasticSearchSaveModal = ({
  searchItems,
  isOpenSaveModal,
  onCloseSaveModal,
  savedSearches,
  getSavedSearches,
  setLoadedSearch,
  loadedSearch,
  timeframe,
  urgency,
}: ESSMProps) => {
  const [name, setName] = useState('')
  const [deleteConfirmation, setDeleteConfirmation] = useState({ name: '', id: '' })
  const [containerHeight, setContainerHeight] = useState(160)
  const [searches, setSearches] = useState<SearchItem[] | undefined>([])

  useEffect(() => {
    setSearches(savedSearches)
  }, [savedSearches])

  useEffect(() => {
    if (!searches) return
    switch (searches.length) {
      case 3:
        setContainerHeight(200)
        break
      case 4:
        setContainerHeight(240)
        break
      default:
        setContainerHeight(searches.length > 4 ? 270 : 160)
    }
  }, [searches?.length])

  const [mutateSaveSearch] = useMutation(SAVE_SEARCH, {
    onCompleted: (data) => {
      setName('')
      getSavedSearches()
      if (data && data.addSavedSearch) {
        const { id, name } = data.addSavedSearch
        setLoadedSearch({ id, name })
        onCloseSaveModal()
      }
    },
  })

  const [mutateDeleteSearch] = useMutation(DELETE_SEARCH, {
    onCompleted: () => {
      setDeleteConfirmation({ name: '', id: '' })
      if (loadedSearch.id === deleteConfirmation.id) {
        setLoadedSearch({ name: '', id: '' })
      }
      getSavedSearches()
    },
  })

  const handleSaveSearch = async () => {
    await mutateSaveSearch({
      variables: {
        name,
        searchParameters: {
          searchItems,
          timeframe,
          urgency,
        },
      },
    })
  }

  const handleDeleteSearch = () => {
    mutateDeleteSearch({ variables: { deleteSavedSearchId: deleteConfirmation.id } })
  }

  return (
    <Modal
      isOpen={isOpenSaveModal}
      onClose={() => {
        setName('')
        setDeleteConfirmation({ name: '', id: '' })
        onCloseSaveModal()
      }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        display={'flex'}
        flexDir={'column'}
        justifyContent={'center'}
        bg={'white'}
        color="textBlack"
        fontFamily={'Inter'}
        w={'600px'}
        maxW={'80%'}
        px={4}
      >
        <ModalHeader color={'inherit'} fontSize={18}>
          <Text color={'inherit'}>Save or Edit Search</Text>
          <ModalCloseButton
            _focusVisible={{ boxShadow: '0 0 0 1px var(--chakra-colors-borderFocus)', border: '1px solid transpaent' }}
            onClick={() => {
              setDeleteConfirmation({ name: '', id: '' })
              setName('')
              onCloseSaveModal()
            }}
          />
        </ModalHeader>

        <motion.div
          initial={{ height: 30, opacity: 0 }}
          animate={{
            height: containerHeight,
            opacity: 1,
          }}
          transition={{
            type: 'ease',
            duration: 0.7,
            opacity: { delay: 0.6 },
          }}
        >
          <ModalBody display={'flex'} flexDirection={'column'} mx={6} px={0}>
            {deleteConfirmation.name ? (
              <DeleteConfirmation />
            ) : (
              <>
                <Flex align="center" maxW="500px" gap={4}>
                  <Image src={images.bookmarkPlus} />
                  <Input
                    autoFocus
                    flex={1}
                    px="1rem"
                    py=".5rem"
                    placeholder="Choose a name"
                    value={name}
                    disabled={false}
                    variant="tipSaveModalTitle"
                    border="1px solid #e9eaec"
                    onChange={(event) => {
                      setName(event.target.value)
                    }}
                    onKeyDown={(e) => {
                      if (e.key !== 'Enter') return
                      if (!name) return
                      handleSaveSearch()
                    }}
                    color="textBlack"
                  />
                </Flex>
                <Flex
                  flexDir="column"
                  id="SavedSearches"
                  mt={2}
                  maxH="200px"
                  overflowY="auto"
                  css={{
                    '&::-webkit-scrollbar': {
                      width: '6px',
                    },
                    '&::-webkit-scrollbar-track': {
                      width: '1px',
                      background: '#e9eaec',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: '#D9D9D9',
                      borderRadius: '6px',
                    },
                  }}
                >
                  {searches && (
                    <Reorder.Group layoutScroll axis="y" values={searches} onReorder={setSearches}>
                      <AnimatePresence mode="wait">
                        <motion.div
                          key="hello-world"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ delay: 0.5 }}
                        >
                          {searches?.map((item) => (
                            <SavedSearch
                              key={item.id}
                              item={item}
                              setDeleteConfirmation={setDeleteConfirmation}
                              getSavedSearches={getSavedSearches}
                              loadedSearch={loadedSearch}
                              setLoadedSearch={setLoadedSearch}
                            />
                          ))}
                        </motion.div>
                      </AnimatePresence>
                    </Reorder.Group>
                  )}
                </Flex>
              </>
            )}
          </ModalBody>
        </motion.div>

        <ModalFooter borderTop={'1px solid #e9eaec'} mx={6} px={0} py={4} display="flex" bg="white" zIndex={1}>
          {deleteConfirmation.name ? (
            <Button
              variant="btnOutline"
              mr={0}
              px={6}
              onClick={() => {
                setDeleteConfirmation({ name: '', id: '' })
              }}
              borderColor="textBlack"
              color="textBlack"
              _hover={{ bgColor: 'textSoft' }}
              _focusVisible={{
                boxShadow: '0 0 0 1px var(--chakra-colors-borderFocus)',
                border: '1px solid transpaent',
              }}
              order={'2'}
            >
              {'No'}
            </Button>
          ) : (
            <Button
              variant="btnOutline"
              mr={3}
              px={6}
              onClick={onCloseSaveModal}
              borderColor="textBlack"
              color="textBlack"
              _focusVisible={{
                boxShadow: '0 0 0 1px var(--chakra-colors-borderFocus)',
                border: '1px solid transpaent',
              }}
              _hover={{ bgColor: 'textSoft' }}
              order={undefined}
            >
              Cancel
            </Button>
          )}
          {deleteConfirmation.name ? (
            <Button mr={3} variant="defaultButton" px={6} onClick={handleDeleteSearch}>
              Yes
            </Button>
          ) : (
            <Button mr={0} variant="defaultButton" px={6} onClick={handleSaveSearch}>
              Save
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const SavedSearch = ({ item, setDeleteConfirmation, getSavedSearches, loadedSearch, setLoadedSearch }) => {
  const controls = useDragControls()

  const [mutateUpdateSearch] = useMutation(UPDATE_SEARCH, {
    onCompleted: (data) => {
      getSavedSearches()
      if (data.updateSavedSearch.id === loadedSearch.id) {
        setLoadedSearch({ name: data.updateSavedSearch.name, id: data.updateSavedSearch.id })
      }
    },
  })

  return (
    <Reorder.Item value={item} id={item} dragListener={false} dragControls={controls} style={{ position: 'relative' }}>
      <Flex align="center" maxW="500px" gap={4} py=".5rem">
        <Image src={images.bookmark} />
        <Editable
          pl="1rem"
          fontSize={14}
          fontWeight={500}
          color="textBlack"
          defaultValue={item.name}
          submitOnBlur={false}
          onSubmit={(nextValue) =>
            mutateUpdateSearch({
              variables: { name: nextValue, updateSavedSearchId: item.id, searchParameters: item.searchParameters },
            })
          }
        >
          <EditablePreview _focus={{ borderWidth: 0 }} />
          <Tooltip
            label="Rename this Search?"
            placement="top-end"
            hasArrow
            gutter={10}
            px={4}
            p={2}
            borderRadius=".375rem"
            bg="black"
            color="gray.300"
            fontFamily="Inter"
            fontSize={[11, null, null, 13]}
            lineHeight="1"
          >
            <EditableInput
              _focusVisible={{
                boxShadow: '0 0 0 1px var(--chakra-colors-borderFocus)',
                border: '1px solid transpaent',
              }}
            />
          </Tooltip>
        </Editable>

        <Flex marginLeft="auto" align="center" gap={2} className={styles.buttonsDiv}>
          <button className={styles.grabbable} onPointerDownCapture={(e) => controls.start(e)}>
            <Image src={images.grabbable} pointerEvents={'none'} />
          </button>
          <button className={styles.delete} onClick={() => setDeleteConfirmation({ name: item.name, id: item.id })}>
            <Image src={images.xSmall02} />
          </button>
        </Flex>
      </Flex>
    </Reorder.Item>
  )
}

const DeleteConfirmation = () => {
  return (
    <Box my="2rem">
      <Text color="textBlack" fontWeight={500} fontSize="18px" mb={4}>
        Are you sure you want to remove this Saved Search?
      </Text>
      <Text color="textBlack" fontWeight={500} fontSize="14px">
        By clicking “Yes”, the Saved Search and its criteria will be removed.
      </Text>
    </Box>
  )
}

export default ElasticSearchSaveModal
