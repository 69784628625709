import { Avatar, Button, Flex, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react'
import { Role, User } from 'types/graphqlSchema'
import { getResizedImage } from 'helpers/storage'
import { AnimatedMenuChevron } from 'components'
import { useOutletContext } from 'react-router-dom'
import { AdminContext } from 'components/layouts/AdminLayout'
import { useAppSlice } from 'features/redux'
import { format } from 'date-fns'

const menuItemStyles = {
  bg: 'inherit',
  color: 'textBlack',
  fontFamily: 'Inter',
  fontWeight: 500,

  _hover: { bg: 'menuBackground', color: 'textHighlight', fill: 'textHighlight' },
  _focusVisible: { bg: 'menuBackground', color: 'textHighlight' },
}

const RoleDropdown = ({ children, title, disable }) => {
  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            minH="43px"
            display="flex"
            gap={0}
            w="8.5rem"
            variant="outline"
            isDisabled={disable}
            rightIcon={<AnimatedMenuChevron isOpen={isOpen} />}
            color="textHighlight"
            borderColor="textRegular"
            _hover={{
              color: 'textHighlight',
            }}
            _active={{
              color: 'textHighlight',
            }}
            fontSize={{ base: '.85rem', md: '15px' }}
            fontWeight={400}
            _focusVisible={{ boxShadow: '0 0 0 1px var(--chakra-colors-borderFocus)' }}
          >
            {title}
          </MenuButton>
          <MenuList minWidth="240px" bg="white" px="1px" py={4}>
            {children}
          </MenuList>
        </>
      )}
    </Menu>
  )
}

const UserCard = ({ user }: { user: User }) => {
  const { userRoles, setUserRoles } = useOutletContext<AdminContext>()
  const {
    user: { id },
  } = useAppSlice()

  const getRole = () => {
    const role = userRoles.find((ur) => ur.userId === user.id)?.role

    if (role === Role.Admin) return 'Admin'
    if (role === Role.User) return 'Member'
  }

  const handleRoleUpdate = (userId: string, newRole: string) => {
    setUserRoles((prevUserRoles) =>
      prevUserRoles.map((userRole) => (userRole.userId === userId ? { ...userRole, role: newRole } : userRole)),
    )
  }

  return (
    <Flex
      fontSize=".75rem"
      rounded={8}
      bg="cardBackground"
      px="2rem"
      py="1rem"
      border="1px solid var(--chakra-colors-borderRegular)"
      alignItems="center"
    >
      <Flex align="center" gap={4} w="15rem">
        {/* <Checkbox variant="tipbox" /> */}
        <Avatar
          name={user.avatarSecureUrl ? '' : `${user.firstName} ${user.lastName}`}
          size="md"
          src={user?.avatarSecureUrl ? getResizedImage(user.avatarSecureUrl, 'profile.account') : '#'}
        />
        <Text noOfLines={1} fontSize="1rem" fontWeight={400}>
          {user.firstName} {user.lastName}
        </Text>
      </Flex>
      <RoleDropdown title={getRole()} disable={user.id === id}>
        <MenuItem {...menuItemStyles} px={8} value="Urgent" onClick={() => handleRoleUpdate(user.id, Role.Admin)}>
          <Flex flexDir="column">
            <Text color="inherit" fontSize="0.875rem" fontWeight={500}>
              Admin
            </Text>
            <Text color="textRegular" fontSize="11px" fontWeight={500} w="35ch">
              Can manage organization, privacy & team members, create & edit all content
            </Text>
          </Flex>
        </MenuItem>
        <MenuItem {...menuItemStyles} px={8} value="Not Urgent" onClick={() => handleRoleUpdate(user.id, Role.User)}>
          <Flex flexDir="column">
            <Text color="inherit" fontSize="0.875rem" fontWeight={500}>
              Member
            </Text>
            <Text color="textRegular" fontSize="11px" fontWeight={500} w="35ch">
              Can create & edit content, manage privacy & team members, manage & respond to Flows
            </Text>
          </Flex>
        </MenuItem>
      </RoleDropdown>
      <Text fontWeight={400} fontSize="1rem" pl="3.125rem" minW="20rem">
        {user.email}
      </Text>
      <Text fontWeight={400} fontSize="1rem" pl="3.125rem">
        {format(new Date(user.createdAt), 'MMMM d, yyyy')}
      </Text>
    </Flex>
  )
}

export default UserCard
