import React from 'react'
import { Flex, Box } from '@chakra-ui/react'
import { IconButton } from 'components'
import { usePermissions } from 'hooks'
import { icons, customIcons } from 'theme'
import { menuActions } from 'helpers/constant'

interface DotActionsMenuProps {
  follow: boolean
  isSharedWithMe?: boolean
  handleMenuAction: (action: string) => void
  disableMenuOptions: string[]
}

const DotActionsMenu = ({ follow, handleMenuAction, disableMenuOptions, isSharedWithMe }: DotActionsMenuProps) => {
  const { hasPermission } = usePermissions()
  const firstSection = !disableMenuOptions.includes('link')
  const showSecondSection =
    !disableMenuOptions.includes('coverImage') ||
    !disableMenuOptions.includes('tag') ||
    !disableMenuOptions.includes('rename') ||
    !disableMenuOptions.includes('moveTo') ||
    !disableMenuOptions.includes('follow')

  return (
    <Flex flexDir="column" overflow="hidden" rounded={8} minW="10rem" bg="textHighlight" py=".75rem">
      {firstSection && (
        <Flex
          display={disableMenuOptions.includes('link') ? 'none' : 'block'}
          flexWrap="wrap"
          flexDir="column"
          borderBottom="1px"
          borderColor="textSoft"
          pb={2}
          mb={2}
          bg="textHighlight"
        >
          {/* COPY LINK */}
          <IconButton
            ariaLabel={menuActions.copyLink}
            onClick={handleMenuAction}
            icon={customIcons.menuLink}
            iconSize={{ width: 4, height: 4 }}
            label="Copy link"
            variant="dropDownButton"
            iconMarginRight={3}
            flexDir={['row']}
            isDisable={!hasPermission('share')}
          />
        </Flex>
      )}
      {showSecondSection && (
        <Flex flexWrap="wrap" flexDir="column" borderBottom="1px" borderColor="textSoft" pb={2} mb={2}>
          {/* Cover Image */}
          <Box display={disableMenuOptions.includes('coverImage') ? 'none' : 'block'} flexDir="column">
            <IconButton
              ariaLabel={menuActions.coverImage}
              onClick={handleMenuAction}
              icon={customIcons.menuPhoto}
              iconSize={{ width: 4, height: 4 }}
              label="Add a cover photo"
              variant="dropDownButton"
              iconMarginRight={3}
              flexDir={['row']}
              isDisable={!hasPermission('update')}
            />
          </Box>
          {/* TAG */}
          <Box display={disableMenuOptions.includes('tag') ? 'none' : 'block'}>
            <IconButton
              ariaLabel={menuActions.tag}
              onClick={handleMenuAction}
              icon={customIcons.menuTag}
              iconSize={{ width: 4, height: 4 }}
              label="Add / Edit Tags"
              variant="dropDownButton"
              iconMarginRight={3}
              flexDir={['row']}
              isDisable={!hasPermission('tag')}
            />
          </Box>
          {/* RENAME */}
          <Box display={disableMenuOptions.includes('rename') ? 'none' : 'block'}>
            <IconButton
              ariaLabel={menuActions.rename}
              onClick={handleMenuAction}
              icon={customIcons.menuRename}
              iconSize={{ width: 4, height: 4 }}
              label="Rename"
              variant="dropDownButton"
              iconMarginRight={3}
              flexDir={['row']}
              isDisable={!hasPermission('update')}
            />
          </Box>
          {/* MOVE FOLDER */}
          <Box display={disableMenuOptions.includes('moveTo') ? 'none' : 'block'}>
            <IconButton
              ariaLabel={menuActions.moveTo}
              onClick={handleMenuAction}
              icon={icons.moveTo}
              iconSize={{ width: 4, height: 4 }}
              label="Move to"
              variant="dropDownButton"
              iconMarginRight={3}
              flexDir={['row']}
              isDisable={!hasPermission('update')}
            />
          </Box>
          {/* FOLLOWING */}
          <Box display={disableMenuOptions.includes('follow') ? 'none' : 'block'}>
            <IconButton
              ariaLabel={menuActions.follow}
              onClick={handleMenuAction}
              icon={customIcons.menuFollow}
              iconSize={{ width: 4, height: 4 }}
              label={follow ? 'Unfollow' : 'Follow'}
              variant="dropDownButton"
              iconMarginRight={3}
              flexDir={['row']}
            />
          </Box>
        </Flex>
      )}
      <Flex flexWrap="wrap" flexDir="column">
        {/* DUPLICATE */}
        <Box display={disableMenuOptions.includes('duplicate') ? 'none' : 'block'}>
          <IconButton
            ariaLabel={menuActions.duplicate}
            onClick={handleMenuAction}
            icon={customIcons.menuDuplicate}
            iconSize={{ width: 4, height: 4 }}
            label="Duplicate"
            variant="dropDownButton"
            iconMarginRight={3}
            flexDir={['row']}
          />
        </Box>
        {/* DOWNLOAD */}
        <IconButton
          ariaLabel={menuActions.download}
          onClick={handleMenuAction}
          icon={customIcons.menuDownload}
          iconSize={{ width: 4, height: 4 }}
          label="Download"
          variant="dropDownButton"
          iconMarginRight={3}
          flexDir={['row']}
          isDisable={!hasPermission('download')}
        />
        {/* DELETE */}
        {!isSharedWithMe && (
          <Box display={disableMenuOptions.includes('delete') ? 'none' : 'block'}>
            <IconButton
              ariaLabel={menuActions.delete}
              onClick={handleMenuAction}
              icon={customIcons.menuDelete}
              iconSize={{ width: 4, height: 4 }}
              label="Delete"
              variant="dropDownButton"
              iconMarginRight={3}
              flexDir={['row']}
              isDisable={!hasPermission('delete')}
            />
          </Box>
        )}
        {/* Remove Me */}
        <Box display={!isSharedWithMe ? 'none' : 'block'}>
          <IconButton
            ariaLabel={menuActions.unshare}
            onClick={handleMenuAction}
            icon={customIcons.menuDelete}
            iconSize={{ width: 4, height: 4 }}
            label="Remove Myself"
            variant="dropDownButton"
            iconMarginRight={3}
            flexDir={['row']}
            style={{ paddingBottom: '16px' }}
          />
        </Box>
      </Flex>
    </Flex>
  )
}

export default DotActionsMenu
