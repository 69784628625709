import React, { useEffect, useState } from 'react'
import { Box, Container, Flex, Heading, useBreakpointValue, Icon } from '@chakra-ui/react'
import { usePermissions } from 'hooks'
import { icons, customIcons } from 'theme'
import { Badge } from 'components'
import { NodeInfoPopover } from 'features/node/components'
import { convertNodeColorToHex } from 'helpers/utils'
import { EntityType, FollowedNode } from 'types/graphqlSchema'
import BoxMenuPopover from './BoxMenuPopover'
import { useTags } from 'features/tags'

const BoxGridCard = ({
  boxInfo,
  followedNodes,
  alerts,
  selected,
  boxClick,
  handleMenuAction,
  pageType,
  index,
  noBorder,
  menuOpen,
  setMenuOpen,
  isLinkShare = false,
  onDragStart,
  onDragEnter,
  onDragOver,
  onDrop,
  dragEnterIndex,
}: IBoxCardChild) => {
  const { id, type, sharedMembers, name, color, coverImage, coverImageSecureUrl } = boxInfo
  const isMobile = useBreakpointValue({ xs: true, lg: false })
  const [isHovering, setHovering] = useState<boolean>(false)
  const folderColor = convertNodeColorToHex(color)
  const { hasPermission } = usePermissions()
  const { fetchTags, previousTags } = useTags()

  const isShared = () => {
    if (sharedMembers && sharedMembers.length > 0) return true
    if (type === 'PROJECT') {
      const project: any = { ...boxInfo }
      if (project?.category?.includes('SHARE')) return true
    }
    return false
  }

  useEffect(() => {
    if (isHovering && boxInfo?.id) {
      fetchTags(boxInfo.id, EntityType.Node)
    }
  }, [isHovering, boxInfo?.id])

  return (
    <aside
      key={id}
      onMouseEnter={() => {
        if (!isMobile) setHovering(true)
      }}
      onMouseLeave={() => {
        if (!isMobile) setHovering(false)
      }}
    >
      <Container
        id={id}
        display="flex"
        flexDir="column"
        variant="boxCard"
        bg="transparent"
        sx={selected || dragEnterIndex === index ? { borderColor: 'accentIcon' } : {}}
        onClick={boxClick}
        draggable="true"
        onDrag={onDragStart}
        onDragEnter={onDragEnter}
        onDragOver={onDragOver}
        onDrop={onDrop}
      >
        <Box w="30%" h="6%" bg={coverImage ? 'textRegular' : folderColor.tab} borderRadius="10px 10px 0 0" mb="3%" />

        <Flex flex={1} flexDir="column" bg="cardBackground" borderRadius="10px" cursor={'pointer'}>
          <Box
            flex={5}
            pos="relative"
            borderRadius="10px"
            bgImage={coverImageSecureUrl}
            bgSize="cover"
            overflow="hidden"
          >
            <Box
              pos="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              bg={coverImage ? 'transparent' : folderColor.body}
            />
            {alerts > 0 && <Badge message={alerts} top={2} right={2} />}
          </Box>

          <Heading
            flex={3}
            fontSize={[12, null, null, null, 14]}
            lineHeight={['14px', null, null, null, '20px']}
            fontWeight="400"
            textAlign="left"
            maxH="3rem"
            textOverflow="hidden"
            wordBreak="break-word"
            noOfLines={2}
            px="8%"
            pt="2%"
          >
            {name}
          </Heading>
        </Flex>
      </Container>

      {/* BOTTOM MENUS */}
      <Flex
        pos="absolute"
        bottom={isMobile ? '-2rem' : '-2.5rem'}
        align="center"
        justify="space-between"
        width="100%"
        opacity={isMobile || isHovering ? 1 : 0}
        transition="opacity 0.2s"
        p={0}
      >
        {/* BOTTOM MENUS LEFT */}
        <Flex flex={1} dir="row" align="center" m="2px 0">
          {/* <Icon
            as={icons.clockIcon}
            color="accentIcon"
            h={[3, null, null, 4]}
            w={[3, null, null, 4]}
            mr={2}
          /> */}
          {isShared() && (
            <Icon
              as={isMobile ? customIcons.addUserMobile : customIcons.userPlusIcon}
              color="textRegular"
              h={[3, null, null, 4]}
              w={[3, null, null, 4]}
              mr={2}
            />
          )}

          {followedNodes?.some((f: FollowedNode) => f.nodeId === id) && (
            <Icon as={icons.follow} color="textRegular" h={[3, null, null, 4]} w={[3, null, null, 4]} mr={2} />
          )}
        </Flex>

        {/* BOTTOM MENUS RIGHT */}
        <Flex
          dir="row"
          align="center"
          onClick={(event) => {
            event.stopPropagation()
          }}
          display={isLinkShare && !hasPermission('download', boxInfo, true) ? 'none' : 'inherit'}
        >
          {!isMobile && (
            <NodeInfoPopover node={boxInfo} tags={previousTags}>
              <Icon
                as={icons.infoIcon}
                color="textRegular"
                h={[3, null, null, 5]}
                w={[3, null, null, 5]}
                p={0}
                _hover={{
                  color: 'white',
                }}
              />
            </NodeInfoPopover>
          )}
          <BoxMenuPopover
            box={boxInfo}
            followedNodes={followedNodes}
            handleMenuAction={handleMenuAction}
            pageType={pageType}
            noBorder={noBorder}
            isLinkShare={isLinkShare}
            isMobile={isMobile}
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
          />
        </Flex>
      </Flex>
    </aside>
  )
}

export default BoxGridCard
