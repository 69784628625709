import React, { useRef } from 'react'
import { Flex, Box } from '@chakra-ui/react'
import { IconButton } from 'components/elements'
import { icons, customIcons } from 'theme'
import { menuActions } from 'helpers/constant'
import { usePermissions } from 'hooks'
import { Node } from 'types/graphqlSchema'
import { useAppSlice } from 'features/redux'

interface ITipCardMenuProps {
  tip: Node
  follow: boolean
  handleMenuAction: (action: string) => void
  pageType: string[]
  noBorder?: boolean
  isMobile?: boolean
  isLinkShare?: boolean
}

const TipCardMenu = ({
  tip,
  follow,
  handleMenuAction,
  pageType,
  noBorder,
  isMobile,
  isLinkShare,
}: ITipCardMenuProps) => {
  const { hasPermission } = usePermissions()
  const { user } = useAppSlice()
  const isSharedWithMe = tip.sharedMembers?.some((sm) => sm?.sharedWithId === user.id && user.id !== tip.ownerId)
  const duplicateRef = useRef<HTMLButtonElement>(null)

  return (
    <Flex
      flexDir="column"
      overflow="hidden"
      overflowY="auto"
      rounded={8}
      maxH={['90vh', null, null, '55vh']}
      className="show_apple_scrollbar"
      bg="textHighlight"
      py=".75rem"
    >
      <Flex flexWrap="wrap" flexDir="column" borderBottom={noBorder ? '0' : '1px'} borderColor="textSoft" pb={2} mb={2}>
        {/* Text Editor */}
        <Box display={pageType.includes('textEditor') ? 'block' : 'none'}>
          <IconButton
            ariaLabel={menuActions.textEditor}
            onClick={handleMenuAction}
            icon={customIcons.quickstartIcon}
            iconSize={{ width: 4, height: 4 }}
            label="Add a Text Editor"
            variant="dropDownButton"
            iconMarginRight={2}
            flexDir={['row']}
            style={{ paddingTop: '16px' }}
          />
        </Box>
        {/* Flow */}
        <Box display={pageType.includes('share') ? 'block' : 'none'}>
          <IconButton
            ariaLabel={menuActions.flow}
            onClick={handleMenuAction}
            icon={customIcons.menuFlows}
            iconSize={{ width: 5, height: 5 }}
            label="Start a Task"
            variant="dropDownButton"
            iconMarginRight={2}
            flexDir={['row']}
            style={{ paddingTop: '16px' }}
            isDisable={tip.state === 'INACTIVE' || tip.state === 'PENDING' || !hasPermission('start_flow', tip)}
          />
        </Box>
        {/* Share */}
        <Box display={pageType.includes('share') ? 'block' : 'none'}>
          <IconButton
            ariaLabel={menuActions.share}
            onClick={handleMenuAction}
            icon={customIcons.menuShare}
            iconSize={{ width: 4, height: 4 }}
            label="Share"
            variant="dropDownButton"
            iconMarginRight={3}
            flexDir={['row']}
            style={{ paddingTop: '16px' }}
            isDisable={tip.state === 'INACTIVE' || tip.state === 'PENDING' || !hasPermission('share', tip)}
          />
        </Box>
        {/* Copy Link */}
        <Box display={pageType.includes('link') ? 'block' : 'none'}>
          <IconButton
            ariaLabel={menuActions.copyLink}
            onClick={handleMenuAction}
            icon={customIcons.menuLink}
            iconSize={{ width: 4, height: 4 }}
            label="Copy link"
            variant="dropDownButton"
            iconMarginRight={3}
            flexDir={['row']}
            isDisable={!hasPermission('privateLinkShare', tip) || tip.state === 'INACTIVE' || tip.state === 'PENDING'}
          />
        </Box>
      </Flex>
      <Flex flexWrap="wrap" flexDir="column" borderBottom={noBorder ? '0' : '1px'} borderColor="textSoft" pb={2} mb={2}>
        {/* Comment */}
        <Box display={pageType.includes('comment') ? 'block' : 'none'}>
          <IconButton
            ariaLabel="comment"
            onClick={() => {}}
            icon={customIcons.menuPhoto}
            iconSize={{ width: 4, height: 4 }}
            label="Comment"
            variant="dropDownButton"
            iconMarginRight={3}
            flexDir={['row']}
          />
        </Box>
        {/* TIP PHOTO */}
        <Box display={pageType.includes('coverImage') ? 'block' : 'none'}>
          <IconButton
            ariaLabel={menuActions.coverImage}
            onClick={handleMenuAction}
            icon={customIcons.menuPhoto}
            iconSize={{ width: 4, height: 4 }}
            label="Add a cover photo"
            variant="dropDownButton"
            iconMarginRight={3}
            flexDir={['row']}
            isDisable={!hasPermission('update', tip)}
          />
        </Box>
        {/* TAGS */}
        <Box>
          <IconButton
            ariaLabel={menuActions.tag}
            onClick={handleMenuAction}
            icon={customIcons.menuTag}
            iconSize={{ width: 4, height: 4 }}
            label="Add / Edit Tags"
            variant="dropDownButton"
            iconMarginRight={3}
            flexDir={['row']}
            isDisable={!hasPermission('update', tip)}
          />
        </Box>
        <Box display={pageType.includes('rename') ? 'block' : 'none'}>
          {/* RENAME */}
          <IconButton
            ariaLabel={menuActions.rename}
            onClick={handleMenuAction}
            icon={customIcons.menuRename}
            iconSize={{ width: 4, height: 4 }}
            label="Rename"
            variant="dropDownButton"
            iconMarginRight={3}
            flexDir={['row']}
            isDisable={!hasPermission('update', tip)}
          />
        </Box>
        {/* Move */}
        <Box display={pageType.includes('moveTo') ? 'block' : 'none'}>
          <IconButton
            ariaLabel="moveTo"
            onClick={handleMenuAction}
            icon={icons.moveTo}
            iconSize={{ width: 4, height: 4 }}
            label="Move to"
            variant="dropDownButton"
            iconMarginRight={3}
            flexDir={['row']}
            isDisable={!hasPermission('update', tip) || tip.state === 'INACTIVE' || tip.state === 'PENDING'}
          />
        </Box>
        {/* Manage Versions */}
        {/* <Box display={pageType.includes('manageVersions') ? 'block' : 'none'}>
          <IconButton
            ariaLabel="manageVersions"
            onClick={() => {}}
            icon={icons.manage}
            iconSize={{ width: 4, height: 4 }}
            label="Manage versions"
            variant="dropDownButton"
            iconMarginRight={3}
            flexDir={['row']}
            isDisable={!hasPermission('update', tip)}
          />
        </Box> */}
        {/* Following */}
        <Box display={pageType.includes('follow') ? 'block' : 'none'}>
          <IconButton
            ariaLabel={menuActions.follow}
            onClick={handleMenuAction}
            icon={customIcons.menuFollow}
            iconSize={{ width: 4, height: 4 }}
            label={follow ? 'Unfollow' : 'Follow'}
            variant="dropDownButton"
            iconMarginRight={3}
            flexDir={['row']}
            isDisable={tip.state === 'INACTIVE' || tip.state === 'PENDING' || !hasPermission('view', tip)}
          />
        </Box>
        {/* Details */}
        <Box display={isMobile && pageType.includes('detail') ? 'block' : 'none'}>
          <IconButton
            ariaLabel={menuActions.details}
            onClick={handleMenuAction}
            icon={icons.preview}
            iconSize={{ width: 4, height: 4 }}
            label="Details"
            variant="dropDownButton"
            iconMarginRight={3}
            flexDir={['row']}
          />
        </Box>
      </Flex>
      <Flex flexWrap="wrap" flexDir="column">
        {/* Duplicate */}
        <Box display={pageType.includes('duplicate') ? 'block' : 'none'}>
          <IconButton
            ariaLabel={menuActions.duplicate}
            customRef={duplicateRef}
            onClick={() => {
              duplicateRef?.current?.blur()
              handleMenuAction(menuActions.duplicate)
            }}
            icon={customIcons.menuDuplicate}
            iconSize={{ width: 4, height: 4 }}
            label="Duplicate"
            variant="dropDownButton"
            iconMarginRight={3}
            flexDir={['row']}
            isDisable={!hasPermission('update', tip)}
          />
        </Box>
        {/* Download */}
        <Box display={pageType.includes('download') ? 'block' : 'none'}>
          <IconButton
            ariaLabel={menuActions.download}
            onClick={handleMenuAction}
            icon={customIcons.menuDownload}
            iconSize={{ width: 4, height: 4 }}
            label="Download"
            variant="dropDownButton"
            iconMarginRight={3}
            flexDir={['row']}
            isDisable={
              !hasPermission('download', tip, isLinkShare) || tip.state === 'INACTIVE' || tip.state === 'PENDING'
            }
          />
        </Box>
        {/* Delete */}
        {!isSharedWithMe && (
          <Box display={pageType.includes('delete') ? 'block' : 'none'}>
            <IconButton
              ariaLabel={menuActions.delete}
              onClick={handleMenuAction}
              icon={customIcons.menuDelete}
              iconSize={{ width: 4, height: 4 }}
              label="Delete"
              variant="dropDownButton"
              iconMarginRight={3}
              flexDir={['row']}
              style={{ paddingBottom: '16px' }}
              isDisable={!hasPermission('delete', tip)}
            />
          </Box>
        )}
        {/* Remove Me */}
        {isSharedWithMe && (
          <Box>
            <IconButton
              ariaLabel={menuActions.unshare}
              onClick={handleMenuAction}
              icon={customIcons.menuDelete}
              iconSize={{ width: 4, height: 4 }}
              label="Remove Myself"
              variant="dropDownButton"
              iconMarginRight={3}
              flexDir={['row']}
              style={{ paddingBottom: '16px' }}
            />
          </Box>
        )}
      </Flex>
    </Flex>
  )
}

export default TipCardMenu
