import { useState } from 'react'
import { Flex, Text, Icon } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { pathPrefix, workflowConstant } from 'helpers/constant'
import { formatForDate } from 'helpers/utils'
import { useFlowContents } from 'features/workflow/hooks'
import { icons } from 'theme'
import FlowAssigned from 'pages/Flows/components/FlowAssigned'
import { motion } from 'framer-motion'

const FlowItem = ({ flow }) => {
  const { id, state, workflow, dueDate } = flow
  const isPending = state.stateName === workflowConstant.approval.state.pending
  const navigate = useNavigate()
  const { getFlowStatusForParticipant } = useFlowContents()
  const [isHovering, setHovering] = useState(false)
  const pendingRequestName = workflow.name === 'Feedback' ? 'General' : workflow.name

  return (
    <motion.li
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: 'auto' }}
      exit={{ opacity: 0, height: 0, transition: { opacity: 0.1, height: { delay: 0.4 } } }}
      transition={{ duration: 0.3, opacity: { delay: 0.2 } }}
      key={flow.id}
    >
      <Flex
        flexDir="column"
        bg="background"
        px={5}
        py={3}
        mb={'1rem'}
        border="1px solid"
        borderColor={isPending ? 'textSoft' : 'borderRegular'}
        borderRadius={8}
        cursor="pointer"
        onClick={() => {
          navigate(`${pathPrefix.flows.single}${id}`)
        }}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        color="textSoft"
        _hover={{
          borderColor: 'textSoft',
        }}
      >
        {dueDate && (
          <Text variant="tiny" pb={2} color="inherit">
            Due: {formatForDate(dueDate)}
          </Text>
        )}
        <Text variant="mediumHighlight" fontWeight={550} color="inherit">
          {isPending ? `${pendingRequestName} Request` : getFlowStatusForParticipant(flow)}
        </Text>
        <Text variant="small" noOfLines={1} lineHeight="1.5rem" color="inherit">
          {flow.name}
        </Text>
        <Text variant="small" noOfLines={1} lineHeight="1.5rem" color="inherit">
          {flow.project.name}
        </Text>
        <Flex py={2} justify="space-between" align="center">
          <FlowAssigned flow={flow} />
          {isHovering && <Icon as={icons.FaChevronRight} color="accentIcon" ml={4} />}
        </Flex>
      </Flex>
    </motion.li>
  )
}

export default FlowItem
