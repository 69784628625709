import { useEffect, useRef } from 'react'
import { Flex, Box, Button, Avatar } from '@chakra-ui/react'
import { useMutation, ApolloError } from '@apollo/client'
import { getResizedImage } from 'helpers/storage'
import { ADD_COMMENT } from 'features/message/message.schema'
import { Node, User, Flow, MessageType, Message } from 'types/graphqlSchema'

import { EditorContent, useEditor } from '@tiptap/react'
import Document from '@tiptap/extension-document'
import Mention from '@tiptap/extension-mention'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Placeholder from '@tiptap/extension-placeholder'
import { MentionSuggestion, mentionSuggestionOptions } from './mentionSuggestionOptions'

import './CommentForm.scss'

type CommentsProps = {
  user: User
  node?: Node
  flow?: Flow
  mobile?: boolean
  comments: Message[]
  loading: boolean
  error: ApolloError | undefined
  sharedUsers: {
    id: string | undefined
    display: string
  }[]
}

const CommentForm = ({
  user,
  node = undefined,
  flow = undefined,
  comments,
  loading,
  error,
  sharedUsers,
}: CommentsProps) => {
  if (!flow && !node) return null
  // GraphQL
  const [mutateAddComment, { loading: isSubmitting }] = useMutation(ADD_COMMENT, {
    onCompleted: () => {
      editor?.commands.clearContent()
    },
  })

  // Tiptap Editor
  const editor = useEditor(
    {
      editorProps: {
        attributes: {
          class: 'tiptapCommentForm',
        },
      },
      extensions: [
        Document,
        Paragraph,
        Text,
        Placeholder.configure({
          placeholder: 'Add your comment here',
        }),
        Mention.configure({
          HTMLAttributes: {
            class: 'mention',
          },
          suggestion: {
            ...mentionSuggestionOptions,
            items: async ({ query }): Promise<MentionSuggestion[]> => {
              const users: MentionSuggestion[] = sharedUsers.map((user) => ({
                mentionLabel: user.display,
                id: user.id ?? '',
              }))

              return Promise.resolve(
                [...users]
                  .filter((item) => item.mentionLabel.toLowerCase().startsWith(query.toLowerCase()))
                  .slice(0, 5),
              )
            },
          },
        }),
      ],
    },
    [sharedUsers.length],
  )

  const disabled = !editor?.getText() || isSubmitting
  const commentListRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!comments) return
    if (!commentListRef.current) return
    commentListRef.current.scroll({ top: 0 })
  }, [comments?.length])

  const handleCreateComment = async () => {
    if (!editor || isSubmitting) return

    const parseMentions = (html: string) => {
      const doc = new DOMParser().parseFromString(html, 'text/html')
      return Array.from(doc.querySelectorAll('[data-id]')).map((element) => element.getAttribute('data-id'))
    }

    const mentionedUserIds = parseMentions(editor.getHTML()) || []

    const input = flow
      ? { flowId: flow.id, message: editor.getHTML(), mentions: mentionedUserIds, messageType: MessageType.Comment }
      : { nodeId: node?.id, message: editor.getHTML(), mentions: mentionedUserIds, messageType: MessageType.Comment }

    await mutateAddComment({ variables: { input } })
  }

  if (loading || !comments || !editor) return null

  if (error) throw new Error(error.message)

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        handleCreateComment()
      }}
    >
      <Flex
        bg="transparent"
        borderTop="1px solid var(--chakra-colors-borderRegular)"
        justifyContent="space-between"
        pt="1rem"
        gap=".5rem"
        px=".75rem"
        flexDir="column"
        onClick={() => editor.commands.focus()}
        _hover={{ cursor: 'text' }}
      >
        <Flex>
          <Avatar
            mr={4}
            name={user.avatarSecureUrl ? '' : `${user.firstName} ${user.lastName}`}
            size="sm"
            src={user?.avatarSecureUrl ? getResizedImage(user.avatarSecureUrl, 'profile.main') : '#'}
          />

          <Box
            w="full"
            border="1px solid"
            borderColor="borderRegular"
            rounded={6}
            p=".25rem"
            maxH="125px"
            overflowY="scroll"
            css={{
              '&::-webkit-scrollbar': {
                width: '0',
              },
              '&::-webkit-scrollbar-track': {
                width: '0',
              },
            }}
            // pb="1.5rem"
            pos="relative"
          >
            <EditorContent
              className="tiptapCommentForm"
              editor={editor}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && e.shiftKey) {
                  e.preventDefault()
                  handleCreateComment()
                }
              }}
            />
          </Box>
        </Flex>
        <Flex flexDir="column" justifyContent={'space-between'}>
          <Button
            variant="defaultButton"
            _focusVisible={{ boxShadow: '0 0 0 1px var(--chakra-colors-borderFocus)' }}
            isDisabled={disabled}
            type="submit"
            alignSelf="flex-end"
            px="1rem"
            fontSize={14}
            isLoading={isSubmitting}
            mb=".25rem"
          >
            Submit
          </Button>
        </Flex>
      </Flex>
    </form>
  )
}

export default CommentForm
