import { useMutation, useQuery } from '@apollo/client'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  Text,
  Input,
  Divider,
} from '@chakra-ui/react'
import { AvatarsTooltip, UsersSelect, Toast } from 'components'
import { CREATE_USER_GROUP, GET_USER_GUEST_SHARE_MEMBERS, UPDATE_USER_GROUP, USERS } from 'features/graphql'
import { CreateUserGroupMutation, UserGroup } from 'pages/Admin/hooks/useAdmin'
import { useEffect, useState } from 'react'
import UserGroupTypeDropdown from './UserGroupTypeDropdown'
import { SelectOption } from 'components/fragments/Selects/UsersSelect'
import { uniqBy } from 'lodash'
import { User } from 'types/graphqlSchema'
import { motion } from 'framer-motion'

interface UGMProps {
  isOpen: boolean
  onClose: () => void
  group?: UserGroup
  setUserGroups: React.Dispatch<React.SetStateAction<UserGroup[]>>
  users?: User[]
}

const UserGroupModal = ({ isOpen, onClose, group, setUserGroups, users }: UGMProps) => {
  const { loading, error, data: usersQueryData } = useQuery(USERS)
  const { data: guestUsersQueryData } = useQuery(GET_USER_GUEST_SHARE_MEMBERS)
  const [createUserGroup]: CreateUserGroupMutation = useMutation(CREATE_USER_GROUP, {
    onCompleted: async (data) => {
      const { createUserGroup } = await data
      setUserGroups((prevUserGroups) => [...prevUserGroups, createUserGroup])
      Toast.show({
        icon: 'check',
        message: `${createUserGroup?.name} has been created.`,
      })
    },
  })
  const [updateUserGroup] = useMutation(UPDATE_USER_GROUP, {
    onCompleted: async (data) => {
      const { updateUserGroup } = await data
      setUserGroups(
        (prevGroups) =>
          prevGroups?.map((grp) => (grp.id === updateUserGroup.id ? updateUserGroup : grp)) || [updateUserGroup],
      )
      Toast.show({
        icon: 'check',
        message: `${updateUserGroup?.name} has been updated.`,
      })
    },
  })

  const [groupName, setGroupName] = useState('')
  const [groupType, setGroupType] = useState('Organizational')
  const [userSelectValue, setUserSelectValue] = useState<SelectOption[]>()
  const [userSelectOptions, setUserSelectOptions] = useState<SelectOption[]>([])
  const [selections, setSelections] = useState<User[]>([])
  const [selectOpen, setSelectOpen] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)

  const handleClose = () => {
    setGroupName('')
    setSelections([])
    setIsUpdate(false)
    setGroupType('Organizational')
    onClose()
  }

  useEffect(() => {
    if (!loading && !error) {
      const guestUsers = guestUsersQueryData?.me?.shareEmailSuggestions || []
      const orgUsers = usersQueryData.users
      const users = uniqBy([...orgUsers, ...guestUsers], 'id')
      const userDataWithEmailValue = users.map((user) => ({
        ...user,
        value: user.email,
        label: user.firstName + ' ' + user.lastName,
      }))
      setUserSelectOptions(userDataWithEmailValue)
    }
    if (error) {
      console.error('An error occurred when setting the select options:', error.message)
    }
  }, [loading, error, usersQueryData, guestUsersQueryData?.me?.shareEmailSuggestions])

  const [height, setHeight] = useState('auto')

  useEffect(() => {
    setHeight(selectOpen ? '300px' : '215px')
  }, [selectOpen])

  useEffect(() => {
    if (group) {
      setIsUpdate(true)
      setGroupName(group.name)
      setGroupType(group.groupType)
      setSelections(group.users)
    }
  }, [group])

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />

      <ModalContent
        justifyContent={'center'}
        bg={'textHighlight'}
        color={'textBlack'}
        fontFamily={'Inter'}
        w={'600px'}
        maxW={'80%'}
        py={2}
      >
        <ModalHeader color="textBlack">{isUpdate ? `Update ${group?.name}` : 'Add a group'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody display="flex" flexDir="column">
          <motion.div
            key="shareModal"
            aria-label="Share"
            initial={{ height, opacity: 0 }}
            animate={{
              height,
              opacity: 1,
            }}
            transition={{
              type: 'ease',
              duration: 0.4,
            }}
          >
            <Flex align={{ base: 'flex-start', md: 'center' }} flexDir={{ base: 'column', md: 'row' }}>
              <Text color="textBlack" fontWeight={600} mx={{ base: 0, md: 6 }} minW="max">
                *Name:
              </Text>
              <Flex align="center" borderBottom="1.5px solid var(--chakra-colors-borderLight)" flex={1} w="full">
                <UserGroupTypeDropdown type={groupType} setType={setGroupType} />
                <Divider orientation="vertical" borderColor="borderLight" borderWidth="1px" h="21px" ml="1rem" />
                <Flex flex={1}>
                  <Input
                    type="text"
                    background="transparent"
                    color="textBlack"
                    fontSize="14px"
                    boxShadow="none"
                    border="none"
                    _focus={{ border: 'none', boxShadow: 'none' }}
                    _active={{ border: 'none', boxShadow: 'none' }}
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                  />
                </Flex>
              </Flex>
            </Flex>
            <Text color="textBlack" fontWeight={600} mx={6} mt={8} mb="19px" minW="max">
              *Select Members:
            </Text>
            <UsersSelect
              isOpen={selectOpen}
              setIsOpen={setSelectOpen}
              userSelectOptions={userSelectOptions}
              usersData={users}
              selectValue={userSelectValue}
              setSelectValue={setUserSelectValue}
              selections={selections}
              setSelections={setSelections}
            />
            {!selectOpen && (
              <Flex id="Group Members Avatars" flexDir={'column'} pl={6} mt={8} opacity={selectOpen ? 0 : 1}>
                {!!selections?.length && (
                  <AvatarsTooltip
                    users={selections}
                    clickAction={() => {
                      setSelectOpen(true)
                    }}
                  />
                )}
              </Flex>
            )}
          </motion.div>
        </ModalBody>

        <ModalFooter display="flex" justifyContent="space-between" opacity={selectOpen ? 0 : 1}>
          <Text fontStyle="italic" fontWeight={400} fontSize=".875rem" color="textRegular" ml={6} pt={4}>
            *required
          </Text>
          <Button
            variant="outline"
            isDisabled={selections.length === 0 || selectOpen}
            onClick={() => {
              if (selections.length > 0) {
                isUpdate
                  ? updateUserGroup({
                      variables: {
                        groupId: group?.id,
                        name: groupName,
                        userIds: selections?.map((sel) => sel.id),
                        groupType,
                      },
                    })
                  : createUserGroup({
                      variables: {
                        name: groupName,
                        userIds: selections?.map((sel) => sel.id),
                        groupType,
                      },
                    })
              }
              handleClose()
            }}
          >
            {isUpdate ? 'Update' : 'Done'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default UserGroupModal
