import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  Breadcrumb,
  BreadcrumbItem,
  Flex,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useNavigate, useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'
import { AnimatedCheckLarge, Button, IconButton, TextInput, Toast } from 'components'
import { GET_NODES, PERMANENTLY_DELETE_NODE } from 'features/node/node.schema'
import { useAppSlice, useBoxSlice, useProjectSlice, useTipSlice } from 'features/redux'
import { nodeState, nodeType } from 'helpers/constant'
import { colors, icons, images } from 'theme'
import { Node, NodeState } from 'types/graphqlSchema'
import SaveBreadcrumbs from './SaveBreadcrumbs'
import SaveItem from './SaveItem'

// Folder Creation (on hold)...
// import { CreateNewBox } from 'scenes/components'
// import { uploadFile } from 'helpers/storage'

interface BreadcrumbProps {
  label: string
  type: string
  item: any
}

interface ButtonIconProps {
  imageUrl: string
  style?: React.CSSProperties
}

interface SaveModalProps {
  isReadOnly?: boolean
  isOpen: boolean
  onClose: () => void
  movingResource?: Node
  node: Node
  isBackButton?: boolean
  isMoveTo?: boolean
  saveFlowAsDocument?: boolean
  handleShare?: () => void
  documentName: string
  setDocumentName: React.Dispatch<React.SetStateAction<string>>
}

const ButtonIcon = ({ imageUrl, style }: ButtonIconProps) => <Image src={imageUrl} style={style}></Image>

const SaveModal = ({
  documentName,
  setDocumentName,
  isOpen,
  onClose,
  node,
  isReadOnly,
  movingResource,
  isBackButton,
  isMoveTo,
  saveFlowAsDocument = false,
  handleShare = () => {},
}: SaveModalProps) => {
  const focusRef = useRef<HTMLInputElement | null>(null)

  // Redux & GraphQL
  const [getNodes, { loading }] = useLazyQuery(GET_NODES)
  const { currentTip, tips, moveTip, setTips, updateTip } = useTipSlice()
  const {
    user: { myProject, organizationId },
  } = useAppSlice()
  const { dispatch, moveBox, getBox } = useBoxSlice()
  const { projects, getProject, currentProject } = useProjectSlice()

  // React Router
  const navigate = useNavigate()
  const location = useLocation()
  const isQuickstart = location?.state?.quickstart

  // State
  const [titleFocused, setTitleFocused] = useState(false)

  const projectsInOrganisation = useMemo(() => {
    return projects.filter((proj) => proj.organizationId === organizationId && proj.id !== myProject.id)
  }, [projects])
  const [keyword, setKeyword] = useState('')
  const [showing, setShowing] = useState('default')
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbProps[]>([{ label: 'Tipbox', type: 'default', item: {} }])
  const [listOfBoxes, setListOfBoxes] = useState<Node[]>([])
  const [searchableBoxes, setSearchableBoxes] = useState<Node[]>([])

  const [mutateDeletePendingNode] = useMutation(PERMANENTLY_DELETE_NODE)

  // Setup
  const defaultOptions = [
    {
      iconImageLeft: images.box,
      name: 'Projects',
      clickValue: 'projects',
    },
    {
      iconImageLeft: images.lock,
      name: 'Personal',
      clickValue: `${myProject.id}`,
    },
  ]

  const canBeMoved = () => {
    if (!node.name || showing === 'projects') return false
    if (isQuickstart) return true
    if (
      node?.breadcrumbs?.[node.breadcrumbs.length - 1]?.id !== breadcrumbs[breadcrumbs.length - 1]?.item?.id &&
      showing !== 'default'
    )
      return true
  }

  const setupBreadcrumbsAndFolders = () => {
    if (currentProject && currentProject?.id !== myProject.id) {
      if (currentTip && currentTip?.breadcrumbs) {
        const currentTipBreadcrumbs = currentTip.breadcrumbs.map((crumb) => {
          return { label: crumb.name, type: 'BOX', item: { id: crumb.id } }
        })
        setBreadcrumbs(() => [
          { label: 'Tipbox', type: 'default', item: {} },
          {
            label: 'Projects',
            type: 'projects',
            item: {},
          },
          ...currentTipBreadcrumbs,
        ])
        getChildFolders(currentTip.breadcrumbs[currentTip.breadcrumbs.length - 1], false)
      } else {
        setBreadcrumbs(() => [
          { label: 'Tipbox', type: 'default', item: {} },
          {
            label: 'Projects',
            type: 'projects',
            item: {},
          },
        ])
      }
    }
  }

  useEffect(() => {
    if (isQuickstart) return
    setupBreadcrumbsAndFolders()
  }, [])

  useEffect(() => {
    const fetchSearchableBoxes = async () => {
      try {
        const nodes = await getBoxesForSearch()
        setSearchableBoxes(nodes)
      } catch (error) {
        console.error('Failed to get nodes:', error.message)
      }
    }
    fetchSearchableBoxes()
  }, [])

  const getBoxesForSearch = async () => {
    const params = {
      filter: {
        type: 'multi_conditions',
        conditions: {
          type: nodeType.box,
        },
      },
    }
    try {
      const {
        data: { nodes },
      } = await getNodes({ variables: { ...params } })
      return nodes
    } catch (error: any) {
      throw new Error(error)
    }
  }

  const getChildFolders = async (item: Node, updateBreadcrumb = true) => {
    if (loading || !item) return
    const params = {
      filter: {
        type: 'multi_conditions',
        conditions: {
          parentId: item.id,
          type: nodeType.box,
          state: nodeState.active,
        },
      },
    }
    try {
      const {
        data: { nodes },
      } = await getNodes({ variables: { ...params } })

      setListOfBoxes(nodes)
      setShowing('boxes')
      setKeyword('')
      if (updateBreadcrumb) {
        setBreadcrumbs((prev) => [...prev, { label: item.name, type: item.type, item }])
      }
    } catch (error: any) {
      throw new Error(error)
    }
  }

  // Handlers
  const navigateBack = (type: string, item: any, index: number) => {
    const remainBreadcrumb = [...breadcrumbs].slice(0, index + 1)
    setBreadcrumbs(remainBreadcrumb)
    setShowing(type)
    setKeyword('')
    if (type === 'box' || type === 'project' || type === 'personal' || type === 'BOX' || type === 'PROJECT') {
      getChildFolders(item, false)
    }
  }

  const handleMove = async () => {
    try {
      const { item, label } = breadcrumbs[breadcrumbs.length - 1]
      const destinationId = item.id || myProject.id

      if (movingResource?.type === nodeType.box) {
        await dispatch(moveBox({ resourceId: movingResource.id, destinationId }))
      } else if (movingResource?.type === nodeType.tip) {
        const { payload } = await dispatch(moveTip({ resourceId: movingResource.id, destinationId }))
        if (!payload) throw new Error('Error moving resource')

        // update the UI inside the document
        await dispatch(updateTip({ id: payload.id }))

        // moving while inside a document
        if (location.pathname.includes('/file/')) {
          const path: string[] = []
          payload.breadcrumbs?.forEach((crumb) => {
            if (!crumb || crumb.id === myProject.id) return
            if (crumb.id !== currentTip.id) {
              path.push(crumb.id)
            }
          })
          // redirect path derived from breadcrumbs
          const nodePath = path.join('/')
          const insideBoxId = path.pop()

          // moving to Personal
          if (!destinationId || destinationId === myProject.id || payload.projectId === myProject.id) {
            if (isQuickstart) {
              await dispatch(getProject(myProject.id))
              if (insideBoxId) {
                // set current box for redux
                await dispatch(getBox(insideBoxId))
                // navigate to box (folder) with state for SavedConfirmation Modal
                navigate(`/personal/${nodePath}`, {
                  state: { saved: payload.name, breadcrumbs: payload.breadcrumbs },
                })
              } else {
                navigate('/personal', {
                  state: {
                    saved: payload.name,
                    breadcrumbs: [{ id: myProject.id, name: 'Personal', type: 'PERSONAL' }],
                  },
                })
              }
            } else {
              if (insideBoxId) {
                navigate(`/personal/${nodePath}/file/${payload.id}`, { replace: true })
              } else {
                navigate(`/personal/file/${payload.id}`, { replace: true })
              }
            }
          } else {
            // moving to projects not Personal
            if (item.type === 'PROJECT') {
              navigate(`/project/${destinationId}/file/${payload.id}`, { replace: true })
            } else if (item.type === 'BOX') {
              const project = breadcrumbs.find((obj) => obj.item.type === 'PROJECT')
              if (project) {
                navigate(`/project/${project.item.id}/${destinationId}/file/${payload.id}`, { replace: true })
              }
            }
          }
        }
      }
      Toast.show({
        icon: 'check',
        message: `Your ${movingResource?.type === nodeType.tip ? 'file' : 'folder'} was successfully saved to "${
          label === 'Tipbox' ? 'Personal' : label
        }".`,
        onUndo: saveFlowAsDocument ? undefined : () => handleUndoMove(),
        onShow: !saveFlowAsDocument
          ? undefined
          : () => {
              if (movingResource) navigate(`/file/${movingResource.id}`)
            },
      })
      if (isQuickstart) {
        setShowing('saved')
      } else {
        onClose()
      }
    } catch (e) {
      Toast.show({
        icon: 'error',
        message: `Something went wrong. Failed to save ${movingResource?.type === nodeType.box ? 'folder' : 'file'}`,
      })
    }
  }

  const handleUndoMove = async () => {
    await dispatch(moveTip({ resourceId: node.id, destinationId: node.parent?.id }))
    dispatch(setTips(tips))
    setKeyword('')
    setBreadcrumbs([{ label: 'Tipbox', type: 'default', item: {} }])
    setShowing('default')
  }

  const handleClose = () => {
    if (currentProject && currentProject?.id !== myProject.id && !isQuickstart) {
      setupBreadcrumbsAndFolders()
    } else {
      setBreadcrumbs([{ label: 'Tipbox', type: 'default', item: {} }])
      setShowing('default')
    }
    onClose()
  }

  const handleDontSave = async () => {
    handleClose()
    if (node && node.state === NodeState.Pending) {
      mutateDeletePendingNode({ variables: { id: node.id } })
    }
    if (!isMoveTo && isBackButton) {
      navigate(-1)
    }
  }

  // FOLDER CREATION within MODAL - SET ASIDE FOR NOW //

  // const handleCreateBox = async (boxName: string, coverImage: File | undefined) => {
  //   const { item, type } = breadcrumbs[breadcrumbs.length - 1]
  //   const projectId = type === 'box' ? item.project.id : item.id
  //   const input: ICreateBoxInput = {
  //     name: boxName,
  //     projectId,
  //     state: nodeState.active,
  //     type: nodeType.box,
  //     parentId: type === 'box' ? item.id : projectId,
  //   }
  //   try {
  //     const {
  //       data: { createNode },
  //     } = await apolloClient.mutate({
  //       mutation: CREATE_NODE,
  //       variables: { input },
  //     })
  //     if (coverImage) {
  //       uploadImage(coverImage, createNode)
  //     } else {
  //       setListOfBoxes([createNode, ...listOfBoxes])
  //       setSearchableBoxes([createNode, ...searchableBoxes])
  //     }
  //   } catch (error: any) {
  //     throw new Error(error.message)
  //   }
  // }
  // Upload new Box to s3 and updateDB
  // const uploadImage = async (image: File) => {
  //   if (!image) return
  //   const uploadResponse = await uploadFile(image)
  //   const params = {
  //     coverImage: uploadResponse.fileUrl,
  //     id: box.id,
  //   }
  //   const updatedBox = await dispatch(updateBox(params))
  //   setListOfBoxes([updatedBox.payload, ...listOfBoxes])
  // }

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} isCentered>
        <ModalOverlay />
        <ModalContent
          display={'flex'}
          flexDir={'column'}
          justifyContent={'center'}
          bg={'textHighlight'}
          color={'textBlack'}
          fontFamily={'Inter'}
          w={'600px'}
          maxW={'80%'}
          h={'519px'}
        >
          <ModalHeader color={'inherit'} fontSize={18} py={4}>
            {isMoveTo ? 'Move' : 'Save'} This Document
          </ModalHeader>
          <ModalHeader
            py={0}
            display={'flex'}
            alignItems={'baseline'}
            onClick={() => focusRef.current && focusRef.current.focus()}
          >
            <Input
              ref={focusRef}
              placeholder={!titleFocused ? 'Give me a name' : ''}
              value={documentName}
              disabled={isReadOnly}
              variant="tipSaveModalTitle"
              onChange={(e) => setDocumentName(e.target.value)}
              onFocus={() => setTitleFocused(true)}
              onBlur={() => setTitleFocused(false)}
              color="textBlack"
              _placeholder={{ color: 'textRegular' }}
            />
            <Text
              color="accent"
              fontWeight={400}
              fontStyle="italic"
              position="absolute"
              opacity={'50'}
              mt="9px"
              left={'9rem'}
              fontSize={14}
              display={node.name || titleFocused ? 'none' : 'initial'}
            >
              * required
            </Text>
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody
            display={'flex'}
            flexDirection={'column'}
            borderTop={'1px solid #e9eaec'}
            mx={6}
            px={0}
            maxH={'350px'}
            color="inherit"
          >
            {showing !== 'saved' && (
              <Flex my={'1rem'} flexDirection={'column'} gap={'1.5rem'} color="inherit">
                <Text color="inherit" fontWeight={'semibold'} fontSize={14}>
                  Please select a destination project or folder.
                </Text>
                <TextInput
                  placeholder="Search"
                  value={keyword}
                  handleChange={({ target: { value } }) => {
                    setShowing('default')
                    setBreadcrumbs([{ label: 'Tipbox', type: 'default', item: {} }])
                    setKeyword(value)
                  }}
                  leftIcon={icons.search}
                  isSaveModal
                />
              </Flex>
            )}

            {showing !== 'saved' && (
              <SaveBreadcrumbs clickAction={navigateBack} breadcrumbs={breadcrumbs} isMoveTo={isMoveTo} />
            )}

            <Flex
              flexDir={'column'}
              my="auto"
              minH={'10rem'}
              overflowY={'scroll'}
              overflowX={'hidden'}
              css={{
                '&::-webkit-scrollbar': {
                  width: '0',
                },
                '&::-webkit-scrollbar-track': {
                  width: '0',
                },
              }}
            >
              {/* PROJECTS OR BOXES KEYWORD SEARCH */}
              {keyword.trim() && showing === 'default' && (
                <>
                  {projectsInOrganisation
                    .filter((project) => project.name.trim().toLowerCase().includes(keyword.trim().toLocaleLowerCase()))
                    .map((project: any) => (
                      <SaveItem
                        keyword={keyword}
                        key={project.id}
                        id={project.id}
                        image={project.coverImageSecureUrl || undefined}
                        label={project.name}
                        isDisabled={loading}
                        clickAction={() => {
                          if (loading) return
                          setBreadcrumbs(() => {
                            return [
                              { label: 'Tipbox', type: 'default', item: {} },
                              {
                                label: 'Projects',
                                type: 'projects',
                                item: {},
                              },
                            ]
                          })
                          getChildFolders(project)
                        }}
                      />
                    ))}
                  {searchableBoxes
                    .filter((box) => box.name.trim().toLowerCase().includes(keyword.trim().toLocaleLowerCase()))
                    .map((boxesOption: any) => (
                      <SaveItem
                        keyword={keyword}
                        key={boxesOption.id}
                        id={boxesOption.id}
                        color={boxesOption.color}
                        image={boxesOption.coverImage}
                        label={boxesOption.name}
                        isDisabled={loading}
                        clickAction={() => {
                          if (loading) return
                          const boxesOptionBreadcrumbs = boxesOption?.breadcrumbs?.map((crumb) => {
                            return { label: crumb.name, type: 'BOX', item: { id: crumb.id } }
                          })
                          setBreadcrumbs(() => [
                            { label: 'Tipbox', type: 'default', item: {} },
                            {
                              label: 'Projects',
                              type: 'projects',
                              item: {},
                            },
                            ...boxesOptionBreadcrumbs,
                          ])
                          getChildFolders(boxesOption)
                        }}
                      />
                    ))}
                </>
              )}

              {/* DEFAULT VIEW */}
              {!keyword.trim() && showing === 'default' && (
                <>
                  {defaultOptions?.map((option) => (
                    <SaveItem
                      isPersonalButton={option.name === 'Personal'}
                      isQuickstart={isQuickstart}
                      keyword={keyword}
                      key={option.name}
                      id={option.name}
                      iconImageLeft={<ButtonIcon imageUrl={option.iconImageLeft} style={{}} />}
                      iconImageRight={<ButtonIcon imageUrl={images.chevronRightBreadcrumb} />}
                      label={option.name}
                      isDisabled={loading}
                      clickAction={() => {
                        if (loading) return
                        if (option.clickValue === 'projects') {
                          setShowing('projects')
                          setBreadcrumbs((prev) => [
                            ...prev,
                            {
                              label: 'Projects',
                              type: 'projects',
                              item: {},
                            },
                          ])
                        } else {
                          setBreadcrumbs((prev) => {
                            return [
                              ...prev,
                              {
                                label: 'Projects',
                                type: 'projects',
                                item: {},
                              },
                              {
                                label: 'Personal',
                                type: 'personal',
                                item: myProject,
                              },
                            ]
                          })
                          getChildFolders(myProject, false)
                        }
                      }}
                    />
                  ))}
                </>
              )}
              {/* PROJECT */}
              {showing === 'projects' && (
                <>
                  {projectsInOrganisation?.map((projectOption: any) => (
                    <SaveItem
                      keyword={keyword}
                      key={projectOption.id}
                      id={projectOption.id}
                      image={projectOption.coverImageSecureUrl || undefined}
                      iconImageLeft={<ButtonIcon imageUrl={images.boxWhite} />}
                      iconImageRight={<ButtonIcon imageUrl={images.chevronRightBreadcrumb} />}
                      label={projectOption.name}
                      clickAction={() => {
                        if (loading) return
                        getChildFolders(projectOption)
                      }}
                      isProjects
                      isDisabled={loading}
                    />
                  ))}
                </>
              )}
              {/* BOXES */}
              {showing === 'boxes' && (
                <>
                  {listOfBoxes?.map((boxesOption: any) => {
                    return (
                      <>
                        {boxesOption.id !== movingResource?.id && (
                          <SaveItem
                            key={boxesOption.id}
                            keyword={keyword}
                            id={boxesOption.id}
                            color={boxesOption.color}
                            image={boxesOption.coverImage}
                            label={boxesOption.name}
                            isDisabled={loading}
                            clickAction={() => {
                              if (loading) return
                              getChildFolders(boxesOption)
                            }}
                          />
                        )}
                      </>
                    )
                  })}
                </>
              )}
              {/* NO BOXES */}
              {showing === 'boxes' && listOfBoxes.length === 0 && (
                <motion.div key="empty" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                  <Text mt={'2rem'} textAlign={'center'}>
                    No folders here...
                  </Text>
                </motion.div>
              )}
              {/* DOCUMENT SAVED */}
              {showing === 'saved' && (
                <motion.div key="saved" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                  <Flex flexDir={'column'} mx="auto" alignItems={'center'} gap={'1.5rem'} maxH={'342px'} pt={'3rem'}>
                    <AnimatedCheckLarge caption={isMoveTo ? 'Document Moved.' : 'Document Saved.'} />

                    <IconButton
                      icon={icons.share}
                      iconMarginLeft={0}
                      iconMarginRight={3}
                      ariaLabel="Share"
                      label={'Share'}
                      variant="defaultButton"
                      onClick={handleShare}
                    ></IconButton>
                  </Flex>
                </motion.div>
              )}
            </Flex>
          </ModalBody>

          <ModalFooter borderTop={showing !== 'saved' ? '1px solid #e9eaec' : 'none'} mx={6} px={0}>
            {showing !== 'saved' && (
              <>
                {/* FOLDER CREATION - on hold */}
                {/* <Button
                  variant="iconBtnRed"
                  label="Create a new folder"
                  leftIcon={<Icon as={customIcons.addBoxIcon} color="accent" w="24px" h="24px" pt={0} />}
                  onClick={() => {
                    setOpenCreate('newBox')
                  }}
                  style={{
                    // display: 'flex',
                    // flexDirection: 'column',
                    maxWidth: '180px',
                    maxHeight: '40px',
                    padding: 0,
                    fontSize: '14px',
                    marginRight: 'auto',
                  }}
                  disabled={showing !== 'boxes'}
                /> */}
                <Button
                  label={isBackButton ? `Don't ${isMoveTo ? 'Move' : 'Save'}` : 'Cancel'}
                  variant="btnOutline"
                  mr={3}
                  onClick={() => handleDontSave()}
                ></Button>

                <Button
                  label={isMoveTo ? 'Move' : 'Save'}
                  variant="defaultButton"
                  disabled={!canBeMoved()}
                  onClick={() => {
                    if (breadcrumbs.some((crumb) => crumb.item.id === myProject.id)) {
                      onClose()
                    }
                    dispatch(updateTip({ id: node.id, name: documentName, state: 'ACTIVE' }))
                    handleMove()
                  }}
                ></Button>
              </>
            )}
            {showing === 'saved' && (
              <Flex mr={'auto'} gap=".5rem" maxW={'100%'} overflow={'hidden'} mt={1.5} mb={1.5}>
                <Text minW={'max-content'} fontWeight={'semibold'} fontSize={14} color={colors.folder.darkGray.body}>
                  Saved To:
                </Text>

                {breadcrumbs.length > 3 && (
                  <Text fontSize={14} minW={'max'}>
                    {breadcrumbs[2].label.length > 25 ? breadcrumbs[2].label.substring(0, 25) : breadcrumbs[2].label}
                  </Text>
                )}
                <Breadcrumb
                  separator={<ButtonIcon imageUrl={images.chevronRightBreadcrumb} style={{ marginTop: '-2px' }} />}
                >
                  {breadcrumbs.length > 2 && (
                    <BreadcrumbItem mb={'.5rem'}>
                      <Text fontSize={14}>...</Text>
                    </BreadcrumbItem>
                  )}
                  {breadcrumbs.length > 2
                    ? breadcrumbs.slice(-1)?.map((crumb) => (
                        <BreadcrumbItem key={crumb.item.id} mb={'.5rem'} minWidth={'max-content'}>
                          <Text color={colors.folder.darkGray.body} fontSize={14}>
                            {crumb.label?.length > 20 ? crumb.label.substring(0, 20) + '...' : crumb.label}
                          </Text>
                        </BreadcrumbItem>
                      ))
                    : breadcrumbs?.map((crumb) => (
                        <BreadcrumbItem key={crumb.item.id} mb={'.5rem'} minWidth={'max-content'}>
                          <Text color={colors.folder.darkGray.body} fontSize={14}>
                            {breadcrumbs.length === 1 && breadcrumbs[0].label === 'Tipbox'
                              ? 'Personal'
                              : crumb.label?.length > 14
                              ? `${crumb.label.substring(0, 14)}...`
                              : crumb.label}
                          </Text>
                        </BreadcrumbItem>
                      ))}
                </Breadcrumb>
              </Flex>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default SaveModal
