import React from 'react'
import { Flex, Avatar, Text, Checkbox } from '@chakra-ui/react' // Assuming Chakra UI
import { getResizedImage } from 'helpers/storage'
import { colors } from 'theme'

const UserSelectable = ({ data, selected }) => {
  const avatarUrl = data.avatarSecureUrl ? getResizedImage(data.avatarSecureUrl, 'profile.account') : ''

  return (
    <Flex
      pl={4}
      py={2}
      gap={2}
      rounded={6}
      justifyContent="flex-start"
      w="full"
      bg="inherit"
      _hover={{ bg: colors.tipbox.dark.border.lightGrey }}
      cursor="pointer"
    >
      {avatarUrl ? (
        <Avatar name={data.avatarSecureUrl ? '' : `${data.firstName} ${data.lastName}`} size={'sm'} src={avatarUrl} />
      ) : (
        <Avatar name={`${data.firstName} ${data.lastName}`} size={'sm'} />
      )}
      <Flex flexDir="column" color="textBlack" alignItems="flex-start">
        <Text color="inherit" fontSize="sm" mb={-1}>
          {data.firstName} {data.lastName}
        </Text>
        {data.organization?.name && (
          <Text color="inherit" fontSize="2xs">
            {data.organization?.name}
          </Text>
        )}
      </Flex>
      <Checkbox ml="auto" mr={4} variant="tipbox" isChecked={selected} pointerEvents={'none'} />
    </Flex>
  )
}

export default UserSelectable
