import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useBreakpointValue } from '@chakra-ui/react'
import { useAppSlice, useProjectSlice, useBoxSlice, useTipSlice, useLinkShareSlice } from 'features/redux'
import { icons } from 'theme'
import BreadcrumbMobile from './components/BreadcrumbsMobile'
import BreadcrumbDeskTop from './components/BreadcrumbDesktop'
import { MY_NODE_IDS } from 'features/graphql'
import { useQuery } from '@apollo/client'
import { intersection } from 'lodash'

interface CurrentNode {
  name: string
  type: string
  id: string
  breadcrumbs: IBreadcrumb[]
}

interface BreadcrumbProps {
  isLinkShare?: boolean
}

const Breadcrumb = ({ isLinkShare }: BreadcrumbProps) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isMobile = useBreakpointValue({
    xs: true,
    sm: true,
    md: true,
    lg: false,
  })
  const {
    setIsLoading,
    user: { id: userId },
  } = useAppSlice()
  const {
    dispatch,
    originalNode,
    node,
    tip,
    publicLink: { randomPath },
    clearLinkShareTip,
    getRandomPathNode,
    getRandomPathNodeChild,
  } = useLinkShareSlice()
  const { currentProject, getProject } = useProjectSlice()
  const { currentBox, clearCurrentBox, getBox } = useBoxSlice()
  const { currentTip, clearCurrentTip } = useTipSlice()

  const [menuOpen, setMenuOpen] = useState(false)
  const [currentNode, setCurrentNode] = useState<CurrentNode>()
  const [breadcrumbBar, setBreadcrumbBar] = useState<IBreadcrumb[]>([])
  const [breadcrumbMenu, setBreadcrumbMenu] = useState<IBreadcrumb[]>([])

  const { data: nodeIdData } = useQuery(MY_NODE_IDS)

  const formatBreadcrumbs = (crumbs) => {
    if (crumbs.length > 2) {
      setBreadcrumbBar(crumbs.slice(-2))
      setBreadcrumbMenu(crumbs.slice(0, -2))
    } else {
      setBreadcrumbBar(crumbs)
      setBreadcrumbMenu([])
    }
  }

  const setupBreadcrumbs = () => {
    if (!currentNode || !nodeIdData || !currentProject) {
      setBreadcrumbBar([])
      setBreadcrumbMenu([])
      return
    }
    const isOwnerOrSharedProject =
      currentProject.ownerId === userId || currentProject.sharedMembers?.some((sm) => sm?.sharedWithId === userId)
    const { breadcrumbs } = currentNode

    if (isOwnerOrSharedProject) {
      formatBreadcrumbs(breadcrumbs)
      return
    }

    // Node is shared with user (not whole project):
    // (check if id returned w myNodes query)
    const breadcrumbIds = breadcrumbs.map((bc) => bc.id)
    const myAccessibleIds: string[] = nodeIdData.myNodes?.map((node) => node?.id) || []
    const sharedIds = intersection(myAccessibleIds, breadcrumbIds)

    // myNodeIds doesn't include ids for projects containing folders shared with user
    // so we add the project breadcrumb back to the list
    const addProjectBreadcrumb = breadcrumbs.length !== 0 ? !sharedIds.includes(breadcrumbs[0].id) : false

    // If myNodes query hasn't returned an associated nodeId
    // we remove that breadcrumb
    const filteredBreadcrumbs =
      breadcrumbs.length > 0
        ? addProjectBreadcrumb
          ? [breadcrumbs[0], ...breadcrumbs.filter((bc) => sharedIds.includes(bc.id))]
          : breadcrumbs.filter((bc) => sharedIds.includes(bc.id))
        : []

    formatBreadcrumbs(filteredBreadcrumbs)
  }

  const checkBreadCrumbWidth = () => {
    const parent = document.querySelector('#breadcrumbParent')
    const breadcrumbsDiv = document.querySelector('#breadcrumbsDiv')

    if (breadcrumbBar.length < 1) return
    if (parent && breadcrumbsDiv && parent?.clientWidth < breadcrumbsDiv?.clientWidth) {
      const breadcrumbs = [...breadcrumbBar]
      const firstBreadcrumb = breadcrumbs.shift()
      setBreadcrumbBar(breadcrumbs)
      if (firstBreadcrumb) {
        setBreadcrumbMenu([...breadcrumbMenu, firstBreadcrumb])
      }
    } else if (parent && breadcrumbsDiv && breadcrumbsDiv?.clientWidth / parent?.clientWidth < 0.6) {
      const breadcrumbs = [...breadcrumbMenu]
      const lastCrumb = breadcrumbs.pop()
      if (lastCrumb) {
        setBreadcrumbBar([lastCrumb, ...breadcrumbBar])
        setBreadcrumbMenu(breadcrumbs)
      }
    }
  }

  const handleNavagationParentPage = (path: string) => {
    navigate(path)
    setBreadcrumbBar([])
    setBreadcrumbMenu([])
  }

  const handleLinkShareNavigation = (navigateNode: IBreadcrumb) => {
    if (originalNode?.id === navigateNode.id) {
      dispatch(getRandomPathNode(randomPath))
    } else {
      dispatch(getRandomPathNodeChild({ randomPath, nodeId: navigateNode.id }))
      dispatch(clearLinkShareTip())
    }
  }

  const handleNavigationClick = (navigateNode: IBreadcrumb) => {
    setMenuOpen(false)
    if (isLinkShare) {
      handleLinkShareNavigation(navigateNode)
    } else {
      if (navigateNode.type === 'PROJECT') {
        dispatch(setIsLoading(true))
        dispatch(getProject(navigateNode.id))
        navigate(`/project/${navigateNode.id}`)
      }

      if (currentNode) {
        if (currentNode.type === 'TIP') {
          dispatch(clearCurrentBox())
          dispatch(clearCurrentTip())
        }
        if (navigateNode.type === 'BOX') {
          let add = true
          const path: string[] = []
          currentNode.breadcrumbs?.forEach((item) => {
            if (add && item && item.id) {
              path.push(item.id)
              if (item.id === navigateNode.id) {
                add = false
              }
            }
          })
          const nodePath = path.join('/')
          if (pathname.includes('personal')) {
            navigate(`/personal/${nodePath}`)
          } else {
            navigate(`/project/${nodePath}`)
          }
          dispatch(setIsLoading(true))
          dispatch(getBox(navigateNode.id))
        }
      }
    }
  }

  const getMainLabel = () => {
    if (pathname === '/') return { label: 'Projects', path: '/', bold: true }
    if (pathname.includes('project'))
      return {
        label: 'Projects',
        path: '/',
        bold: pathname === '/',
      }
    if (pathname.includes('following'))
      return {
        label: 'Following',
        path: '/following',
        bold: pathname === '/following',
      }
    if (pathname.includes('trash'))
      return {
        label: 'Trash',
        path: '/trash',
        bold: pathname === '/trash',
      }
    if (pathname.includes('recent'))
      return {
        label: 'Recents',
        path: '/recent',
        bold: pathname === '/recent',
      }
    if (pathname.includes('personal')) {
      return {
        label: 'Personal',
        path: '/personal',
        bold: pathname === '/personal',
        tooltipIcon: icons.outlineQuestionMark,
        tooltip: 'Move items to a project later if you want to share or start a Task with others.',
      }
    }
    if (pathname.includes('flows')) {
      return { label: 'Flows', path: '/flows', bold: pathname === '/flows' }
    }

    return { label: 'Projects', path: '/', bold: true }
  }

  const resizedWindow = () => {
    checkBreadCrumbWidth()
  }

  useEffect(() => {
    if (currentTip) {
      if (pathname.includes('personal')) {
        let newBreadCrumbs = [...currentTip.breadcrumbs]
        if (newBreadCrumbs.length > 1) {
          newBreadCrumbs.shift()
        } else {
          newBreadCrumbs = []
        }
        setCurrentNode({
          breadcrumbs: newBreadCrumbs,
          name: currentTip.name,
          type: 'TIP',
          id: currentTip.id,
        })
      } else {
        setCurrentNode({
          breadcrumbs: currentTip.breadcrumbs,
          name: currentTip.name,
          type: 'TIP',
          id: currentTip.id,
        })
      }
      return
    }
    if (currentBox) {
      if (pathname.includes('personal')) {
        let newBreadCrumbs = [...currentBox.breadcrumbs]
        if (newBreadCrumbs.length > 1 && newBreadCrumbs.length < 3) {
          newBreadCrumbs.shift()
        } else if (newBreadCrumbs.length === 1) {
          newBreadCrumbs = []
        }
        setCurrentNode({
          breadcrumbs: newBreadCrumbs,
          name: currentBox.name,
          type: 'BOX',
          id: currentBox.id,
        })
      } else {
        setCurrentNode({
          breadcrumbs: currentBox.breadcrumbs,
          name: currentBox.name,
          id: currentBox.id,
          type: 'BOX',
        })
      }
      return
    }
    setCurrentNode(undefined)
  }, [currentBox, currentTip])

  useEffect(() => {
    setupBreadcrumbs()
  }, [currentNode])

  useEffect(() => {
    checkBreadCrumbWidth()
  }, [breadcrumbBar])

  useEffect(() => {
    if (tip && tip.breadcrumbs) {
      setCurrentNode({
        breadcrumbs: tip.breadcrumbs,
        name: tip.name,
        id: tip.id,
        type: tip.type,
      })
    }
    if (node && node.breadcrumbs && !tip) {
      setCurrentNode({
        breadcrumbs: node.breadcrumbs,
        name: node.name,
        type: node.type,
        id: node.id,
      })
    }
  }, [node, tip])

  useEffect(() => {
    // check window width
    window.addEventListener('resize', resizedWindow)

    // clean up
    return () => {
      window.removeEventListener('resize', resizedWindow)
    }
  }, [])

  return (
    <>
      {isMobile ? (
        <BreadcrumbMobile
          currentNode={currentNode}
          project={currentProject}
          handleClick={handleNavigationClick}
          mainLabel={getMainLabel()}
        />
      ) : (
        <BreadcrumbDeskTop
          currentNode={currentNode}
          project={currentProject}
          handleClick={handleNavigationClick}
          handleNavagationParentPage={handleNavagationParentPage}
          mainLabel={getMainLabel()}
          isLinkShare={isLinkShare}
          breadcrumbMenu={breadcrumbMenu}
          breadcrumbBar={breadcrumbBar}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          pathname={pathname}
        />
      )}
    </>
  )
}

export default Breadcrumb
