import { useState } from 'react'
import {
  Button,
  Checkbox,
  Divider,
  Flex,
  Grid,
  Icon,
  IconButton,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import { AnimatedMenuChevron, Toast } from 'components/elements'
import { useAppSlice, useBoxSlice, useProjectSlice, useTipSlice, useWorkflowSlice } from 'features/redux'
import { manageOrgUsers, retrieveOrgUsers } from 'helpers/auth'
import config from 'helpers/config'
import { images, icons } from 'theme'
import { User } from 'types/graphqlSchema'
import { ComfirmationModal } from '../Modal'

const NavOrganisationDropdown = ({ title }) => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const { dispatch, user, setAddAccount } = useAppSlice()
  const { clearCurrentProject, clearProjects } = useProjectSlice()
  const { clearBoxes, clearCurrentBox } = useBoxSlice()
  const { clearCurrentTip, clearTips } = useTipSlice()
  const { clearWorkflows } = useWorkflowSlice()
  const [hoveredIndex, setHoveredIndex] = useState(-1)
  const cognitoClientId = config.AWS.COGNITO.CLIENT_ID
  const authenticatedOrgUsers = retrieveOrgUsers()
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false)
  const [removeUser, setRemoveUser] = useState<User>()

  const handleAddAccount = () => {
    dispatch(setAddAccount(true))
  }

  const selectAccountToRemove = (selectedUser: User) => {
    setOpenConfirmationModal(true)
    setRemoveUser(selectedUser)
  }

  const handleRemoveAccount = () => {
    if (!removeUser || removeUser.id === user?.id) return
    manageOrgUsers('remove', removeUser)
    setOpenConfirmationModal(false)
    Toast.show({
      icon: 'check',
      message: `${removeUser?.organization?.name} has been successfully removed`,
    })
  }

  const handleSwitchUser = (username) => {
    dispatch(clearBoxes())
    dispatch(clearCurrentBox())
    dispatch(clearProjects())
    dispatch(clearCurrentProject())
    dispatch(clearTips())
    dispatch(clearCurrentTip())
    dispatch(clearWorkflows())
    const scope = Sentry.getCurrentScope()
    scope.setUser(null)
    localStorage.setItem(`CognitoIdentityServiceProvider.${cognitoClientId}.LastAuthUser`, username)
    dispatch(setAddAccount(false))
    history.go(0)
  }
  return (
    <Flex px=".5rem" justifyContent="space-between">
      <Button
        maxW="min"
        variant="outline"
        borderColor="transparent"
        color="textHighlight"
        textAlign="left"
        fontWeight={400}
        onClick={onToggle}
        w="full"
        fontSize="1rem"
        _focusVisible={{ boxShadow: '0 0 0 1px var(--chakra-colors-borderFocus)' }}
      >
        {title}
      </Button>
      <Popover returnFocusOnClose={false} isOpen={isOpen} onClose={onClose} placement="bottom-end" closeOnBlur>
        <PopoverTrigger>
          <button
            onClick={onToggle}
            style={
              {
                paddingRight: '2rem',
                outline: 'none',
                ':focus-visible': {},
              } as React.CSSProperties
            }
          >
            <AnimatedMenuChevron isOpen={isOpen} />
          </button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent bg="textHighlight" border="none" color="textBlack" padding=".75rem">
            <PopoverHeader color="inherit" fontSize=".875rem" fontWeight={500}>
              Switch Organizations
            </PopoverHeader>
            <PopoverBody>
              <Flex flexDir="column">
                <Flex flexDir="column" gap="1rem" pb={4}>
                  {authenticatedOrgUsers.map((orgUser, index) => (
                    <Flex
                      key={orgUser.username}
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(-1)}
                      px="3px"
                    >
                      <Flex
                        flex={1}
                        justifyContent="space-between"
                        align="center"
                        _hover={{
                          cursor: 'pointer',
                        }}
                        onClick={() => handleSwitchUser(orgUser.username)}
                      >
                        <Checkbox
                          variant="tipbox"
                          isChecked={orgUser.username === user.username || index === hoveredIndex}
                        />
                        <Grid
                          w="28px"
                          h="28px"
                          bgColor="accentIcon"
                          bgImage={orgUser?.organization?.logoSecureUrl ?? ''}
                          bgSize="contain"
                          rounded="md"
                          ml="1.2rem"
                          mr=".5rem"
                          color="textBlack"
                          placeContent="center"
                        >
                          {orgUser?.organization?.logoSecureUrl ? '' : orgUser.organization?.name?.charAt(0)}
                        </Grid>
                        <Flex flexDir="column" mr="auto" color="textBlack" lineHeight={1.3}>
                          <Text fontSize=".875rem" fontWeight={500} color="inherit" p={0} m={0}>
                            {orgUser.organization?.name}
                          </Text>
                          <Text m={0} p={0} color="textRegular" fontSize="11px">
                            {orgUser.email}
                          </Text>
                        </Flex>
                      </Flex>
                      <Tooltip
                        px={3}
                        py={1}
                        borderRadius={4}
                        hasArrow
                        label="Remove"
                        placement="top"
                        bg="cardBackground"
                        color="white"
                      >
                        <IconButton
                          variant="unstyled"
                          visibility={orgUser.id === user.id ? 'hidden' : 'visible'}
                          _hover={{ bg: '#E9EAEC', svg: { fill: 'folder.darkGray.body' } }}
                          icon={<Icon as={icons.close} color="#B3B3B3" />}
                          aria-label={'Remove'}
                          alignSelf="center"
                          onClick={() => selectAccountToRemove(orgUser)}
                        />
                      </Tooltip>
                    </Flex>
                  ))}
                </Flex>
                <Divider borderColor="textRegular" h="1pt" />
                <Button display="flex" alignSelf="flex-start" p={0} pt={4} gap="1.2rem" onClick={handleAddAccount}>
                  <Image src={images.plusBoxPurple} />
                  <Text fontWeight={500} color="textBlack">
                    Add organization
                  </Text>
                </Button>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
      <ComfirmationModal
        name={removeUser?.organization?.name ? removeUser.organization.name : ''}
        header="Remove Organization"
        confirmAction={handleRemoveAccount}
        confirmType="removeOrg"
        isOpen={openConfirmationModal}
        close={() => setOpenConfirmationModal(false)}
      />
    </Flex>
  )
}

export default NavOrganisationDropdown
