import { Flex, Image, Text } from '@chakra-ui/react'
import { truncateString } from 'helpers/utils'

const textHighlightStyles = {
  _hover: { color: 'textHighlight' },
  transition: 'color 200ms linear',
}

const BlockTitle = ({ name, isMobile, extensionIcon, visibleNameChars = 30 }) => {
  return (
    <Flex align="center" gap={8} minW={'200px'}>
      <Image src={extensionIcon} alt="File" w={'35px'} h="35px" cursor="pointer" />
      <Text {...textHighlightStyles} cursor="pointer" fontSize={16} minW={isMobile ? 'max-content' : ''}>
        {truncateString(name, visibleNameChars)}
      </Text>
    </Flex>
  )
}

export default BlockTitle
