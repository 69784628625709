import {
  Flex,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { Dispatch } from 'react'
import { images } from 'theme'

interface FlowFormRecipientTypeProps {
  type: string
  setType: Dispatch<React.SetStateAction<string>>
}

const UserGroupTypeDropdown = ({ type, setType }: FlowFormRecipientTypeProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure()

  return (
    <Flex flexDir="column" gap={3} minW="max" maxW="max" py={2}>
      <Flex>
        <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement="bottom-start">
          <PopoverTrigger>
            <button style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
              <Text color={'accent'} fontWeight={'500'}>
                {type}
              </Text>
              <Image src={images.chevronRedDown} alt="down arrow" />
            </button>
          </PopoverTrigger>
          <PopoverContent maxW={'max-content'} border={'none'}>
            <PopoverBody
              borderColor="gray.400"
              shadow={'md'}
              borderWidth={1}
              borderStyle={'solid'}
              bg={'white'}
              color={'textBlack'}
              display={'flex'}
              pr={8}
              py={4}
              gap={2}
              flexDir={'column'}
              alignItems={'flex-start'}
              fontWeight={'400'}
              rounded={'md'}
              minW="max"
            >
              <button
                onClick={() => {
                  setType('Private')
                  onClose()
                }}
              >
                <Text color="textBlack" fontWeight={400}>
                  Private <span style={{ color: 'var(--chakra-colors-textBlack)' }}>- your custom groups</span>
                </Text>
              </button>
              <button
                onClick={() => {
                  setType('Organizational')
                  onClose()
                }}
              >
                <Text color="textBlack" fontWeight={400}>
                  Organizational{' '}
                  <span style={{ color: 'var(--chakra-colors-textBlack)' }}>- accessible to everyone</span>
                </Text>
              </button>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>
    </Flex>
  )
}

export default UserGroupTypeDropdown
