import React, { useCallback, useState } from 'react'
import {
  Button as ChakraButton,
  Flex,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { Editor } from '@tiptap/react'
import { Button, IconButton, AnimatedMenuChevron } from 'components'
import { validateUrl } from 'helpers/utils'
import {
  RiArrowGoBackLine,
  RiArrowGoForwardLine,
  RiBold,
  RiItalic,
  RiLinkM,
  RiListCheck3,
  RiListOrdered,
  RiListUnordered,
  RiStrikethrough,
} from 'react-icons/ri'
import { colors, icons } from 'theme'
import styles from './Toolbar.module.css'

type ToolbarProps = {
  editor: Editor
  editable?: boolean
  onRemove: () => void
}

const buttonStyles = {
  h: '29px',
  w: '29px',
  fontSize: '1.25rem',
  minW: 0,
  variant: 'unstyled',
  display: 'grid',
  placeContent: 'center',
  _hover: { background: 'menuBackground', color: 'textHighlight' },
  _focusVisible: { boxShadow: '0 0 0 1px var(--chakra-colors-borderFocus)' },
}

export default ({ editor, editable = true, onRemove }: ToolbarProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [url, setUrl] = useState('http://')
  const [isValid, setIsValid] = useState(false)

  const handleUrlChange = (e) => {
    const value = e.target.value
    setUrl(value)
    const valid = validateUrl(value)
    setIsValid(valid)
  }

  const handleSubmit = () => {
    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
    onClose()
  }

  const setLink = useCallback(() => {
    onOpen()
  }, [onOpen])

  //
  if (!editor) return null
  if (!editable) return null

  const getButtonTitle = (editor) => {
    if (editor.isActive('paragraph')) {
      return 'Normal'
    }
    if (editor.isActive('heading', { level: 3 })) {
      return 'Large'
    }
    if (editor.isActive('heading', { level: 1 })) {
      return 'Huge'
    }
    return 'Paragraph'
  }

  return (
    <div className={styles.ToolbarContainer}>
      <Flex gap=".25rem" height="1.75rem" align="center">
        <Menu>
          {({ isOpen }) => (
            <>
              <MenuButton
                display="flex"
                alignItems="center"
                gap="1rem"
                variant="unstyled"
                minH={0}
                h="min"
                py={1}
                px={4}
                mr={2}
                fontSize="14px"
                border="2px solid var(--chakra-colors-borderLight)"
                _focusVisible={{ boxShadow: '0 0 0 1px var(--chakra-colors-borderFocus)' }}
                rounded="lg"
                as={ChakraButton}
                rightIcon={<AnimatedMenuChevron isOpen={isOpen} />}
              >
                {getButtonTitle(editor)}
              </MenuButton>
              <MenuList bg="white" border="1px solid var(--chakra-colors-textRegular)" minW={0} w="7.5rem">
                <MenuItem bg="white" w="full" px={1}>
                  <ChakraButton
                    variant="unstyled"
                    w="full"
                    display="flex"
                    gap=".75rem"
                    justifyContent="flex-start"
                    rounded="none"
                    px="10px"
                    _hover={{ bg: 'textBlack', color: 'white' }}
                    onClick={() => editor.chain().focus().setParagraph().run()}
                  >
                    <span>Normal</span>
                  </ChakraButton>
                </MenuItem>
                <MenuItem bg="white" w="full" px={1}>
                  <ChakraButton
                    variant="unstyled"
                    w="full"
                    display="flex"
                    gap=".75rem"
                    justifyContent="flex-start"
                    rounded="none"
                    fontSize="1.4rem"
                    px="10px"
                    _hover={{ bg: 'textBlack', color: 'white' }}
                    onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                  >
                    <span>Large</span>
                  </ChakraButton>
                </MenuItem>
                <MenuItem bg="white" w="full" px={1}>
                  <ChakraButton
                    variant="unstyled"
                    w="full"
                    display="flex"
                    gap=".75rem"
                    justifyContent="flex-start"
                    rounded="none"
                    px="10px"
                    fontSize="2.25rem"
                    _hover={{ bg: 'textBlack', color: 'white' }}
                    onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                  >
                    <span>Huge </span>
                  </ChakraButton>
                </MenuItem>
              </MenuList>
            </>
          )}
        </Menu>
        <ChakraButton
          {...buttonStyles}
          background={editor.isActive('bold') ? 'background' : 'white'}
          color={editor.isActive('bold') ? 'white' : 'background'}
          onClick={() => editor.chain().focus().toggleBold().run()}
        >
          <RiBold />
        </ChakraButton>
        <ChakraButton
          {...buttonStyles}
          background={editor.isActive('italic') ? 'background' : 'white'}
          color={editor.isActive('italic') ? 'white' : 'background'}
          onClick={() => editor.chain().focus().toggleItalic().run()}
        >
          <RiItalic />
        </ChakraButton>
        <ChakraButton
          {...buttonStyles}
          background={editor.isActive('strike') ? 'background' : 'white'}
          color={editor.isActive('strike') ? 'white' : 'background'}
          onClick={() => editor.chain().focus().toggleStrike().run()}
        >
          <RiStrikethrough />
        </ChakraButton>

        <div className={styles.divider}></div>
        <ChakraButton
          {...buttonStyles}
          background={editor.isActive('link') ? 'background' : 'white'}
          color={editor.isActive('link') ? 'white' : 'background'}
          onClick={() => {
            !editor.isActive('link') ? setLink() : editor.chain().focus().unsetLink().run()
          }}
        >
          <RiLinkM />
        </ChakraButton>
        <div className={styles.divider}></div>

        <ChakraButton
          {...buttonStyles}
          background={editor.isActive('bulletList') ? 'background' : 'white'}
          color={editor.isActive('bulletList') ? 'white' : 'background'}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
        >
          {/* <RiListOrdered /> */}
          <RiListUnordered />
        </ChakraButton>
        <ChakraButton
          {...buttonStyles}
          background={editor.isActive('orderedList') ? 'background' : 'white'}
          color={editor.isActive('orderedList') ? 'white' : 'background'}
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
        >
          <RiListOrdered />
        </ChakraButton>
        <ChakraButton
          {...buttonStyles}
          onClick={() => editor.chain().focus().toggleTaskList().run()}
          background={editor.isActive('taskList') ? 'background' : 'white'}
          color={editor.isActive('taskList') ? 'white' : 'background'}
        >
          <RiListCheck3 />
        </ChakraButton>

        <div className={styles.divider}></div>

        <ChakraButton {...buttonStyles} onClick={() => editor.chain().focus().undo().run()}>
          <RiArrowGoBackLine />
        </ChakraButton>
        <ChakraButton {...buttonStyles} onClick={() => editor.chain().focus().redo().run()}>
          <RiArrowGoForwardLine />
        </ChakraButton>
      </Flex>
      <IconButton
        style={{ right: 0, position: 'absolute', color: colors.folder.darkGray.body }}
        ariaLabel="Toggle text editor"
        onClick={() => onRemove()}
        variant="unstyled"
        icon={icons.close}
      />
      <Modal isOpen={isOpen} onClose={onClose} blockScrollOnMount>
        <ModalOverlay />
        <ModalContent
          overflow="visible"
          bg="white"
          color={'textBlack'}
          rounded="md"
          role="form"
          aria-labelledby="modal-header"
        >
          <ModalHeader id="modal-header" color="inherit">
            Add Link
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody bg="white">
            <label htmlFor="url-input" style={{ fontWeight: 400, color: 'inherit', marginBottom: '2px' }}>
              Link
            </label>
            <Input
              id="url-input"
              value={url}
              onChange={handleUrlChange}
              placeholder="URL"
              type="url"
              aria-label="Link"
              aria-describedby="url-help"
              bg="white"
              border="1px solid #d9d9d9"
              color="inherit"
              _hover={{}}
              rounded="lg"
            />
          </ModalBody>
          <ModalFooter bg="white" display="flex" gap={3} rounded="md">
            <Button
              variant="defaultButton"
              bg="white"
              color="inherit"
              border="1px solid #1A1A1A"
              _hover={{ bg: colors.folder.lightGray.body }}
              label="Cancel"
              onClick={handleSubmit}
            />
            <Button variant="defaultButton" label="Yes" type="submit" onClick={handleSubmit} disabled={!isValid} />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  )
}
