export default {
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    bg: 'var(--chakra-colors-textRegular)',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    bg: 'var(--chakra-colors-menuBackground)',
    borderRadius: '4px',
    '&:hover': {
      bg: 'gray.500',
    },
  },
  scrollbarWidth: 'thin',
  scrollbarColor: 'var(--chakra-colors-textRegular) var(--chakra-colors-background)',
}
