import React, { useCallback, useEffect, ReactNode, useState } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { Box, Text, Flex, Grid, Spinner } from '@chakra-ui/react'
import { useAppSlice } from 'features/app'
import BoxCard from './BoxCard'
import { Node } from 'types/graphqlSchema'

interface BoxesGridProps {
  boxes: any[]
  viewType: string
  pageType?: string
  noBorder?: boolean
  shouldBoxReorder?: boolean
  boxIndex?: number | null
  droppingIn?: string | ''
  setDroppingIn?: any
  dragEnterIndex?: number
  setDragEnterIndex?: React.Dispatch<React.SetStateAction<number>>
  draggingTip?: any
  setDraggingTip?: any
  startIndex?: any
  setStartIndex?: any
  displayBar?: ReactNode
}
const BoxesGrid = ({
  boxes,
  viewType,
  pageType,
  noBorder,
  shouldBoxReorder,
  boxIndex,
  droppingIn,
  setDroppingIn,
  dragEnterIndex,
  setDragEnterIndex,
  draggingTip,
  setDraggingTip,
  startIndex,
  setStartIndex,
  displayBar,
}: BoxesGridProps) => {
  const { dispatch, setPopupMenuBoxes, sortType } = useAppSlice()
  const [duplicatingFolder, setDuplicatingFolder] = useState<string>('')
  const [deletingFolder, setDeletingFolder] = useState<string>('')
  const [displayedBoxes, setDisplayedBoxes] = useState<Node[]>([])

  useEffect(() => {
    if (deletingFolder || !boxes || boxes.length === 0) return

    const formattedBoxes = boxes.map((item) => ({
      itemId: item.id,
      isOpen: false,
    }))

    dispatch(setPopupMenuBoxes(formattedBoxes))

    const isSortedByName = sortType.default === 'Name'
    const sortedBoxes = isSortedByName ? [...boxes].sort((a, b) => a.name.localeCompare(b.name)) : boxes

    if (!duplicatingFolder && !deletingFolder) {
      setDisplayedBoxes(sortedBoxes)
    }
  }, [boxes, duplicatingFolder, deletingFolder, sortType])

  const boxesLayout = useCallback(
    (placeholder?: any) => (
      <Grid
        gridTemplateColumns={
          viewType === 'grid'
            ? [
                'repeat(auto-fill, minmax(103px, 1fr))',
                null,
                'repeat(auto-fill, minmax(90px, 1fr))',
                'repeat(auto-fill, minmax(130px, 1fr))',
                'repeat(auto-fill, minmax(180px, 1fr))',
              ]
            : 'full'
        }
        position="relative"
        gridGap={viewType === 'grid' ? ['3rem 1rem', null, null, '3rem 1rem'] : [4]}
      >
        {displayedBoxes?.map((box, idx) => {
          const showSpinner = duplicatingFolder === box.id
          return (
            <React.Fragment key={box?.id}>
              <BoxCard
                boxInfo={box}
                alerts={0}
                index={idx}
                pageType={pageType}
                noBorder={noBorder || boxIndex === idx || droppingIn === box.id}
                shouldBoxReorder={shouldBoxReorder}
                setDroppingIn={setDroppingIn}
                dragEnterIndex={dragEnterIndex}
                setDragEnterIndex={setDragEnterIndex}
                draggingTip={draggingTip}
                setDraggingTip={setDraggingTip}
                startIndex={startIndex}
                setStartIndex={setStartIndex}
                setDuplicatingFolder={setDuplicatingFolder}
                setDeletingFolder={setDeletingFolder}
                deletingFolder={deletingFolder}
              />
              {showSpinner && (
                <Spinner
                  key={box.id}
                  placeSelf="center"
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="accent"
                  size="lg"
                />
              )}
            </React.Fragment>
          )
        })}
        {placeholder || ''}
      </Grid>
    ),
    [
      viewType,
      displayedBoxes,
      boxIndex,
      droppingIn,
      noBorder,
      pageType,
      setDroppingIn,
      shouldBoxReorder,
      dragEnterIndex,
      duplicatingFolder,
      deletingFolder,
    ],
  )
  // Variables
  const droppableContainer = (
    <Droppable droppableId="1" direction="vertical" isCombineEnabled>
      {(provided) => (
        <Box ref={provided.innerRef} {...provided.droppableProps}>
          {boxesLayout(provided.placeholder)}
        </Box>
      )}
    </Droppable>
  )
  const nonDroppableContainer = boxesLayout()
  return (
    <Flex flex={1} mt={6} mb={12} direction="column" w="full">
      <Flex dir="row" justify="space-between" align="center" mb={[4, null, null, 6]}>
        <Text fontWeight="700">Folders</Text>
        {displayBar}
      </Flex>
      {viewType === 'grid' ? nonDroppableContainer : droppableContainer}
    </Flex>
  )
}

export default BoxesGrid
