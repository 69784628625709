import { useMutation } from '@apollo/client'
import { Flex, Icon, Menu, MenuButton, MenuItem, MenuList, Portal, Text, useDisclosure } from '@chakra-ui/react'
import { AlertWithAction, Toast } from 'components'
import { AdminContext } from 'components/layouts/AdminLayout'
import { REMOVE_USER_GROUP } from 'features/graphql'
import { UserGroup } from 'pages/Admin/hooks/useAdmin'
import { useOutletContext } from 'react-router-dom'
import { icons } from 'theme'

const menuItemStyles = {
  bg: 'inherit',
  color: 'textBlack',
  fontFamily: 'Inter',
  fontWeight: 500,
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',

  _hover: { bg: 'menuBackground', color: 'textHighlight', fill: 'textHighlight' },
  _focusVisible: { bg: 'menuBackground', color: 'textHighlight' },
}

const UserGroupCard = ({
  group,
  setGroupValue,
  onOpenGroupModal,
}: {
  group: UserGroup
  setGroupValue: React.Dispatch<React.SetStateAction<UserGroup | null>>
  onOpenGroupModal: () => void
}) => {
  const { setUserGroups } = useOutletContext<AdminContext>()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [removeUserGroup] = useMutation(REMOVE_USER_GROUP, {
    onCompleted: async (data) => {
      const { removeUserGroup } = await data
      if (removeUserGroup) {
        setUserGroups((prevUserGroups) => prevUserGroups.filter((grp) => grp.id !== group.id))
      }
      Toast.show({
        icon: 'check',
        message: `${group?.name} has been deleted.`,
      })
    },
  })

  const memberCount = group?.users?.length || 0

  return (
    <>
      <Flex
        fontSize=".75rem"
        rounded={8}
        bg="cardBackground"
        px="2rem"
        py="1rem"
        border="1px solid var(--chakra-colors-borderRegular)"
        alignItems="center"
      >
        <Flex flexDir="column" w="10rem">
          <Text fontWeight={400} fontSize="1rem" color="textHighlight">
            {group.name}
          </Text>
          <Text fontWeight={400} fontSize=".875rem" color="textRegular">
            {memberCount} members
          </Text>
        </Flex>
        {group && group.users && group.users.length > 0 && (
          <Flex ml="13rem" mr="auto">
            {group.users.length <= 5 ? (
              group.users.map((user, index) => (
                <Text key={user.id} mr=".4rem" fontWeight={400} fontSize="1rem" color="textHighlight">
                  {user.firstName} {user.lastName}
                  {index < group.users.length - 1 && ','}
                </Text>
              ))
            ) : (
              <>
                {group.users.slice(0, 5).map((user, index) => (
                  <Text key={user.id} mr=".4rem" fontWeight={400} fontSize="1rem" color="textHighlight">
                    {user.firstName} {user.lastName}
                    {index < 4 && ', '}
                  </Text>
                ))}
                <Text ml="1rem" fontWeight={400} fontSize="1rem" color="textHighlight">
                  {' '}
                  + {group.users.length - 5} more
                </Text>
              </>
            )}
          </Flex>
        )}
        <Menu>
          <MenuButton
            display="flex"
            alignItems="center"
            bg="transparent"
            fill="textSoft"
            fontSize={14}
            py={'2px'}
            px={2}
            ml="auto"
            roundedLeft="lg"
            _focusVisible={{ boxShadow: '0 0 0 1px var(--chakra-colors-borderFocus)' }}
            _hover={{ fill: 'textHighlight' }}
          >
            <Icon as={icons.menuIcon} w={6} h={6} p={0} mt={2} fill="inherit" />
          </MenuButton>
          <Portal>
            <MenuList py={3} px={2} m={0} zIndex={2} bg="textHighlight" rounded={6}>
              <MenuItem
                {...menuItemStyles}
                onClick={() => {
                  setGroupValue(group)
                  onOpenGroupModal()
                }}
              >
                <Icon as={icons.rename} w={6} h={6} p={0} fill="inherit" />
                Edit
              </MenuItem>
              <MenuItem {...menuItemStyles} onClick={onOpen}>
                <Icon as={icons.deleteIcon} w={6} h={6} p={0} fill="inherit" />
                Remove
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>
      </Flex>
      <AlertWithAction
        isOpen={isOpen}
        onClose={onClose}
        actionText={'Remove ' + group.name + '?'}
        warningText={[group.name + ' will be deleted.']}
        confirmButtonText="Remove Group"
        action={() => removeUserGroup({ variables: { groupId: group.id } })}
      />
    </>
  )
}

export default UserGroupCard
