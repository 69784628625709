import React from 'react'
import { Container, Box, Skeleton } from '@chakra-ui/react'

interface SkeletonProjectCardProps {
  maxWidth?: string | [string, null]
}

const SkeletonProjectCard = ({ maxWidth }: SkeletonProjectCardProps) => {
  return (
    <Box pos="relative" width="100%" pb={['80%', null, null, '90%']} h={0}>
      <Container variant="projectCard" maxW={maxWidth || 'full'} minW={maxWidth}>
        <Box
          w="full"
          h={['74%', null, null, '67.5%']}
          bg="cardBackground"
          borderTopRadius={8}
          overflow="hidden"
          pos="relative"
        ></Box>
        <Box px={3} pt={[2, null, null, 4]} maxW="100%">
          <Skeleton w="60%" h="14px" startColor="textAsh" endColor="textRegular" mb={2} />
          <Skeleton w="80%" h="16px" startColor="textAsh" endColor="textRegular" />
        </Box>
      </Container>
    </Box>
  )
}

export default SkeletonProjectCard
