import { Flex, Text, Image, Button, Box, Spacer } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { AvatarsTooltip } from 'components'
import { useNodeMenuActions } from 'features/node/hooks'
import { images } from 'theme'
import { Node } from 'types/graphqlSchema'
import { UserOrGuest } from 'features/workflow/components/FlowModal/types/flow-form-types'

interface ESMprops {
  setShowEditPane: React.Dispatch<React.SetStateAction<boolean>>
  node: Node
  setRecipientsToList: React.Dispatch<React.SetStateAction<UserOrGuest[]>>
  recipientsToList: UserOrGuest[]
}

const EditSharedMembers = ({ node, setRecipientsToList, recipientsToList }: ESMprops) => {
  const { unshareNode } = useNodeMenuActions()
  const { id: nodeId, private: isPrivate, sharedMembers } = node

  const handleRemoveUser = async (user) => {
    const exists = sharedMembers?.find((mem) => mem?.sharedWith?.email === user.email)
    if (exists && exists.sharedWithId) {
      await unshareNode(exists.sharedWithId, nodeId)
    } else {
      setRecipientsToList((prev) => prev.filter((mem) => mem.email !== user.email))
    }
  }

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.6 }}>
      <Flex flexDir={'column'} gap={4} pr={2} h="full">
        <Text color="textBlack" pl={6}>
          {isPrivate ? 'Remove Shared Users:' : 'This is Public to your organization, only guests may be removed.'}
        </Text>
        <Flex
          h={'200px'}
          flexDir={'column'}
          overflowY={'scroll'}
          css={{
            '&::-webkit-scrollbar': {
              width: '0',
            },
            '&::-webkit-scrollbar-track': {
              width: '0',
            },
          }}
        >
          {recipientsToList?.map((mem) => (
            <Flex key={mem.email} justifyContent={'space-between'} alignItems={'center'}>
              <Box px={6} py={2}>
                <AvatarsTooltip users={[mem]} />
              </Box>
              <Text ml={'auto'} pr={6} fontWeight={300} color="textBlack">
                Click to remove <span style={{ fontWeight: 500, fontStyle: 'italic' }}>{mem.firstName}</span>
              </Text>
              <Button
                variant={'unstyled'}
                display={'grid'}
                placeContent={'center'}
                _hover={{ bg: '#E9EAEC' }}
                onClick={() => handleRemoveUser(mem)}
              >
                <Image src={images.xSmall} />
              </Button>
            </Flex>
          ))}
        </Flex>
        <Spacer />
      </Flex>
    </motion.div>
  )
}

export default EditSharedMembers
