import type { SuggestionOptions, SuggestionProps } from '@tiptap/suggestion'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import type { MentionSuggestion } from './mentionSuggestionOptions'
import './SuggestionList.scss'

export type SuggestionListProps = SuggestionProps<MentionSuggestion>
export type SuggestionListRef = {
  onKeyDown: NonNullable<ReturnType<NonNullable<SuggestionOptions<MentionSuggestion>['render']>>['onKeyDown']>
}

interface MentionNodeAttrs {
  id: string | null
  label?: string | null
}

const SuggestionList = forwardRef<SuggestionListRef, SuggestionListProps>((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const selectItem = (index: number) => {
    if (index >= props.items.length) {
      return
    }

    const suggestion = props.items[index]

    const mentionItem: MentionNodeAttrs = {
      id: suggestion.id,
      label: suggestion.mentionLabel,
    }

    props.command(mentionItem)
  }

  const upHandler = () => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length)
  }

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length)
  }

  const enterHandler = () => {
    selectItem(selectedIndex)
  }

  useEffect(() => setSelectedIndex(0), [props.items])

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        downHandler()
        return true
      }

      if (event.key === 'Enter') {
        enterHandler()
        return true
      }

      return false
    },
  }))

  return props.items.length > 0 ? (
    <div className="dropdown-menu">
      {props.items.length ? (
        props.items.map((item, index) => (
          <button
            className={index === selectedIndex ? 'is-selected' : ''}
            key={index}
            onClick={() => selectItem(index)}
          >
            {item.mentionLabel}
          </button>
        ))
      ) : (
        <div className="item">No user found</div>
      )}
    </div>
  ) : null
})

SuggestionList.displayName = 'SuggestionList'

export default SuggestionList
