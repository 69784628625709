import { IconPopover, PopoverModal } from 'components'
import BoxCardMenu from './BoxCardMenu'
import { icons } from 'theme'
import { FollowedNode, Node } from 'types/graphqlSchema'
import React from 'react'

type BoxCardMenuProps = {
  box: Node
  followedNodes: FollowedNode[]
  handleMenuAction: (action: string) => void
  pageType: string[]
  noBorder?: boolean
  isMobile?: boolean
  isLinkShare?: boolean
  menuOpen: boolean
  setMenuOpen: (open: boolean) => void
}

const BoxMenuPopover = ({
  box,
  followedNodes,
  handleMenuAction,
  pageType,
  noBorder,
  isMobile,
  isLinkShare,
  menuOpen,
  setMenuOpen,
}: BoxCardMenuProps) => {
  if (isMobile) {
    return (
      <PopoverModal menuOpen={menuOpen} setMenuOpen={setMenuOpen}>
        <BoxCardMenu
          box={box}
          follow={followedNodes?.some((f: FollowedNode) => f.nodeId === box.id)}
          handleMenuAction={handleMenuAction}
          pageType={pageType}
          noBorder={noBorder}
          isMobile={isMobile}
          isLinkShare={isLinkShare}
        />
      </PopoverModal>
    )
  }

  return (
    <IconPopover
      itemId={box.id}
      menuType="box"
      icon={icons.menuIcon}
      variantBtn="iconBtnSm"
      iconSize={{
        width: [3, null, null, 5],
        height: [3, null, null, 5],
      }}
      variantPopover="popoverMenu"
      placement="bottom-start"
    >
      <BoxCardMenu
        box={box}
        follow={followedNodes?.some((f: FollowedNode) => f.nodeId === box.id)}
        handleMenuAction={handleMenuAction}
        pageType={pageType}
        noBorder={noBorder}
        isLinkShare={isLinkShare}
      />
    </IconPopover>
  )
}

export default BoxMenuPopover
