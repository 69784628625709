import { Text, Flex, Grid } from '@chakra-ui/react'
import { FlowRecipientTypeValue } from 'features/workflow/components/FlowModal/types/flow-form-types'
import { motion } from 'framer-motion'
import { UserGroup } from 'pages/Admin/hooks/useAdmin'
interface GroupItemProps {
  group: UserGroup
  type?: FlowRecipientTypeValue
  flowEdit?: (string: FlowRecipientTypeValue) => Promise<void>
  shareEdit?: () => void
}

const GroupItem = ({ group, type, flowEdit, shareEdit }: GroupItemProps) => {
  const formatType = (type: string) => {
    if (type === 'shared') {
      return 'Pending'
    }
    if (type === 'watcher') {
      return 'In The Loop'
    }
    if (type === 'approver') {
      return 'Approvers'
    }
    if (type === 'feedbackProvider') {
      return 'Input Providers'
    }
  }

  return (
    <Flex
      align="center"
      cursor="pointer"
      onClick={() => {
        if (flowEdit && type) flowEdit(type)
        else if (shareEdit) shareEdit()
      }}
    >
      <Flex align="center" gap={2}>
        <Grid bg="accentIcon" rounded="full" h="2rem" w="2rem" placeContent="center">
          <Text fontWeight={400} color="textBlack">
            + {group.users?.length ?? 0}
          </Text>
        </Grid>
        <Flex flexDir="column">
          <Text fontSize={14} fontWeight={400} color="tipbox.dark.text.charcoalGrey">
            {group.name}
          </Text>
          <Text fontSize={12} color="tipbox.dark.text.charcoalGrey">
            {group.users?.length ?? 0} members
          </Text>
        </Flex>
      </Flex>
      <motion.div
        style={{
          flex: 1,
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginLeft: '.25rem',
        }}
        initial={{ opacity: 0 }}
        whileHover={{ opacity: 1 }}
        transition={{ duration: 0.1 }}
      >
        Edit
      </motion.div>
      <Text ml="auto" color="cardBackground">
        {formatType(type ?? 'shared')}
      </Text>
    </Flex>
  )
}

export default GroupItem
