import { useEffect, useState } from 'react'
import { Flex, Box, Heading, Center, Image, Text, useBreakpointValue } from '@chakra-ui/react'
import ReactPlayer from 'react-player/lazy'
import { IconPopover } from 'components/fragments'
import { IconButton, Button, AdobePDFEmbed } from 'components/elements'
import { icons, images } from 'theme'
import { useStorage } from 'hooks'
import { blockType } from 'helpers/constant'
import { getFileIconFromNodeContent } from 'helpers/utils'

function BlockClickContent({ user, clickedBlock, onClose }) {
  const { downloadBlockFile } = useStorage()

  const isMobile = useBreakpointValue({ xs: true, md: true, lg: false })
  const fileName = (clickedBlock?.content || '').replace(/^.*[\\/]/, '')
  const extension = fileName.split('.').pop()
  const extensionIcon = getFileIconFromNodeContent({ ...clickedBlock, extension })
  const isFile = clickedBlock?.type === blockType.file
  const isPDF = clickedBlock?.type === blockType.file && extension === 'pdf'
  const [isPlaying, setPlaying] = useState<boolean>(true)

  const handleDownload = () => {
    if (!clickedBlock) return
    downloadBlockFile({ ...clickedBlock, name: fileName })
  }

  useEffect(() => {
    return () => {
      onClose()
    }
  }, [])

  const renderBody = () => {
    // Image
    if (clickedBlock?.type === blockType.image) {
      return (
        <Center flex={1} borderRadius={6} w="full" h="full">
          <Image h="full" alt="myImage" src={clickedBlock.contentSecureUrl} objectFit="contain" />
        </Center>
      )
    }
    // video
    if (clickedBlock?.type === blockType.video) {
      return (
        <Center flex={1} borderRadius={6} w="full" h="full">
          <ReactPlayer
            url={clickedBlock.contentSecureUrl}
            controls={isPlaying}
            width="100%"
            height="100%"
            muted
            playing={isPlaying}
            onPlay={() => {
              setPlaying(true)
            }}
            onPause={() => {
              setPlaying(false)
            }}
          />
          {!isPlaying && (
            <Flex
              pos="absolute"
              left={0}
              right={0}
              bottom={0}
              top={0}
              justify="center"
              align="center"
              onClick={() => {
                setPlaying(true)
              }}
            >
              <Image src={images.playVideo} alt="play" w={isMobile ? 20 : 32} mt={20} />
            </Flex>
          )}
        </Center>
      )
    }
    // file (PDF)
    if (isPDF) {
      return (
        <Center flex={1} borderRadius={6} w="full" h="full">
          <AdobePDFEmbed
            user={user}
            resource={clickedBlock}
            previewConfig={{
              showAnnotationTools: true,
              // enableAnnotationAPIs: true, // SAVE_API won't work if this is enabled
              showDownloadPDF: true,
            }}
          />
        </Center>
      )
    }

    return (
      <Flex flexDirection="column" align="center" justify="center" w="full">
        <Image src={extensionIcon} alt="File" paddingTop={4} w={12} />
        <Text my={6} textAlign="center" maxW={[null, null, null, '55%']} fontSize={[14, null, null, 16]}>
          We are sorry, we cannot preview this file type in your web browser. To View this content, please download and
          open it on your device.
        </Text>
        <Button
          label="Download"
          leftIcon={<icons.downLoad />}
          style={{
            background: 'accent',
            padding: 5,
            margin: 0,
            width: 'auto',
          }}
          onClick={handleDownload}
        />
      </Flex>
    )
  }

  return (
    <>
      <Flex flex={1} display={clickedBlock ? 'inline' : 'none'} pos="fixed" w="full" h="full" zIndex={100}>
        <Flex direction="row" justify="space-between" align="center" w="full" h="68px" mb="16px">
          {!isMobile && <Flex flex={1} />}
          <Heading flex={1} w="full" textAlign="center" noOfLines={1}>
            {fileName}
          </Heading>
          <Flex flex={1} justify="flex-end" align="center">
            {/* <IconButton
              ariaLabel="drive"
              onClick={onDrive}
              icon={icons.addDriveIcon}
              iconSize={{ width: 18, height: 18 }}
              variant="tipMenuButton"
              iconMarginLeft={0}
            /> */}
            {!isPDF && (
              <>
                {isFile && (
                  <IconButton
                    ariaLabel="printer"
                    onClick={() => {
                      if (clickedBlock?.signedUrl) {
                        window.open(clickedBlock.signedUrl, '_blank', 'noreferrer')
                      }
                    }}
                    icon={icons.printerIcon}
                    iconSize={{ width: 18, height: 18 }}
                    variant="tipMenuButton"
                    iconMarginLeft={0}
                  />
                )}
                <IconButton
                  ariaLabel="download"
                  onClick={handleDownload}
                  icon={icons.downloadIcon}
                  iconSize={{ width: 18, height: 18 }}
                  variant="tipMenuButton"
                  iconMarginLeft={0}
                />
              </>
            )}
            <IconPopover
              icon={icons.tipMenuIcon}
              iconSize={{ width: 18, height: 18 }}
              style={{ margin: 0 }}
              variant="tipMenuButton"
            >
              <Flex flexDir="column" overflow="hidden" rounded={8}>
                {/* <Flex flexWrap="wrap" flexDir="column" borderBottom="1px" borderColor="borderRegular">
                  <Box>
                    <IconButton
                      ariaLabel="details"
                      onClick={onDetails}
                      icon={icons.info}
                      iconSize={{ width: 5, height: 5 }}
                      label="Details"
                      variant="dropDownButton"
                      iconMarginRight={2}
                      flexDir={['row']}
                    />
                  </Box>
                </Flex> */}
                <Box>
                  <IconButton
                    ariaLabel="newTab"
                    onClick={() => {
                      if (clickedBlock?.signedUrl) {
                        window.open(clickedBlock.signedUrl, '_blank', 'noreferrer')
                      }
                    }}
                    icon={icons.externalLink}
                    iconSize={{ width: 5, height: 5 }}
                    label="Open in new window"
                    variant="dropDownButton"
                    iconMarginRight={2}
                    flexDir={['row']}
                  />
                </Box>
              </Flex>
            </IconPopover>
            <IconButton
              ariaLabel="close"
              onClick={onClose}
              icon={icons.close}
              iconSize={{ width: 23, height: 23 }}
              variant="tipMenuButton"
              iconMarginLeft={6}
              iconMarginRight={isMobile ? 4 : 10}
            />
          </Flex>
        </Flex>
        <Flex h="calc(100vh - 84px)" overflowY="auto">
          {renderBody()}
        </Flex>
      </Flex>
      <Box
        flex={1}
        w="full"
        h="full"
        pos="fixed"
        bgColor="black"
        display={clickedBlock ? 'inline' : 'none'}
        opacity={0.8}
        zIndex={9}
      />
    </>
  )
}

export default BlockClickContent
