import {
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { AnimatedMenuChevron } from 'components/elements'
import { customIcons } from 'theme'

const TagsMenu = ({ styles = {}, selectedTags, setSelectedTags, tags, menuItemStyles }) => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false })
  const { isOpen: menuOpen, onOpen, onClose } = useDisclosure()

  return (
    <Menu closeOnSelect={false} isOpen={menuOpen} onOpen={onOpen} onClose={onClose}>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            variant="outline"
            px={useBreakpointValue({ base: 2, md: 4 }, { ssr: false })}
            rightIcon={!isMobile ? <AnimatedMenuChevron isOpen={isOpen} marginTop="3px" /> : undefined}
            borderColor="transparent"
            minH="43px"
            color="textRegular"
            _hover={{ color: 'textHighlight' }}
            _active={{ color: 'textHighlight' }}
            {...styles}
            fontWeight={400}
            fontSize={{ base: '.85rem', md: '15px' }}
            _focusVisible={{ boxShadow: '0 0 0 1px var(--chakra-colors-borderFocus)' }}
          >
            Tags
          </MenuButton>
          <MenuList minWidth="240px" bg="textHighlight" px="1px" py={4}>
            <MenuOptionGroup
              type="checkbox"
              value={selectedTags.map((t) => t.name)}
              onChange={(e) => {
                const matchingTags = tags.filter((tag) => e.includes(tag.name))
                setSelectedTags(matchingTags)
              }}
            >
              {tags &&
                tags.map((t) => {
                  return (
                    <MenuItemOption
                      key={t.name}
                      {...menuItemStyles}
                      value={t.name}
                      icon={null}
                      pl={6}
                      _checked={{ bg: 'menuBackground', color: 'textHighlight', fill: 'textHighlight' }}
                      _active={{ bg: 'inherit', color: 'textBlack', fill: 'textBlack' }}
                    >
                      <Flex align="center" gap={3} px={0}>
                        <Icon as={customIcons.tagSolid} w={3} h={3} />
                        {t.name}
                      </Flex>
                    </MenuItemOption>
                  )
                })}
            </MenuOptionGroup>
            <MenuDivider color="borderLight" mx={8} mb={4} />
            <MenuItem
              {...menuItemStyles}
              px={6}
              onClick={() => {
                setSelectedTags([])
                onClose()
              }}
            >
              Clear
            </MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  )
}

export default TagsMenu
