import { User } from 'types/graphqlSchema'

export interface FlowGuest {
  firstName: string
  lastName: string
  email: string
  value?: string
  isNew?: boolean
  avatar?: null
  avatarSecureUrl?: string
  organizationId?: null
  organization: {
    name: string
  }
}

export type UserOrGuest = User | FlowGuest

export const flowRecipientTypes = {
  Feedback: {
    primary: 'feedbackProvider',
    watcher: 'watcher',
  },
  Approval: {
    primary: 'approver',
    watcher: 'watcher',
  },
} as const // Using 'as const' to create a truly immutable object type

// Type for flow recipient type keys
export type FlowRecipientTypeCategory = keyof typeof flowRecipientTypes
export type FlowRecipientTypeValue =
  | (typeof flowRecipientTypes.Feedback)[keyof typeof flowRecipientTypes.Feedback]
  | (typeof flowRecipientTypes.Approval)[keyof typeof flowRecipientTypes.Approval]
