import { Flex, Box, Text, Collapse, Icon } from '@chakra-ui/react'
import { LuGripVertical } from 'react-icons/lu'
import { DraggableProvided } from 'react-beautiful-dnd'
import { formatBytes } from 'helpers/utils'
import { images } from 'theme'
import { Block, User } from 'types/graphqlSchema'
import BlockTitle from './BlockTitle'
import BlockDeleteBtn from './BlockDeleteBtn'

export const blockInfoBannerStyles = {
  w: 'full',
  bgColor: 'cardBackground',
  _hover: { bg: 'cardBackgroundAccent' },
  align: 'center',
  justify: 'space-between',
  minHeight: '65px',
  px: 4,
  borderRadius: 6,
}

type BlockInfoBannerProps = {
  block: Block
  user?: User
  name: string
  extensionIcon: string
  isReadOnly?: boolean
  isMobile?: boolean
  isHovered: boolean
  downloadBlockFile: (block: Block) => Promise<void>
  onRemoveBlock?: (blockId: string) => void
  provided?: DraggableProvided
}

export const BlockInfoBanner = ({
  block,
  user,
  name,
  extensionIcon,
  isReadOnly,
  isMobile,
  isHovered,
  downloadBlockFile,
  onRemoveBlock,
  provided,
}: BlockInfoBannerProps) => {
  return (
    <Box
      as={Collapse}
      in={isMobile ? true : isHovered}
      animateOpacity
      w={'full'}
      pos={isMobile ? 'static' : 'absolute'}
      rounded="md"
      zIndex={2}
      border="1px solid transparent"
      borderTop="none"
    >
      <Flex {...blockInfoBannerStyles}>
        <BlockTitle name={name} isMobile={isMobile} extensionIcon={extensionIcon} />
        {provided && block?.owner?.id === user?.id && (
          <Box {...provided.dragHandleProps} pos="absolute" right={'50%'} display="grid" placeContent="center">
            <Icon as={LuGripVertical} h="1.25rem" w="1.25rem" />
          </Box>
        )}
        <Flex gap="1.5rem" align="center">
          {block.size && <Text>{formatBytes(parseFloat(block.size.toString()), 2)}</Text>}

          <button onClick={() => downloadBlockFile({ ...block, name })}>
            <img src={images.download} alt="download file" />
          </button>
          {!isReadOnly && <BlockDeleteBtn block={block} onRemoveBlock={onRemoveBlock} user={user} />}
        </Flex>
      </Flex>
    </Box>
  )
}
