import React, { useEffect, useState } from 'react'
import { Container, Flex, Box, Image, Heading, Icon, useBreakpointValue, useDisclosure } from '@chakra-ui/react'
import { IconPopover, Badge, PopoverModal } from 'components'
import { NodeInfoPopover } from 'features/node/components'
import { customIcons, icons, images } from 'theme'
import { usePermissions } from 'hooks'
import { getFileIconFromNode, isVideoFile } from 'helpers/utils'
import { EntityType, FollowedNode, Node } from 'types/graphqlSchema'
import TipCardMenu from './TipCardMenu'
import { useTags } from 'features/tags'

type TipGridCardProps = {
  tip: Node
  alerts: number
  handleMenuAction: (action: string) => void
  onSingleTipClick: () => void
  followedNodes: import('types/graphqlSchema').FollowedNode[]
  pageType: string[]
  noBorder?: boolean
  menuOpen: boolean
  setMenuOpen: (menuOpen: boolean) => void
  selected?: boolean
  isLinkShare?: boolean
  setDragEnterIndex?: any
  setDraggingTip?: any
  setOpenModal?: (modalAction: string) => void
}

const TipGridCard = ({
  tip,
  followedNodes,
  alerts,
  handleMenuAction,
  onSingleTipClick,
  pageType,
  noBorder,
  menuOpen,
  setMenuOpen,
  isLinkShare,
  setDragEnterIndex,
  setDraggingTip,
  setOpenModal,
}: TipGridCardProps) => {
  const { id, name, sharedMembers, coverImage } = tip
  const extensionIcon = getFileIconFromNode(tip)
  const isVideo = isVideoFile(tip.blocks)
  const isMobile = useBreakpointValue({ xs: true, lg: false })
  const [isHovering, setHovering] = useState<boolean>(false)
  const { hasPermission } = usePermissions()
  const { fetchTags, previousTags } = useTags()

  useEffect(() => {
    if (isHovering && tip?.id) {
      fetchTags(tip.id, EntityType.Node)
    }
  }, [isHovering, tip.id])

  return (
    <aside
      key={id}
      onMouseEnter={() => {
        if (!isMobile) setHovering(true)
      }}
      onMouseLeave={() => {
        if (!isMobile) setHovering(false)
      }}
    >
      <Container
        id={id}
        variant={coverImage ? 'boxCardMediaBg' : 'boxCard'}
        backgroundImage={coverImage ?? undefined}
        onClick={onSingleTipClick}
        draggable="true"
        borderTopRadius={8}
        overflow="hidden"
        onDrag={(ev: any) => {
          setDraggingTip(ev.target.id)
        }}
        onDragEnd={() => {
          setDragEnterIndex(null)
        }}
      >
        <Flex
          justify="center"
          flexDir="column"
          align="center"
          w="full"
          h="full"
          bg={coverImage ? 'linear-gradient(transparent, rgba(0,0,0,.7))' : 'textBlack'}
          cursor={'pointer'}
        >
          <Flex flex={5} justify="center" align="center" pos="relative" w="full">
            {!coverImage && !isVideo && extensionIcon && (
              <Image
                pos="absolute"
                src={extensionIcon}
                alt="Box Logo"
                objectFit="contain"
                maxH="55%"
                mb={2}
                draggable={false}
              />
            )}
            {isVideo && <Image src={images.playVideo} alt="play" w="24%" mt={8} draggable={false} />}
          </Flex>
          {alerts > 0 && <Badge message={alerts} top={2} right={2} />}
          <Heading
            alignSelf="flex-start"
            flex={3}
            fontSize={[12, null, null, null, 14]}
            lineHeight={['16px', 1.5, null, null, null]}
            fontWeight="400"
            textAlign="left"
            maxH="3rem"
            textOverflow="hidden"
            overflow="hidden"
            wordBreak="break-word"
            noOfLines={2}
            px="8%"
            pt="2%"
            mb={1}
          >
            {name || 'Untitled'}
          </Heading>
        </Flex>
      </Container>

      {/* BOTTOM MENUS */}
      <Flex
        pos="absolute"
        bottom={isMobile ? '-2rem' : '-2.5rem'}
        align="center"
        justify="space-between"
        width="100%"
        opacity={isMobile || isHovering ? 1 : 0}
        transition="opacity 0.2s"
        p={0}
      >
        <Flex align="center" flex={1}>
          {sharedMembers && sharedMembers.length > 0 && (
            <Icon
              as={isMobile ? customIcons.addUserMobile : customIcons.userPlusIcon}
              color="textRegular"
              h={[3, null, null, null, 3.5]}
              w={[3, null, null, null, 3.5]}
              mr={1}
            />
          )}
          {followedNodes?.some((f: FollowedNode) => f.nodeId === id) && (
            <Icon as={icons.follow} color="textRegular" h={[3, null, null, null, 4]} w={[3, null, null, null, 4]} />
          )}
        </Flex>
        <Box
          onClick={(event) => event.stopPropagation()}
          display={isLinkShare && !hasPermission('download', tip, true) ? 'none' : 'inherit'}
        >
          {!isMobile && (
            <NodeInfoPopover node={tip} setOpenModal={setOpenModal} tags={previousTags}>
              <Icon
                as={icons.infoIcon}
                color="textRegular"
                h={[3, null, null, 5]}
                w={[3, null, null, 5]}
                p={0}
                _hover={{
                  color: 'white',
                }}
              />
            </NodeInfoPopover>
          )}

          {isMobile ? (
            <PopoverModal menuOpen={menuOpen} setMenuOpen={setMenuOpen}>
              <TipCardMenu
                tip={tip}
                follow={followedNodes?.some((f: FollowedNode) => f.nodeId === id)}
                handleMenuAction={handleMenuAction}
                pageType={pageType}
                noBorder={noBorder}
                isMobile={isMobile}
                isLinkShare={isLinkShare}
              />
            </PopoverModal>
          ) : (
            <IconPopover
              itemId={tip.id}
              menuType="tip"
              icon={icons.menuIcon}
              variantBtn="iconBtnSm"
              iconSize={{
                width: [4, null, null, 5],
                height: [4, null, null, 5],
              }}
              variantPopover="popoverMenu"
              placement="bottom-start"
            >
              <TipCardMenu
                tip={tip}
                follow={followedNodes?.some((f: FollowedNode) => f.nodeId === id)}
                handleMenuAction={handleMenuAction}
                pageType={pageType}
                noBorder={noBorder}
                isLinkShare={isLinkShare}
              />
            </IconPopover>
          )}
        </Box>
      </Flex>
    </aside>
  )
}

export default TipGridCard
