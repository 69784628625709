import { Outlet } from 'react-router-dom'
import { Flex } from '@chakra-ui/react'

const FileLayout = () => {
  return (
    <Flex w="100%" h="100svh">
      <Outlet />
    </Flex>
  )
}

export default FileLayout
