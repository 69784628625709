import React, { useEffect, useState } from 'react'
import { Flex, Container, Image, Box, Heading, Text, Grid, useBreakpointValue, Icon } from '@chakra-ui/react'
import { IconSection } from 'components'
import { NodeInfoPopover } from 'features/node/components'
import { formatTimeString, convertNodeColorToHex } from 'helpers/utils'
import { usePermissions } from 'hooks'
import { images, icons } from 'theme'
import { EntityType, FollowedNode } from 'types/graphqlSchema'
import { ReactComponent as Folder } from 'assets/folder_colored.svg'
import BoxMenuPopover from './BoxMenuPopover'
import { useTags } from 'features/tags'

const BoxListCard = ({
  boxInfo,
  followedNodes,
  alerts,
  selected,
  boxClick,
  handleMenuAction,
  pageType,
  noBorder,
  menuOpen,
  setMenuOpen,
  isLinkShare,
}: IBoxCardChild) => {
  const { hasPermission } = usePermissions()
  const { name, coverImageSecureUrl, updatedAt, updatedBy, createdAt, owner, children, color } = boxInfo
  const isMobile = useBreakpointValue({ xs: true, lg: false })
  const folderColor = convertNodeColorToHex(color)

  const [mouseEnterInfo, setMouseEnterInfo] = useState(false)

  const handleUpdatedBy = () => {
    return updatedBy ? ` ${updatedBy?.firstName} ${updatedBy?.lastName}` : ` ${owner.firstName} ${owner.lastName}`
  }

  const { fetchTags, previousTags } = useTags()

  useEffect(() => {
    if (mouseEnterInfo && boxInfo?.id) {
      fetchTags(boxInfo.id, EntityType.Node)
    }
  }, [mouseEnterInfo, boxInfo?.id])

  return (
    <Container
      variant="boxListCard"
      sx={selected ? { borderColor: 'textSoft' } : {}}
      onClick={!mouseEnterInfo ? boxClick : () => null}
    >
      <Grid templateColumns={['4.188rem auto 3.125rem', null, null, '4rem auto 3.125rem']} h="full">
        <Box
          w={['4.188rem', null, null, '4rem']}
          minW={['4.188rem', null, null, '4rem']}
          h="full"
          borderLeftRadius={8}
          overflow="hidden"
          pos="relative"
          borderRightRadius={8}
        >
          {coverImageSecureUrl ? (
            <Flex bg="yellow" justify="center" align="center" h="full">
              <Image
                src={coverImageSecureUrl}
                objectFit="cover"
                // fallbackSrc={images.folder} TODO: fix styling if fallback shows (rounded folder bug)
                alt="Cover Image"
                h="full"
              />
            </Flex>
          ) : (
            <Flex justify="center" align="center" h="full">
              <Folder fill={folderColor.body} />
            </Flex>
          )}
        </Box>
        <Grid
          maxW="full"
          overflowX="hidden"
          px={2}
          py={1}
          templateColumns={['4.688rem auto', null, null, '30% 20% 34% 16%', null, '33% 8% 44% 15%']}
        >
          <Flex
            align={[null, null, null, 'center']}
            maxW={['full', null, null, '100%']}
            overflowX="hidden"
            gridColumn={['1/3', null, null, '1/2']}
          >
            <Heading
              fontSize={[12, null, null, null, 14]}
              lineHeight={['14px', null, null, null, '24px']}
              fontWeight="400"
              mr={0}
              noOfLines={1}
              overflowX="hidden"
            >
              {name}
            </Heading>
          </Flex>
          <Flex
            align="center"
            justify={[null, null, null, 'flex-end']}
            pl={[0, null, null, 2]}
            maxW="full"
            w="full"
            overflowX="hidden"
          >
            {!isLinkShare && (
              <IconSection
                node={boxInfo}
                isMobile={isMobile || false}
                followedNodes={followedNodes}
                nodeNotifications={alerts}
              />
            )}
          </Flex>
          <Flex maxW="full" align="center" overflowX="hidden">
            <Text fontSize={[12, null, null, null, 14]} pl={[1, null, null, 4]} pr={[0, null, null, 4]} noOfLines={1}>
              {updatedBy ? `Modified ${formatTimeString(updatedAt)}` : `Created ${formatTimeString(createdAt)}`}
              <Box as="span" display={['none', null, null, 'inline']}>
                {` by ${handleUpdatedBy()}`}
              </Box>
            </Text>
          </Flex>
          {!isMobile && (
            <Flex align="center" justifyContent="flex-end" display={['none', null, null, 'flex']}>
              <Text noOfLines={1} alignSelf="center" lineHeight="16px" fontSize={[12, null, null, null, 14]}>
                {`${children?.length || 0} ${children?.length === 1 ? 'item' : 'items'}`}
              </Text>
              <NodeInfoPopover node={boxInfo} tags={previousTags}>
                <Icon
                  as={icons.infoIcon}
                  color="textRegular"
                  h={[3, null, null, 5]}
                  w={[3, null, null, 5]}
                  p={0}
                  _hover={{
                    color: 'white',
                  }}
                  onMouseEnter={() => setMouseEnterInfo(true)}
                  onMouseLeave={() => setMouseEnterInfo(false)}
                />
              </NodeInfoPopover>
            </Flex>
          )}
        </Grid>
        <Flex
          onClick={(event) => {
            event.stopPropagation()
          }}
          align="center"
          justify="flex-end"
          pr={4}
          display={isLinkShare && !hasPermission('download', boxInfo, true) ? 'none' : 'flex'}
        >
          <BoxMenuPopover
            box={boxInfo}
            followedNodes={followedNodes}
            handleMenuAction={handleMenuAction}
            pageType={pageType}
            noBorder={noBorder}
            isLinkShare={isLinkShare}
            isMobile={isMobile}
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
          />
        </Flex>
      </Grid>
    </Container>
  )
}

export default BoxListCard
