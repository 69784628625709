import { useState, useEffect, useMemo, useRef } from 'react'
import { Flex, Image, Text as ChakraText, useColorModeValue } from '@chakra-ui/react'
import { useMutation, ApolloError, useQuery } from '@apollo/client'
import { images } from 'theme'
import { ComfirmationModal } from 'components'
import { REMOVE_COMMENT } from 'features/message/message.schema'
import { Node, User, Flow, Message } from 'types/graphqlSchema'

import CommentForm from './CommentForm'
import CommentList from './CommentList'
import { GET_NODE_SHARED_MEMBERS } from 'features/graphql'

type CommentsProps = {
  user: User
  node?: Node
  flow?: Flow
  mobile?: boolean
  comments: Message[]
  loading: boolean
  error: ApolloError | undefined
  tabsVisible?: boolean
}

const Comments = ({
  user,
  node = undefined,
  flow = undefined,
  mobile = false,
  comments,
  loading,
  error,
  tabsVisible = false,
}: CommentsProps) => {
  if (!flow && !node) return null

  // Get Node members for mentioning

  const isNode = !!node

  const { data: nsmQueryData } = useQuery(GET_NODE_SHARED_MEMBERS, {
    skip: !isNode || !tabsVisible,
    variables: { id: node?.id, private: node?.private },
  })

  const sharedUsers = useMemo(() => {
    if (!node || !nsmQueryData?.nodeSharedMembers) {
      return []
    }
    const allSharedMembers = [...nsmQueryData.nodeSharedMembers, node.owner].filter(Boolean)
    return allSharedMembers.map((member) => ({
      id: member?.id,
      display: user.id !== member?.id ? `${member?.firstName} ${member?.lastName}` : `Me`,
    }))
  }, [node, nsmQueryData])

  // Get Flow members for mentioning

  const flowMembers = useMemo(() => {
    if (!flow || !flow.participants || !flow.requester) {
      return []
    }
    const allFlowMembers = [...flow.participants.map((m) => m), flow.requester].filter(Boolean)

    return allFlowMembers.map((member) => ({
      id: member?.id,
      display: user.id !== member?.id ? `${member?.firstName} ${member?.lastName}` : `Me`,
    }))
  }, [flow])

  // Image Panel when no comments present

  const emptyStateImage = useColorModeValue(
    <Image src={images.tipCommentEmptyState} alt="empty state" />,
    <Image src={images.tipCommentEmptyStateDark} py={2} alt="empty state" />,
  )

  // GraphQL

  const [mutateRemoveComment] = useMutation(REMOVE_COMMENT, {
    onCompleted: () => {
      setShowModal(false)
    },
  })

  const [showModal, setShowModal] = useState(false)
  const [idToDelete, setIdToDelete] = useState<string>('')

  const hasComments = comments?.length > 0
  const commentListRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (idToDelete) {
      setShowModal(true)
    }
    return () => setShowModal(false)
  }, [idToDelete])

  useEffect(() => {
    if (!comments) return
    if (!commentListRef.current) return
    commentListRef.current.scroll({ top: 0 })
  }, [comments?.length])

  const handleDeleteConfirmation = (id: string) => {
    setIdToDelete(id)
  }

  const handleDeleteComment = async () => {
    await mutateRemoveComment({ variables: { messageId: idToDelete } })
  }

  if (loading || !comments) return null

  if (error) throw new Error(error.message)

  return (
    <Flex
      flexDir="column"
      h="full"
      justifyContent="flex-start"
      mt={flow ? '2rem' : 0}
      className="no-scroll-bar"
      pb={{ base: 8, md: 20 }}
    >
      {/** Comments Form  */}
      <CommentForm
        user={user}
        comments={comments}
        error={error}
        loading={loading}
        flow={flow ?? undefined}
        node={node ?? undefined}
        sharedUsers={flow ? flowMembers : sharedUsers}
      />
      {/* Comments List */}
      <>
        {hasComments ? (
          <CommentList
            commentListRef={commentListRef}
            comments={comments}
            handleDeleteComment={handleDeleteConfirmation}
            user={user}
            sharedUsers={flow ? flowMembers : sharedUsers}
            mobile={mobile}
          />
        ) : (
          <Flex direction="column" justifyContent="center" alignItems="center">
            {emptyStateImage}
            <ChakraText as="span" textAlign="center" fontSize={16} w="26ch" color="textSoft">
              Don’t worry, your conversation is safe here! Flows started from this item have their own thread.
            </ChakraText>
          </Flex>
        )}
      </>
      {/* Comments Footer */}
      <Flex
        bg="menuBackground"
        zIndex={10}
        as="footer"
        pos="fixed"
        w="full"
        bottom="-1px"
        borderTop="1px solid var(--chakra-colors-borderRegular)"
        h="3.5rem"
        align="center"
        mt="auto"
      >
        <ChakraText color="textSoft" fontWeight={400}>
          {comments.length || '0'} Comments
        </ChakraText>
      </Flex>

      {/* DELETE COMMENT MODAL */}
      <ComfirmationModal
        name="This comment"
        header="Delete comment"
        confirmAction={() => handleDeleteComment()}
        confirmType="delete"
        isOpen={showModal}
        close={() => {
          setShowModal(false)
        }}
      />
    </Flex>
  )
}

export default Comments
