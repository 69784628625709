import { Box, Flex, Spinner } from '@chakra-ui/react'
import { pdfjs, Document, Page } from 'react-pdf'
import { isFirefox } from 'react-device-detect'
import useReactPdf from './useReactPdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

const PDFViewer = ({ url, height = '600px' }: { url: string; height?: string }) => {
  const { numPages, isFullscreen, currentPage, toggleFullscreen, containerRef, pageRefs, onDocumentLoadSuccess } =
    useReactPdf()

  return (
    <Flex
      flexDir="column"
      w="full"
      h="full"
      align="center"
      position={isFullscreen ? 'fixed' : 'relative'}
      top={isFullscreen ? 0 : undefined}
      left={isFullscreen ? 0 : undefined}
      right={isFullscreen ? 0 : undefined}
      bottom={isFullscreen ? 0 : undefined}
      zIndex={isFullscreen ? 'modal' : 'auto'}
      // pt={8}
      bg={isFullscreen ? 'textHighlight' : 'transparent'}
    >
      {isFullscreen && (
        <Box
          position={isFullscreen ? 'absolute' : 'initial'}
          alignSelf={'flex-start'}
          top={4}
          left={4}
          bg="white"
          color="textBlack"
          px={4}
          py={2}
          borderRadius="md"
          boxShadow="md"
          zIndex="1"
        >
          Page {currentPage} of {numPages}
        </Box>
      )}
      <Flex
        ref={containerRef}
        h={isFullscreen ? 'calc(100vh - 80px)' : height}
        w="full"
        justify="center"
        overflowY="auto"
        overflowX="hidden"
        css={{
          '&::-webkit-scrollbar': {
            width: isFullscreen ? '12px' : '6px',
          },
          '&::-webkit-scrollbar-track': {
            width: '1px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'var(--chakra-colors-borderRegular)',
            borderRadius: '6px',
          },
          scrollbarWidth: isFirefox ? 'thin' : 'initial',
        }}
        bg="transparent"
      >
        <Document
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={
            <Spinner placeSelf="center" thickness="4px" speed="0.65s" emptyColor="gray.200" color="accent" size="lg" />
          }
        >
          {numPages &&
            Array.from({ length: numPages }, (_, index) => (
              <div
                onClick={toggleFullscreen}
                style={{ cursor: !isFullscreen ? 'zoom-in' : 'zoom-out' }}
                key={`page_${index + 1}`}
                ref={(el) => (pageRefs.current[index + 1] = el)}
                data-page-number={index + 1}
              >
                <Page
                  pageNumber={index + 1}
                  loading={
                    <Spinner
                      placeSelf="center"
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="accent"
                      size="lg"
                    />
                  }
                  width={isFullscreen ? window.innerWidth - 500 : window.innerWidth - 800}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                />
              </div>
            ))}
        </Document>
      </Flex>
    </Flex>
  )
}

export default PDFViewer
