import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { useAppSlice, useProjectSlice, useWorkflowSlice } from 'features/redux'
import { Flex, Text, Divider } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { isEmpty, uniqBy } from 'lodash'
import { workflowConstant } from 'helpers/constant'
import { useFlowActions } from 'features/workflow/hooks'
import { useTags } from 'features/tags/hooks'
import { USERS, GET_USER_GUEST_SHARE_MEMBERS } from 'features/graphql'
import { MessageForm, Toast, UsersAndGroupsSelect, UsersSelectInput } from 'components'
import { Node, User, EntityType, Flow } from 'types/graphqlSchema'
import {
  FlowFormDueBy,
  FlowFormFooter,
  FlowFormOptions,
  FlowFormParticipantsLists,
  FlowFormProjectsPane,
  FlowFormProjectsSection,
  FlowFormRecipientType,
  FlowTagsPane,
} from './components'
import { regs } from 'helpers/vali'
import { useFlowForm } from './hooks/useFlowForm'
import { flowRecipientTypes, UserOrGuest, FlowGuest as Guest, FlowRecipientTypeValue } from './types/flow-form-types'
import { useAdmin } from 'pages/Admin/hooks'
import { UserGroup } from 'pages/Admin/hooks/useAdmin'
import FlowFormGroupLists from './components/FlowFormGroupLists'

interface props {
  flowType: string
  flowState: FlowState
  node?: Node
  flow?: Flow
  focusRef: React.MutableRefObject<HTMLInputElement | null>
  setFlowState: React.Dispatch<React.SetStateAction<FlowState>>
  handleNewFlowOnNode?: () => void
}

const FlowForm = ({ node, flow, flowState, setFlowState, flowType, focusRef, handleNewFlowOnNode }: props) => {
  const { createFlow, startFlow } = useFlowActions()
  const { availableTags, attachedTags, setAttachedTags, addTag, fetchAvailableTags, fetchTags } = useTags()

  // Redux
  const { dispatch, workflows, getWorkflows } = useWorkflowSlice()
  const { projects, currentProject, clearCurrentProject, getProjects } = useProjectSlice()
  const { user } = useAppSlice()

  // React Router
  const { pathname, state } = useLocation()
  const { projectId } = useParams()
  const isQuickstart = state?.quickstart

  // Project
  const isFirstRender = useRef(true)
  const [attachedProject, setAttachedProject] = useState<Node>()

  // Flow Participants
  const [fetchUsers] = useLazyQuery(USERS)
  const [fetchGuests] = useLazyQuery(GET_USER_GUEST_SHARE_MEMBERS)
  const [recipientsInputValue, setRecipientsInputValue] = useState('')
  const [recipientType, setRecipientType] = useState<FlowRecipientTypeValue>(flowRecipientTypes[flowType].primary || '')
  const [usersData, setUsersData] = useState<User[]>()
  const [userSelectOptions, setUserSelectOptions] = useState<UserOrGuest[]>([])

  const { userGroups, setUserGroups } = useAdmin()
  const [recipientGroups, setRecipientGroups] = useState<UserGroup[]>([])
  const [inTheLoopGroups, setInTheLoopGroups] = useState<UserGroup[]>([])
  const [groupSelectOptions, setGroupSelectOptions] = useState<UserGroup[]>([])

  const [recipients, setRecipients] = useState<UserOrGuest[]>([])
  const recipientEmails = useMemo(() => recipients.map((r) => r.email), [recipients])
  const [inTheLoopUsers, setInTheLoopUsers] = useState<UserOrGuest[]>([])
  const inTheLoopUserEmails = useMemo(() => inTheLoopUsers.map((r) => r.email), [inTheLoopUsers])

  // Visual / Submit Logic
  const {
    isMobile,
    showProjectsPane,
    setShowProjectsPane,
    showTagsPane,
    setShowTagsPane,
    showMessageForm,
    setShowMessageForm,
    setIsSubmitting,
    submitDisabled,
    setOpenCreateProject,
    openModal,
    usersSelectOpen,
    onUsersSelectOpen,
    onUsersSelectClose,
  } = useFlowForm({
    recipientGroupsLength: recipientGroups.length,
    flowRecipientEmails: recipientEmails,
    flowState: {
      due: flowState.due,
      name: flowState.name,
    },
    attachedProject,
  })

  // Add Guests
  const [newGuest, setNewGuest] = useState<Guest | null>(null)
  const [createdGuests, setCreatedGuests] = useState<Guest[]>([])

  // Effects and Memos 10

  const modalHeight = useMemo(() => {
    let baseHeight
    if (flowType === 'Approval') baseHeight = isMobile ? 500 : 500
    if (flowType === 'Feedback') baseHeight = isMobile ? 540 : 460

    let heightAddition = 0

    if (showTagsPane) return 290

    if ([...recipientGroups, ...inTheLoopGroups].length > 0 && [...recipientGroups, ...inTheLoopGroups].length <= 3)
      heightAddition += 100
    if ([...recipientGroups, ...inTheLoopGroups].length > 3 && [...recipientGroups, ...inTheLoopGroups].length <= 6)
      heightAddition += 180

    if (attachedTags.length > 0 && attachedTags.length <= 3) heightAddition += 40
    if (attachedTags.length > 3 && attachedTags.length <= 6) heightAddition += 100
    if (attachedTags.length > 6) heightAddition += 120

    return baseHeight + heightAddition
  }, [recipientEmails?.length, inTheLoopUserEmails?.length, flowType, showTagsPane, isMobile, attachedTags.length])

  //  Keep Title Focused after user selects flow type
  useEffect(() => {
    if (focusRef.current) focusRef.current.focus()
  }, [focusRef])

  // Initialize flow object and tags
  useEffect(() => {
    const entityId = node ? node.id : flow?.id
    const entityType = node ? EntityType.Node : EntityType.Flow
    const initFlowAndTags = async () => {
      if (isEmpty(workflows)) {
        await dispatch(getWorkflows())
      }
      if (!isEmpty(workflows)) {
        setFlowState((prev) => ({
          ...prev,
          workflow: workflows.find((w) => w.name === workflowConstant[flowType.toLowerCase()].name),
        }))
      }

      fetchTags(entityId, entityType)
      fetchAvailableTags()
    }
    initFlowAndTags()
  }, [workflows])

  // Initialize recipient options
  useEffect(() => {
    const initRecipientOptions = async () => {
      const [usersResult, guestUsersResult] = await Promise.all([fetchUsers(), fetchGuests()])
      const orgUsers = usersResult.data.users
      const guestUsers = guestUsersResult.data?.me?.shareEmailSuggestions || []
      let potentialFlowMembers = uniqBy([...orgUsers, ...guestUsers], 'id')
      if (flowType === workflowConstant.feedback.name) {
        potentialFlowMembers = potentialFlowMembers.filter((u) => u.email !== user.email)
      }
      const userDataWithEmailValue = potentialFlowMembers.map((user) => ({
        ...user,
        value: user.email,
        label: user.firstName + ' ' + user.lastName,
      }))
      setGroupSelectOptions(userGroups)
      setUsersData(potentialFlowMembers)
      setUserSelectOptions(userDataWithEmailValue)
    }
    initRecipientOptions()
  }, [userGroups])

  // EFFECT - Populate Projects if user quickstarts immediately on login
  useEffect(() => {
    if (projects.length > 0) return
    const loadProjects = async () => {
      const params = {
        filter: {
          type: 'multi_conditions',
          conditions: { type: 'PROJECT', state: 'ACTIVE' },
        },
      }

      await dispatch(getProjects(params))
    }
    loadProjects()
  }, [projects])

  // EFFECT - Auto Attach a newly created project
  // TODO: creating project here ejects modal
  useEffect(() => {
    // Skip the effect during the initial render
    // So we only run this if user creates project in the modal
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    const newestProject = projects.reduce((a: Node | undefined, b: Node) => {
      return a?.createdAt > b.createdAt ? a : b
    }, undefined as Node | undefined)
    setAttachedProject(newestProject)
    setShowProjectsPane(false)
  }, [projects.length])

  // EFFECT -  Attach Project Automaticially (if file is in a Project)
  useEffect(() => {
    if (pathname.startsWith('/personal')) {
      dispatch(clearCurrentProject())
    }
    if (pathname.startsWith('/project') && projectId) {
      setAttachedProject(projects?.find((proj) => proj.id === projectId))
    } else if (currentProject && !isQuickstart && currentProject.category !== 'PERSONAL') {
      setAttachedProject(currentProject)
    } else if (flow && flow.project) {
      setAttachedProject(flow.project)
    } else {
      setAttachedProject(undefined)
    }
  }, [pathname, currentProject, isQuickstart, flow])

  // EFFECT -
  useEffect(() => {
    filterRecipientOptions(recipientType)
    filterGroupOptions(recipientType)
  }, [recipientType, usersData, createdGuests])

  // Memo
  const selectedRecipients = useMemo(() => {
    return recipientType === flowRecipientTypes[flowType].watcher
      ? inTheLoopUsers && inTheLoopUsers.length > 0
        ? inTheLoopUsers
        : []
      : recipients
  }, [recipientType, inTheLoopUsers, recipients])

  const selectedGroups = useMemo(() => {
    return recipientType === flowRecipientTypes[flowType].watcher
      ? inTheLoopGroups && inTheLoopGroups.length > 0
        ? inTheLoopGroups
        : []
      : recipientGroups
  }, [recipientType, inTheLoopGroups, recipientGroups])

  // Setters 1

  const filterRecipientOptions = (currentRecipientType: FlowRecipientTypeValue) => {
    if (!usersData || usersData.length === 0) return

    const userDataWithEmailValue = usersData.map((user) => ({
      ...user,
      value: user.email,
      label: user.firstName + ' ' + user.lastName,
    }))

    const options = createdGuests.length > 0 ? [...createdGuests, ...userDataWithEmailValue] : userDataWithEmailValue

    let filteredOptions = options

    if (recipientsInputValue.length > 1) {
      filteredOptions = filteredOptions.filter((option) => {
        const fullName = `${option.firstName} ${option.lastName}`.toLowerCase()
        return (
          option.firstName.toLowerCase().startsWith(recipientsInputValue.toLowerCase()) ||
          option.lastName.toLowerCase().startsWith(recipientsInputValue.toLowerCase()) ||
          fullName.startsWith(recipientsInputValue.toLowerCase()) ||
          option.email.toLowerCase().startsWith(recipientsInputValue.toLowerCase())
        )
      })
    } else {
      filteredOptions = options
    }

    if (currentRecipientType === flowRecipientTypes[flowType].primary) {
      const filteredList =
        inTheLoopUserEmails && inTheLoopUserEmails?.length > 0
          ? filteredOptions?.filter((opt) => !inTheLoopUserEmails.includes(opt.email))
          : filteredOptions
      setUserSelectOptions(filteredList)
    }

    if (currentRecipientType === flowRecipientTypes[flowType].watcher) {
      const filteredList =
        recipientEmails && recipientEmails?.length > 0
          ? filteredOptions?.filter((opt) => !recipientEmails.includes(opt.email))
          : filteredOptions
      setUserSelectOptions(filteredList)
    }
  }

  const handleEditList = async (type: FlowRecipientTypeValue) => {
    setRecipientType(type)
    await filterRecipientOptions(type)

    // Pass the type directly to the filtering function
    filterGroupOptions(type)

    onUsersSelectOpen()
  }

  const filterGroupOptions = (currentRecipientType: FlowRecipientTypeValue) => {
    if (!userGroups || userGroups.length === 0) return

    let filteredOptions = userGroups
    if (recipientsInputValue.length > 1) {
      filteredOptions = filteredOptions.filter((option) => {
        return option.users.some((user) => {
          const fullName = `${user.firstName} ${user.lastName}`.toLowerCase()
          return (
            user.firstName.toLowerCase().startsWith(recipientsInputValue.toLowerCase()) ||
            user.lastName.toLowerCase().startsWith(recipientsInputValue.toLowerCase()) ||
            fullName.startsWith(recipientsInputValue.toLowerCase()) ||
            user.email.toLowerCase().startsWith(recipientsInputValue.toLowerCase())
          )
        })
      })
    }

    if (currentRecipientType === flowRecipientTypes[flowType].primary) {
      const filteredList =
        inTheLoopGroups && inTheLoopGroups?.length > 0
          ? filteredOptions?.filter((opt) => !inTheLoopGroups.map((grp) => grp.id).includes(opt.id))
          : filteredOptions
      setGroupSelectOptions(filteredList)
    }

    if (currentRecipientType === flowRecipientTypes[flowType].watcher) {
      const filteredList =
        recipientGroups && recipientGroups?.length > 0
          ? filteredOptions?.filter((opt) => !recipientGroups.map((grp) => grp.id).includes(opt.id))
          : filteredOptions
      setGroupSelectOptions(filteredList)
    }
  }

  // HANDLERS 7

  const handleInputChange = (value: string) => {
    setRecipientsInputValue(value)

    const isEmail = regs.email.test.test(value)
    const isMyEmail = user?.email === value

    filterGroupOptions(recipientType)
    filterRecipientOptions(recipientType)

    if (!isEmail || isMyEmail) return

    // Extract and capitalize name parts
    const capitalize = (part: string) => part.charAt(0).toUpperCase() + part.slice(1)

    const [firstName, rest] = value.split('@')
    const [lastName] = rest.split('.')

    const guest = {
      email: value,
      firstName: capitalize(firstName),
      lastName: capitalize(lastName),
      organization: { name: 'Guest' },
    }

    setNewGuest(guest)
  }

  const handleAddGuest = () => {
    if (newGuest) {
      setCreatedGuests((prev) => {
        if (prev) {
          return [newGuest, ...prev]
        } else {
          return [newGuest]
        }
      })
      filterRecipientOptions(recipientType)
      setRecipientsInputValue('')
      setNewGuest(null)
    }
  }

  const toggleSelectUser = (emailAddress: string) => {
    if (!emailAddress) return
    const user = userSelectOptions.find((u) => u.email === emailAddress)
    if (!user) return

    if (recipientType === flowRecipientTypes[flowType].primary) {
      const selected = recipients.some((sel) => sel.email === user.email)
      if (selected) {
        const updatedList = recipients.filter((r) => r.email !== user.email)
        setRecipients(updatedList)
      } else {
        setRecipients((prev) => [...prev, user])
      }
    } else {
      const selected = inTheLoopUsers.some((sel) => sel.email === user.email)
      if (selected) {
        const updatedList = inTheLoopUsers.filter((r) => r.email !== user.email)
        setInTheLoopUsers(updatedList)
      } else {
        setInTheLoopUsers((prev) => [...prev, user])
      }
    }
  }

  const toggleSelectGroup = (groupId: string) => {
    const group = userGroups.find((g) => g.id === groupId)
    if (!group) return

    const selected = selectedGroups?.some((sel) => sel.id === groupId)
    if (recipientType === flowRecipientTypes[flowType].primary) {
      if (selected) {
        setRecipientGroups((prev) => prev.filter((g) => g.id !== groupId))
      } else {
        setRecipientGroups((prev) => [...prev, group])
      }
    } else {
      if (selected) {
        setInTheLoopGroups((prev) => prev.filter((g) => g.id !== groupId))
      } else {
        setInTheLoopGroups((prev) => [...prev, group])
      }
    }
  }

  const handleMessageChange = (e) => {
    let inputValue = e.target.value
    const maxLength = 250

    if (inputValue.length > maxLength) {
      inputValue = inputValue.substring(0, maxLength)
    }

    setFlowState((state) => ({
      ...state,
      message: inputValue,
    }))
  }
  const groupEmails = recipientGroups.map((group) => group.users.map((user) => user.email)).flat()
  const inTheLoopGroupEmails = inTheLoopGroups.map((group) => group.users.map((user) => user.email)).flat()

  const participantsEmails = Array.from(new Set([...recipientEmails, ...groupEmails]))
  const watchersEmails = Array.from(new Set([...inTheLoopGroupEmails, ...inTheLoopUserEmails]))

  // Start Flow --> mutate our Flow object
  const handleStartFlow = async () => {
    let startingFlow = flow
    setIsSubmitting(true)
    try {
      if (node) {
        if (!startingFlow) {
          // create new flow from node if none exists
          startingFlow = await createFlow({
            name: flowState.name,
            projectId: attachedProject?.id || node.projectId,
            organizationId: node.organizationId,
            nodeId: node.id,
          })
        }
      }
      try {
        const { flowId } = await startFlow(flowType, {
          flowId: startingFlow?.id,
          dueDate: flowState.due,
          urgent: flowState.urgent,
          uploadNeed: flowState.uploadNeed,
          requesterMessage: flowState.message,
          projectId: attachedProject?.id || startingFlow?.projectId || node?.projectId,
          workflowId: flowState.workflow?.id,
          participantsEmails,
          watchersEmails,
          remindersFrequency: flowState.reminder.selected ? flowState.reminder.interval : 0,
        })

        if (attachedTags.length > 0) {
          if (!flowId) {
            console.error('Flow ID not found. Aborting tag addition.')
            return
          }
          attachedTags.forEach(async (tagName) => {
            const tag = availableTags.find((tag) => tag.name === tagName)
            if (tag) {
              const tagId = tag.id
              try {
                await addTag(flowId, EntityType.Flow, tagId)
              } catch (error) {
                console.error(`Error adding tag '${tagName}':`, error)
              }
            } else {
              console.warn(`Tag '${tagName}' not found in the list of available tags.`)
            }
          })
        }
        if (flowId && handleNewFlowOnNode) {
          handleNewFlowOnNode()
          Toast.show({
            icon: 'check',
            message: `${flowType === 'Feedback' ? 'General Request' : flowType} task created.`,
          })
        }
      } catch (error) {
        console.error('An error occurred while starting a flow:', error)
      }
    } catch (error) {
      console.error(error.message, error)
    }
  }

  return (
    <motion.div
      layout
      key={flowType}
      aria-label={flowType + ' Flow'}
      initial={{ height: 30, opacity: 0 }}
      animate={{
        height: modalHeight,
        opacity: 1,
      }}
      transition={{
        type: 'ease',
        duration: 0.6,
        opacity: { delay: 0.6 },
      }}
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: isMobile ? '.5rem' : '1rem',
        width: '100%',
        padding: isMobile ? '0 1rem' : undefined,
        fontSize: isMobile ? '12px' : '14px',
        overflow: 'hidden',
      }}
    >
      {!showProjectsPane && !showTagsPane && !showMessageForm && (
        <>
          <Flex flexDir="column" mx="6">
            <Flex align={{ base: 'flex-start', md: 'center' }} flexDir={{ base: 'column', md: 'row' }}>
              <Text color="textBlack" minW="max">
                * Recipients:
              </Text>
              <Flex
                align="center"
                borderBottom={!usersSelectOpen ? '1.5px solid var(--chakra-colors-borderLight)' : 'none'}
                flex={1}
                w="full"
              >
                <FlowFormRecipientType
                  recipientType={recipientType}
                  setRecipientType={setRecipientType}
                  flowType={flowType}
                />
                <Divider orientation="vertical" borderColor="borderLight" borderWidth="1px" h="21px" ml="1rem" />
                <UsersSelectInput
                  value={recipientsInputValue}
                  onChange={handleInputChange}
                  isOpen={usersSelectOpen}
                  onOpen={onUsersSelectOpen}
                  onClose={onUsersSelectClose}
                />
              </Flex>
            </Flex>

            {usersSelectOpen && (
              <UsersAndGroupsSelect
                userSelectOptions={userSelectOptions}
                selectedOptions={selectedRecipients}
                selectedGroups={selectedGroups}
                toggleSelectUser={toggleSelectUser}
                toggleSelectGroup={toggleSelectGroup}
                guestValue={newGuest}
                handleAddGuest={handleAddGuest}
                userGroups={groupSelectOptions}
                setUserGroups={setUserGroups}
                users={usersData ?? []}
              />
            )}
          </Flex>

          {!usersSelectOpen && (
            <>
              <Flex flexDir={'column'} gap={2}>
                <FlowFormGroupLists
                  flowType={flowType}
                  edit={handleEditList}
                  inTheLoopGroups={inTheLoopGroups}
                  recipientGroups={recipientGroups}
                />
                <FlowFormParticipantsLists
                  flowType={flowType}
                  edit={handleEditList}
                  watchersToList={inTheLoopUsers}
                  recipientsToList={recipients}
                />
              </Flex>
              <FlowFormProjectsSection
                isQuickstart={isQuickstart}
                attachedProject={attachedProject}
                setAttachedProject={setAttachedProject}
                setShowProjectsPane={setShowProjectsPane}
              />
              <FlowFormDueBy flowState={flowState} setFlowState={setFlowState} />
              <FlowFormOptions
                attachedTags={attachedTags}
                flowState={flowState}
                flowType={flowType}
                setAttachedTags={setAttachedTags}
                setFlowState={setFlowState}
                setShowTagsPane={setShowTagsPane}
                handleMessageChange={handleMessageChange}
              />
            </>
          )}
          <FlowFormFooter submitDisabled={submitDisabled} flowType={flowType} handleStartFlow={handleStartFlow} />
        </>
      )}
      {showProjectsPane && (
        <FlowFormProjectsPane
          openModal={openModal}
          projects={projects}
          setAttachedProject={setAttachedProject}
          setOpenCreateProject={setOpenCreateProject}
          setShowProjectsPane={setShowProjectsPane}
        />
      )}
      {showTagsPane && (
        <FlowTagsPane
          tags={availableTags}
          attachedTags={attachedTags}
          setAttachedTags={setAttachedTags}
          setShowTagsPane={setShowTagsPane}
        />
      )}
      {showMessageForm && (
        <MessageForm value={flowState?.message} onChange={handleMessageChange} setShow={setShowMessageForm} />
      )}
    </motion.div>
  )
}

export default FlowForm
