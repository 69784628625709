import { gql } from '@apollo/client'
import { blockOutput } from 'features/block/block.schema'
import { flow } from '../workflow/workflow.schema'

const nodeUser = `
  id
  firstName
  lastName
  email
  avatar
  avatarSecureUrl
  organizationId
  organization {
    id
    name
  }
`

const shareMember = `
  id
  sharedBy {
    id
    firstName
    lastName
    email
    avatar
  }
  sharedWith {
    id
    firstName
    lastName
    email
    avatar
    avatarSecureUrl
    organizationId
  }
  sharedWithId
  permission {
    id
    name
    permissions {
      permissionName
      entityType
      allowed
      scope
    }
  }
  permissionId
  isPublic
  entityType
  entityId
`

const linkShare = `
  randomPath
  linkType
  expiryDate
  hasPassword
  permissionType
`

const children = `
  id
  __typename
  name
  type
  state
  private
  color
  coverImage
  coverImageSecureUrl
  totalSize
  createdAt
  updatedAt
  updatedBy {
    ${nodeUser}
  }
  ownerId
  owner {
    ${nodeUser}
  }
  blocks {
    id
    nodeId
    name
    contentSecureUrl
    extension
    preview
    previewSecureUrl
    size
    owner {
      id
    }
    createdAt
    updatedAt
  }
  childrenIds
  children {
    id
    name
    type
  }
  projectId
  organizationId
  organization {
    id
    name
  }
  parent {
    id
    name
    type
    private
  }
  breadcrumbs {
    id
    name
    type
  }
  sharedMembers {
    ${shareMember}
  }
`

const nodeOutput = `
  id
  __typename
  name
  type
  category
  private
  state
  color
  coverImage
  coverImageSecureUrl
  totalSize
  createdAt
  updatedAt
  updatedBy {
    ${nodeUser}
  }
  ownerId
  owner {
    ${nodeUser}
  }
  projectId
  project {
    id
    name
    category
  }
  parent {
    id
    name
    type
    private
  }
  organizationId
  organization {
    id
    name
  }
  children {
    ${children}
  }
  sharedMembers {
    ${shareMember}
  }
  flows {
    ${flow}
  }
  blocks {
    ${blockOutput}
  }
  breadcrumbs {
    id
    name
    type
  }
  tags {
    name
    id
  }
`
// followUserIds
// linkShare {
//    ${linkShare}
// }

const nodeLightOutput = `
  id
  name
  type
  state
  color
  coverImage
  coverImageSecureUrl
  createdAt
  updatedAt
  totalSize
  projectId
  project {
    id
    name
    category
  }
  organizationId
  organization {
    id
    name
  }
  parent {
    id
    name
    type
    private
  }
  ownerId
  owner {
    ${nodeUser}
  }
  updatedBy {
    ${nodeUser}
  }
  sharedMembers {
    ${shareMember}
  }
  blocks {
    extension
    preview
    previewSecureUrl
  }
  breadcrumbs {
    id
    name
    type
  }
`
// followUserIds
// linkShare {
//    ${linkShare}
// }

// ---------------------------
// QUERIES
// ---------------------------

export const GET_NODE = gql`
  query node($id: ID!) {
    node(id: $id) {
      ${nodeOutput}
    }
  }
`

export const GET_NODE_SHARED_MEMBERS = gql`
  query NodeSharedMembers($id: ID!) {
    nodeSharedMembers(id: $id) {
      lastName
      firstName
      id
      avatar
      avatarSecureUrl
      organizationId
    }
  }
`

export const GET_PROJECTS = gql`
  query myActiveProjects {
    myActiveProjects {
      ${nodeOutput}
    }
  }
`

export const GET_NODES = gql`
  query nodes(
    $filter: FilterObj
    $skip: Int
    $limit: Int
    $sortBy: String
  ) {
    nodes(filter: $filter, skip: $skip, limit: $limit, sortBy: $sortBy) {
      ${nodeOutput}
    }
  }
`

export const MY_NODE_IDS = gql`
  query MyNodes {
    myNodes {
      id
    }
  }
`

export const GET_SEARCH_NODES = gql`
 query getNodes(
    $filter: FilterObj
    $skip: Int
    $sortBy: String
  ) {
    nodes(filter: $filter, skip: $skip, sortBy: $sortBy) {
      ${nodeLightOutput}
    }
  }
`

export const GET_RECENT_NODES = gql`
  query recentNodes($nodeType: String, $count: Int) {
    recentNodes(nodeType: $nodeType, count: $count) {
      id
      name
      type
      category
      state
      color
      coverImage
      coverImageSecureUrl
      createdAt
      updatedAt
      totalSize
      project {
        name
      }
      updatedBy {
        ${nodeUser}
      }
      owner {
        ${nodeUser}
      }
      sharedMembers {
        ${shareMember}
      }
      blocks {
        extension
        preview
        previewSecureUrl
      }
    }
  }
`

export const GET_TRASH_NODES = gql`
   query trashNodes($count: Int) {
    trashNodes(count: $count) {
      id
      name
      type
      category
      state
      color
      coverImage
      coverImageSecureUrl
      totalSize
      createdAt
      updatedAt
      updatedBy {
        ${nodeUser}
      }
      ownerId
      owner {
        ${nodeUser}
      }
      projectId
      project {
        id
        name
      }
      parent {
        id
        name
        type
        private
        state
        color
      }
      sharedMembers {
        ${shareMember}
      }
      blocks {
        extension
        preview
        previewSecureUrl
      }
    }
  }
`

// ---------------------------
// MUTATIONS
// ---------------------------

export const CREATE_NODE = gql`
  mutation createNode($input: NodeCreateInput) {
    createNode(input: $input) {
      ${nodeOutput}
    }
  }
`

export const CREATE_NODE_FROM_FLOW = gql`
  mutation CreateNodeFromFlow($flowId: ID!) {
    createNodeFromFlow(flowId: $flowId) {
      error
      node {
        id
        name
        absolutePaths
        blocks {
          nodeId
          flowId
          id
          content
          size
        }
        organizationId
        ownerId
        projectId
        state
        type
        totalSize
        updatedAt
        createdAt
      }
    }
  }
`

export const UPDATE_NODE = gql`
  mutation UpdateNode($input: NodeUpdateInput) {
    updateNode(input: $input) {
      ${nodeOutput}
    }
  }
`

export const DELETE_NODE = gql`
  mutation deleteNode($id: ID!) {
    deleteNode(id: $id)
  }
`

export const RESTORE_NODE = gql`
  mutation restoreNode($id: ID!) {
    restoreNode(id: $id)
  }
`

export const PERMANENTLY_DELETE_NODE = gql`
  mutation permanentlyDeleteNode($id: ID!) {
    permanentlyDeleteNode(id: $id)
  }
`

export const RESTORE_ALL_TRASH_NODES = gql`
  mutation restoreAllNodes {
    restoreAllNodes
  }
`

export const PERMANENTLY_DELETE_NODES = gql`
  mutation permanentlyDeleteNodes($nodeIds: [ID]) {
    permanentlyDeleteNodes(nodeIds: $nodeIds)
  }
`

export const REMOVE_COVER_IMAGE = gql`
  mutation deleteCoverImage($id: ID!) {
    deleteCoverImage(id: $id) {
      ${nodeOutput} 
    }
  }
`

export const CREATE_NODE_SHARE_MEMBER = gql`
  mutation createNodeShare($input: ShareNodeInput!) {
    shareNode(input: $input) {
      id
      type
      sharedMembers {
        ${shareMember}
      }
    }
  }
`

export const UPDATE_NODE_SHARE_MEMBER = gql`
  mutation updateNodeShare($input: UpdateShareMemberInput!) {
    updateNodeShare(input: $input) {
      id
      type
      sharedMembers {
        ${shareMember}
      }
    }
  }
`

export const UNSHARE_NODE_SHARE_MEMBER = gql`
  mutation unshareNode($input: UpdateShareNodeInput!) {
    unshareNode(input: $input) {
      id
      type
      sharedMembers {
        ${shareMember}
      }
    }
  }
`

export const LEAVE_SHARED_NODE = gql`
  mutation leaveSharedNode($nodeId: ID!) {
    leaveSharedNode(nodeId: $nodeId) {
      id
      type
      sharedMembers {
        ${shareMember}
      }
    }
  }
`

export const REMOVE_USER_FROM_SHARED_NODES = gql`
  mutation unshareAllMyNodesByUser($id: ID!) {
    unshareAllMyNodesByUser(id: $id) {
      nodes {
        id
        type
        sharedMembers {
          ${shareMember}
        }
      }
    }
  }
`

export const USER_NODE_GUESTS = gql`
  query sharedNodes {
    sharedNodes {
      sharedMembers {
        user {
          id
          firstName
          lastName
          email
          avatar
        }
        projects {
          name
          id
        }
        boxes {
          name
          project
          projectId
          id
        }
        tips {
          name
          id
          project
          projectId
          parent
          parentId
        }
      }
    }
  }
`

export const UPLOAD_NODE_FOLDERS = gql`
mutation uploadNodes($files: [UploadNodeInput], $parentId: ID!) {
  uploadNodes(files: $files,  parentId: $parentId) {
      node {
        ${nodeOutput}
      }
      alias
      boxIds
      uploadedTipInfos {
        tipId
        path
        s3Key
        name
      }
    }
  }
`

export const INVALIDATE_COVER_IMAGE = gql`
  mutation invalidateCoverImage($filePath: String!) {
    invalidateCoverImage(filePath: $filePath)
  }
`

export const DUPLICATE_NODE = gql`
  mutation duplicateNode($id: ID!) {
    duplicateNode(id: $id) {
      ${nodeOutput}
    }
  }
`
