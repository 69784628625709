import React from 'react'
import { Flex, Text, Checkbox, Tooltip, Icon, useDisclosure } from '@chakra-ui/react'
import { colors, customIcons, icons } from 'theme'
import { UserGroup } from 'types/graphqlSchema'
import { truncateString } from 'helpers/utils'
import { AlertWithAction } from '../Alerts'
import { useMutation } from '@apollo/client'
import { REMOVE_USER_GROUP } from 'features/graphql'
import { Toast } from 'components'

const GroupSelectable = ({
  group,
  selected,
  setUserGroups,
  editFunction,
  toggleSelectGroup,
}: {
  group: UserGroup
  selected: boolean
  setUserGroups: React.Dispatch<React.SetStateAction<UserGroup[]>>
  editFunction: (group: UserGroup) => void
  toggleSelectGroup: (groupId: string) => void
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [removeUserGroup] = useMutation(REMOVE_USER_GROUP, {
    onCompleted: async (data) => {
      const { removeUserGroup } = await data
      if (removeUserGroup) {
        setUserGroups((prevUserGroups) => prevUserGroups.filter((grp) => grp.id !== group.id))
      }
      Toast.show({
        icon: 'check',
        message: `${group?.name} has been deleted.`,
      })
    },
  })

  return (
    <>
      <Flex
        px={6}
        py={4}
        rounded={6}
        w="full"
        bg="inherit"
        _hover={{ bg: colors.tipbox.dark.border.lightGrey }}
        cursor="pointer"
      >
        <Text color="textBlack" fontSize="sm" w="14ch">
          {truncateString(group.name, 20)}
        </Text>
        <Flex mx="auto" gap={4}>
          <Tooltip
            hasArrow
            fontSize={'13px'}
            rounded="lg"
            px=".75rem"
            py=".75rem"
            placement="top"
            bg="textBlack"
            label={
              <Flex flexDir={'column'}>
                {group.users?.map((member) => (
                  <Text
                    key={member?.email}
                    p={0}
                    m={0}
                    color={'textHighlight'}
                  >{`${member?.firstName} ${member?.lastName}`}</Text>
                ))}
              </Flex>
            }
          >
            <Text color="textRegular" fontSize=".75rem" fontWeight={400} mr={2}>
              {group.users?.length} members
            </Text>
          </Tooltip>
          <Icon
            as={customIcons.menuRename}
            onClick={(e) => {
              e.stopPropagation()
              editFunction(group)
            }}
          />
          <Icon
            as={icons.close}
            onClick={(e) => {
              e.stopPropagation()
              onOpen()
            }}
          />
        </Flex>
        <Checkbox ml="auto" variant="tipbox" isChecked={selected} pointerEvents={'none'} />
      </Flex>
      <AlertWithAction
        isOpen={isOpen}
        onClose={onClose}
        actionText={'Remove ' + group.name + '?'}
        warningText={[group.name + ' will be deleted.']}
        confirmButtonText="Remove Group"
        action={async () => {
          onClose()
          const res = await removeUserGroup({ variables: { groupId: group.id } })
          if (res && res?.data && res?.data?.removeUserGroup) {
            toggleSelectGroup(group.id)
          }
        }}
      />
    </>
  )
}

export default GroupSelectable
