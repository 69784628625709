import { useState, useEffect, useMemo } from 'react'
import { Box, Divider, Flex, Text, useBreakpointValue } from '@chakra-ui/react'
import { ApolloError, useQuery } from '@apollo/client'
import { uniqBy } from 'lodash'
import { USERS, GET_USER_GUEST_SHARE_MEMBERS } from 'features/graphql'
import { useAppSlice } from 'features/redux'
import { AvatarsTooltip, MessageInput } from 'components/elements'
import { User, Node } from 'types/graphqlSchema'
import EditSharedMembers from './EditSharedMembers'
import useNodeDetails from 'features/node/hooks/useNodeDetails'
import { GroupItem, UsersAndGroupsSelect, UsersSelectInput } from 'components'
import { useAdmin } from 'pages/Admin/hooks'
import { UserGroup } from 'pages/Admin/hooks/useAdmin'
import { regs } from 'helpers/vali'
import { FlowGuest, UserOrGuest } from 'features/workflow/components/FlowModal/types/flow-form-types'

interface ShareModalShareTabProps {
  shareState: {
    sentState: string
    sharerMessage: string
    sent: boolean
  }
  setShareState: React.Dispatch<React.SetStateAction<ShareModalShareTabProps['shareState']>>
  node: Node
  containerHeight: number | string
  setContainerHeight: React.Dispatch<React.SetStateAction<number | string>>
  recipients: UserOrGuest[]
  setRecipients: React.Dispatch<React.SetStateAction<UserOrGuest[]>>
  textAreaExpanded: boolean
  setTextAreaExpanded: React.Dispatch<React.SetStateAction<boolean>>
  showEditPane: boolean
  setShowEditPane: React.Dispatch<React.SetStateAction<boolean>>
  usersSelectOpen: boolean
  onUsersSelectOpen: () => void
  onUsersSelectClose: () => void
  recipientGroups: UserGroup[]
  setRecipientGroups: React.Dispatch<React.SetStateAction<UserGroup[]>>
}

const ShareModalShareTab = ({
  shareState,
  setShareState,
  node,
  setContainerHeight,
  recipients,
  setRecipients,
  recipientGroups,
  setRecipientGroups,
  setTextAreaExpanded,
  showEditPane,
  setShowEditPane,
  onUsersSelectClose,
  onUsersSelectOpen,
  usersSelectOpen,
}: ShareModalShareTabProps) => {
  const { userGroups, setUserGroups } = useAdmin()

  const [recipientsInputValue, setRecipientsInputValue] = useState('')
  const [usersData, setUsersData] = useState<User[]>()
  const [userSelectOptions, setUserSelectOptions] = useState<UserOrGuest[]>([])

  // Add Guests
  const [newGuest, setNewGuest] = useState<FlowGuest | null>(null)
  const [createdGuests, setCreatedGuests] = useState<FlowGuest[]>([])
  const options = createdGuests.length > 0 ? [...createdGuests, ...userSelectOptions] : userSelectOptions

  const handleInputChange = (value: string) => {
    setRecipientsInputValue(value)

    const isEmail = regs.email.test.test(value)
    const isMyEmail = user?.email === value

    if (!isEmail || isMyEmail) return

    const capitalize = (part: string) => part.charAt(0).toUpperCase() + part.slice(1)

    const [firstName, rest] = value.split('@')
    const [lastName] = rest.split('.')

    const guest = {
      email: value,
      firstName: capitalize(firstName),
      lastName: capitalize(lastName),
      organization: { name: 'Guest' },
    }

    setNewGuest(guest)
  }

  const handleAddGuest = () => {
    if (newGuest) {
      setCreatedGuests((prev) => {
        if (prev) {
          return [newGuest, ...prev]
        } else {
          return [newGuest]
        }
      })
      setRecipientsInputValue('')
      setNewGuest(null)
    }
  }

  const toggleSelectUser = (emailAddress: string) => {
    if (!emailAddress) return
    const user = options.find((u) => u.email === emailAddress)
    if (!user) return

    const selected = recipients.some((sel) => sel.email === user.email)
    if (selected) {
      const updatedList = recipients.filter((r) => r.email !== user.email)
      setRecipients(updatedList)
    } else {
      setRecipients((prev) => [...prev, user])
    }
  }

  const toggleSelectGroup = (groupId: string) => {
    const group = userGroups.find((g) => g.id === groupId)
    if (!group) return

    const selected = recipientGroups?.some((sel) => sel.id === groupId)
    if (selected) {
      setRecipientGroups((prev) => prev.filter((g) => g.id !== groupId))
    } else {
      setRecipientGroups((prev) => [...prev, group])
    }
  }

  // Chakra and Visual
  const isMobile = useBreakpointValue({ base: true, md: false })
  const { user } = useAppSlice()

  // Members and Suggestion Options
  const isPublic = !node.private
  const { data: usersQueryData } = useQuery(USERS, {
    onError: (error: ApolloError) => {
      console.error('Error fetching users:', error)
    },
  })

  const { loading: guestUsersLoading, data: guestUsersQueryData } = useQuery(GET_USER_GUEST_SHARE_MEMBERS, {
    onError: (error: ApolloError) => {
      console.error('Error fetching guest users:', error)
    },
  })

  const { members } = useNodeDetails(node)

  const publicOrgMembers = useMemo(
    () => (isPublic ? members.filter((mem) => mem.organizationId === node.organizationId) : []),
    [isPublic, members, node.organizationId],
  )

  // Manage height of the modal
  useEffect(() => {
    if (usersSelectOpen) {
      setContainerHeight(328)
      return
    }
    if (!usersSelectOpen && recipients.length === 0 && recipientGroups.length === 0) {
      setContainerHeight(258)
      return
    }
    if (!usersSelectOpen && recipients.length === 0 && recipientGroups.length > 0) {
      setContainerHeight(348)
      return
    }
    if (!usersSelectOpen && recipients.length > 0 && recipientGroups.length > 0) {
      setContainerHeight(380)
      return
    }
    setShowEditPane(false)
  }, [recipients?.length, recipientGroups?.length, usersSelectOpen])

  // Populate Recipients To List
  useEffect(() => {
    if (node && node?.sharedMembers) {
      const sharedMembersToList: User[] = node.sharedMembers
        .map((sm) => sm?.sharedWith)
        .filter((mem): mem is User => Boolean(mem))
        .filter((member) => !publicOrgMembers.some((orgMember) => orgMember.id === member.id))
      setRecipients(sharedMembersToList)
    }
  }, [node, node?.sharedMembers, publicOrgMembers])

  // Populate Users Select Options (userSelectOptions)

  useEffect(() => {
    if (guestUsersLoading || !usersQueryData || !guestUsersQueryData) return

    const guestUsers: User[] = guestUsersQueryData.me?.shareEmailSuggestions || []
    const orgUsers: User[] = usersQueryData.users
    const users = uniqBy([...orgUsers, ...guestUsers], 'id').filter(
      (user) => !publicOrgMembers.some((member) => member.id === user.id),
    )
    setUsersData(users)
    const userDataWithEmailValue = users.map((user) => ({
      ...user,
      value: user.email,
      label: user.firstName + ' ' + user.lastName,
    }))
    setUserSelectOptions(userDataWithEmailValue.filter((u) => u.id !== user.id))
  }, [guestUsersLoading, usersQueryData, guestUsersQueryData])

  useEffect(() => {
    if (shareState.sentState.length > 0) {
      const timer = setTimeout(() => {
        setShareState((prev) => ({ ...prev, sentState: '' }))
      }, 4000)
      return () => clearTimeout(timer)
    }
  }, [shareState.sentState])

  return (
    <div
      key="shareModal"
      aria-label="Share"
      style={{
        borderRadius: '6px',
        display: 'flex',
        flexDirection: 'column',
        gap: isMobile ? '.5rem' : '1.5rem',
        width: '100%',
        fontSize: isMobile ? '12px' : '14px',
        overflow: 'hidden',
      }}
    >
      {showEditPane && (
        <EditSharedMembers
          node={node}
          setRecipientsToList={setRecipients}
          recipientsToList={recipients}
          setShowEditPane={setShowEditPane}
        />
      )}
      {!showEditPane && (
        <>
          {/* SELECT RECIPIENTS */}
          <Flex flexDir="column" mx="6">
            <Flex align={{ base: 'flex-start', md: 'center' }} flexDir={{ base: 'column', md: 'row' }}>
              <Text color="textBlack" minW="max">
                * Recipients:
              </Text>
              <Flex
                align="center"
                borderBottom={!usersSelectOpen ? '1.5px solid var(--chakra-colors-borderLight)' : 'none'}
                flex={1}
                w="full"
              >
                <Divider orientation="vertical" borderColor="borderLight" borderWidth="1px" h="21px" ml="1rem" />
                <UsersSelectInput
                  value={recipientsInputValue}
                  onChange={handleInputChange}
                  isOpen={usersSelectOpen}
                  onOpen={onUsersSelectOpen}
                  onClose={onUsersSelectClose}
                />
              </Flex>
            </Flex>

            {usersSelectOpen && (
              <UsersAndGroupsSelect
                userSelectOptions={options}
                selectedOptions={recipients}
                selectedGroups={recipientGroups}
                toggleSelectUser={toggleSelectUser}
                toggleSelectGroup={toggleSelectGroup}
                guestValue={newGuest}
                handleAddGuest={handleAddGuest}
                userGroups={userGroups}
                setUserGroups={setUserGroups}
                users={usersData ?? []}
              />
            )}
          </Flex>
          {!usersSelectOpen && (
            <>
              <Flex flexDir="column" ml={6}>
                <Text color="textBlack">Optional</Text>
                {/* Message */}
                <MessageInput
                  value={shareState.sharerMessage}
                  onChange={(value) =>
                    setShareState((state) => ({
                      ...state,
                      sharerMessage: value,
                    }))
                  }
                  setTextAreaExpanded={setTextAreaExpanded}
                />
              </Flex>
              <Flex
                flexDir={'column'}
                gap={2}
                overflowY={'scroll'}
                css={{
                  '&::-webkit-scrollbar': {
                    width: '0',
                  },
                  '&::-webkit-scrollbar-track': {
                    width: '0',
                  },
                }}
              >
                <Box pb={2}>{node?.owner && <AvatarsTooltip users={[node.owner]} caption={'Owner'} />}</Box>
                {recipientGroups.map((group) => (
                  <GroupItem shareEdit={onUsersSelectOpen} key={`recipient-${group.name}`} group={group} />
                ))}
                {recipients.length > 0 && (
                  <AvatarsTooltip
                    users={recipients}
                    caption={'Shared'}
                    clickAction={() => {
                      setShowEditPane((prev) => !prev)
                    }}
                  />
                )}
              </Flex>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default ShareModalShareTab
