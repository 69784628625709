import {
  HStack,
  Flex,
  FlexProps,
  Icon,
  Text,
  Avatar,
  AvatarGroup,
  Tooltip,
  useColorModeValue,
  Stack,
  useBreakpointValue,
} from '@chakra-ui/react'
import { Flow } from 'types/graphqlSchema'
import { getResizedImage } from 'helpers/storage'
import { icons } from 'theme'
import './flowAssigned.css'

type FlowAssignedProps = FlexProps & {
  flow: Flow
}

function FlowTooltip(props: { children: React.ReactNode; label: React.ReactNode }) {
  return (
    <Tooltip
      label={props.label}
      placement="top"
      hasArrow
      gutter={10}
      p="16px"
      borderRadius=".375rem"
      bg={useColorModeValue('textRegular', 'black')}
      color="gray.300"
      fontFamily="Inter"
      fontSize={[11, null, null, 13]}
      lineHeight="1"
    >
      {props.children}
    </Tooltip>
  )
}

function FlowAssigned({ flow }: FlowAssignedProps) {
  const requesterName = `${flow.requester?.firstName} ${flow.requester?.lastName}`
  const requesterAvatarSrc = flow.requester?.avatarSecureUrl
    ? getResizedImage(flow.requester?.avatarSecureUrl, 'profile.main')
    : ''
  const participants = Array.isArray(flow.participants)
    ? flow.participants.map((participant) => {
        return {
          id: participant?.id,
          name: `${participant?.firstName} ${participant?.lastName}`,
          avatarSrc: participant?.avatarSecureUrl ? getResizedImage(participant?.avatarSecureUrl, 'profile.main') : '',
        }
      })
    : []

  const xlScreen = useBreakpointValue({ base: false, xl: true, '2xl': false })
  return (
    <Flex id="flowAssigned" align="center" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
      <HStack>
        {!xlScreen && <Avatar name={requesterName} size="xs" src={requesterAvatarSrc} />}
        <Text
          color="textSoft"
          fontSize="11px"
          fontFamily="Inter"
          fontWeight="normal"
          w="min-content"
          maxW="60px"
          maxH="32px"
          whiteSpace="pre-line"
          lineHeight="12px"
        >
          {requesterName}
        </Text>
        <Icon as={icons.FaChevronRight} color="textSoft" w="6px" h="6px" my="4px" />
        {participants.length === 1 ? (
          <>
            <AvatarGroup size="xs" max={2} spacing={-1}>
              {participants.map(({ id, name, avatarSrc }) => (
                <Avatar key={id} name={name} src={avatarSrc} />
              ))}
            </AvatarGroup>
            <Text
              color="textSoft"
              fontSize="11px"
              fontFamily="Inter"
              fontWeight="normal"
              w="min-content"
              maxW={xlScreen ? '60px' : '90px'}
              maxH="25px"
              whiteSpace="pre-line"
              lineHeight="12px"
            >
              {participants[0].name}
            </Text>
          </>
        ) : (
          <FlowTooltip
            label={
              <Stack p={0} m={0}>
                {participants.map(({ id, name }) => (
                  <Text key={id} p={0} m={0}>
                    {name}
                  </Text>
                ))}
              </Stack>
            }
          >
            <AvatarGroup size="xs" max={2} spacing={-1}>
              {participants.map(({ id, name, avatarSrc }) => (
                <Avatar key={id} name={name} src={avatarSrc} />
              ))}
            </AvatarGroup>
          </FlowTooltip>
        )}
      </HStack>
    </Flex>
  )
}

export default FlowAssigned
