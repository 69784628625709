import { Flex } from '@chakra-ui/react'
import { IconButton } from 'components'
import { icons } from 'theme'

const BlockDeleteBtn = ({ block, onRemoveBlock, user }) => {
  return (
    <Flex align="center" justify={'flex-end'}>
      <IconButton
        isDisable={user.id !== block.owner?.id}
        variant="iconBtn"
        ariaLabel="Remove"
        icon={icons.close}
        onClick={() => onRemoveBlock(block.id)}
      />
    </Flex>
  )
}
export default BlockDeleteBtn
