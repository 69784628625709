import { useEffect, useState } from 'react'
import { Container, Flex, Box, Image, Text, Grid, Icon, Heading, useBreakpointValue } from '@chakra-ui/react'
import { usePermissions } from 'hooks'
import { formatTimeString, formatBytes, getFileIconFromNode, isVideoFile } from 'helpers/utils'
import { IconPopover, Badge, PopoverModal } from 'components'
import { NodeInfoPopover } from 'features/node/components'
import { EntityType, FollowedNode, Node } from 'types/graphqlSchema'
import { customIcons, icons, images } from 'theme'
import TipCardMenu from './TipCardMenu'
import { useTags } from 'features/tags'

export type TipListCardProps = {
  tip: Node
  alerts: number
  handleMenuAction: (action: string) => void
  onSingleTipClick: () => void
  followedNodes: import('types/graphqlSchema').FollowedNode[]
  pageType: string[]
  noBorder?: boolean
  menuOpen: boolean
  setMenuOpen: (menuOpen: boolean) => void
  selected?: boolean
  isLinkShare?: boolean
  setOpenModal: (modalAction: string) => void
}

const TipListCard = ({
  tip,
  followedNodes,
  alerts,
  handleMenuAction,
  onSingleTipClick,
  pageType,
  noBorder,
  menuOpen,
  setMenuOpen,
  selected,
  isLinkShare,
  setOpenModal,
}: TipListCardProps) => {
  const { id, name, owner, createdAt, updatedAt, updatedBy, totalSize, sharedMembers, coverImage } = tip
  const isMobile = useBreakpointValue({ xs: true, xl: false })
  const extensionIcon = getFileIconFromNode(tip)
  const isVideo = isVideoFile(tip.blocks)
  const { hasPermission } = usePermissions()

  const [mouseEnterInfo, setMouseEnterInfo] = useState(false)

  const { fetchTags, previousTags } = useTags()

  useEffect(() => {
    if (mouseEnterInfo && tip?.id) {
      fetchTags(tip.id, EntityType.Node)
    }
  }, [mouseEnterInfo, tip.id])

  return (
    <Container
      variant="tipListCard"
      sx={selected ? { borderColor: 'textSoft' } : {}}
      onClick={!mouseEnterInfo ? onSingleTipClick : () => null}
    >
      <Grid templateColumns={['4.188rem auto 3.125rem', null, null, '4rem auto 3.125rem']} h="full">
        <Box
          w={['4.188rem', null, null, '4rem']}
          minW={['4.188rem', null, null, '4rem']}
          h="full"
          borderLeftRadius={8}
          overflow="hidden"
          pos="relative"
        >
          <Flex justify="center" align="center" h="full">
            {coverImage ? (
              <Image src={coverImage} borderRadius={8} fallbackSrc={images.blank} alt="Cover Image" />
            ) : (
              <Image src={extensionIcon} alt="File Logo" w="64%" h="64%" />
            )}
            {isVideo && (
              <Flex pos="absolute" left={0} right={0} bottom={0} top={0} justify="center" align="center">
                <Image src={images.playVideo} alt="play" w="30%" />
              </Flex>
            )}
          </Flex>
        </Box>
        <Grid
          maxW="full"
          overflowX="hidden"
          px={2}
          py={1}
          templateColumns={['4.688rem auto', null, null, '30% 20% 34% 16%', null, '33% 8% 44% 15%']}
        >
          <Flex
            align={[null, null, null, 'center']}
            maxW={['full', null, null, '100%']}
            overflowX="hidden"
            gridColumn={['1/3', null, null, '1/2']}
          >
            <Heading
              fontSize={[12, null, null, null, 14]}
              lineHeight={['14px', null, null, null, '24px']}
              fontWeight="400"
              mr={0}
              noOfLines={1}
              overflowX="hidden"
            >
              {name || 'Untitled'}
            </Heading>
          </Flex>
          <Flex
            align="center"
            justify={[null, null, null, 'flex-end']}
            pl={[0, null, null, 2]}
            maxW="full"
            w="full"
            overflowX="hidden"
          >
            {sharedMembers && sharedMembers?.length > 0 && (
              <Icon
                as={isMobile ? customIcons.addUserMobile : customIcons.userPlusIcon}
                color="textRegular"
                h={[4, null, null, 3.5]}
                w={[4, null, null, 3.5]}
                mx={1}
                onMouseEnter={() => setMouseEnterInfo(true)}
                onMouseLeave={() => setMouseEnterInfo(false)}
              />
            )}
            {followedNodes?.some((f: FollowedNode) => f.nodeId === id) && (
              <Icon as={icons.follow} color="textRegular" h={[3, null, null, 4]} w={[3, null, null, 4]} mr={1} />
            )}
            {alerts > 0 && <Badge message={alerts} top={0} right={0} absolute={false} />}
          </Flex>
          <Flex maxW="full" align="center" overflowX="hidden">
            {isMobile ? (
              <Text
                fontSize={[12, null, null, null, 14]}
                lineHeight={['14px', null, null, null, '24px']}
                noOfLines={1}
                pl={1}
                justifySelf={[null, null, null, 'flex-end']}
              >
                {updatedBy ? `Modified ${formatTimeString(updatedAt)}` : `Created ${formatTimeString(createdAt)}`}
              </Text>
            ) : (
              <Text
                noOfLines={1}
                pl={[0, null, null, 4]}
                fontSize={[12, null, null, null, 14]}
                lineHeight={['14px', null, null, null, '24px']}
              >
                {updatedBy
                  ? `Modified ${formatTimeString(updatedAt)} by ${updatedBy.firstName} ${updatedBy.lastName}`
                  : `Created ${formatTimeString(createdAt)} by ${owner?.firstName} ${owner?.lastName}`}
              </Text>
            )}
          </Flex>
          {!isMobile && (
            <Flex align="center" justifyContent="flex-end" display={['none', null, null, 'flex']}>
              {totalSize && (
                <Text noOfLines={1} fontSize={[12, null, null, null, 14]}>
                  {formatBytes(parseFloat(String(totalSize)), 2)}
                </Text>
              )}
              <NodeInfoPopover node={tip} setOpenModal={setOpenModal} tags={previousTags}>
                <Icon
                  as={icons.infoIcon}
                  color="textRegular"
                  h={[3, null, null, 5]}
                  w={[3, null, null, 5]}
                  p={0}
                  _hover={{
                    color: 'white',
                  }}
                  onMouseEnter={() => setMouseEnterInfo(true)}
                  onMouseLeave={() => setMouseEnterInfo(false)}
                />
              </NodeInfoPopover>
            </Flex>
          )}
        </Grid>
        <Flex
          onClick={(event) => {
            event.stopPropagation()
          }}
          align="center"
          justify="flex-end"
          pr={4}
          display={isLinkShare && !hasPermission('download', tip, true) ? 'none' : 'flex'}
        >
          {isMobile ? (
            <PopoverModal menuOpen={menuOpen} setMenuOpen={setMenuOpen} iconSize={{ height: 5, width: 5 }}>
              <TipCardMenu
                tip={tip}
                follow={followedNodes?.some((f: FollowedNode) => f.nodeId === id)}
                handleMenuAction={handleMenuAction}
                pageType={pageType}
                noBorder={noBorder}
                isMobile={isMobile}
                isLinkShare={isLinkShare}
              />
            </PopoverModal>
          ) : (
            <IconPopover
              itemId={tip.id}
              menuType="tip"
              icon={icons.menuIcon}
              variantBtn="iconBtnSm"
              iconSize={{
                width: [4, null, null, 5],
                height: [4, null, null, 5],
              }}
              variantPopover="popoverMenu"
              placement="bottom-start"
            >
              <TipCardMenu
                tip={tip}
                follow={followedNodes?.some((f: FollowedNode) => f.nodeId === id)}
                handleMenuAction={handleMenuAction}
                pageType={pageType}
                noBorder={noBorder}
                isLinkShare={isLinkShare}
              />
            </IconPopover>
          )}
        </Flex>
      </Grid>
    </Container>
  )
}

export default TipListCard
