import {
  Box,
  Button,
  Flex,
  FormControl,
  Grid,
  Icon,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import { DatePicker, DueDatePopover, AnimatedMenuChevron } from 'components'
import { endOfMonth, format, startOfMonth, startOfWeek, subDays } from 'date-fns'
import { DateRange, SEARCH_BOOLS, SearchItem } from 'features/search/Search.types'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { icons, images } from 'theme'
import { Node, Tag } from 'types/graphqlSchema'
import ElasticSearchSaveModal from './ElasticSearchSaveModal'
import { GET_SAVED_SEARCHES, UPDATE_SEARCH, GET_SUGGESTED_SEARCHES, FETCH_AVAILABLE_TAGS } from 'features/graphql'
import { useLazyQuery, useMutation } from '@apollo/client'
import TagsMenu from './TagsMenu'

interface ElasticSearchProps {
  project: Node | undefined
  handleChangeProject: Dispatch<SetStateAction<Node | undefined>>
  searchItems: SearchItem[]
  setSearchItems: Dispatch<SetStateAction<SearchItem[]>>
  timeframe: DateRange
  setTimeframe: Dispatch<SetStateAction<DateRange>>
  placeholder?: string
  urgency: string | undefined
  setUrgency: Dispatch<SetStateAction<string | undefined>>
  isFlows?: boolean
}

interface SuggestionsState {
  nodes: string[]
  flows: string[]
  blocks: string[]
  tags: string[]
  users: string[]
}

const emptySuggestionsObject: SuggestionsState = {
  nodes: [],
  flows: [],
  blocks: [],
  tags: [],
  users: [],
}

const ElasticSearch = ({
  project,
  handleChangeProject,
  searchItems,
  setSearchItems,
  timeframe,
  setTimeframe,
  placeholder = 'Search for anything',
  urgency,
  setUrgency,
  isFlows = false,
}: ElasticSearchProps) => {
  const [{ flows, tags, users, blocks, nodes }, setSuggestions] = useState<SuggestionsState>(emptySuggestionsObject)
  const [fetchAvailableTags] = useLazyQuery(FETCH_AVAILABLE_TAGS)
  const [tagsToList, setTagsToList] = useState<Tag[]>([])
  const [selectedTags, setSelectedTags] = useState<Tag[]>([])
  const [getSearchSuggestions, { data: suggestionsData }] = useLazyQuery(GET_SUGGESTED_SEARCHES, {
    onCompleted: () => {
      if (!suggestionsData?.getSuggestions) return
      const { flows, nodes, blocks, tags, users } = suggestionsData.getSuggestions
      type SuggestionType = typeof nodes
      const filterAndSetSuggestions = (sourceArray: SuggestionType[], suggestionType: keyof SuggestionsState) => {
        const filteredArray = sourceArray.filter((option) => option.score > 0.69)

        if (filteredArray.length) {
          setSuggestions((prevSuggestions) => ({
            ...prevSuggestions,
            [suggestionType]: filteredArray.map((opt) => opt.text),
          }))
        }
      }

      filterAndSetSuggestions(nodes, 'nodes')
      filterAndSetSuggestions(flows, 'flows')
      filterAndSetSuggestions(blocks, 'blocks')
      filterAndSetSuggestions(tags, 'tags')
      filterAndSetSuggestions(users, 'users')
    },
  })

  // Populate Tags List
  useEffect(() => {
    const populateTags = async () => {
      const { data: tagsData } = await fetchAvailableTags()
      if (!tagsData || !tagsData.tags) return
      const tagsFromDB = await tagsData.tags
      setTagsToList(tagsFromDB)
    }
    populateTags()
  }, [])

  const [getSavedSearches, { data: savedSearchData }] = useLazyQuery(GET_SAVED_SEARCHES, {
    onCompleted: () => {
      setSavedSearches(savedSearchData.getSavedSearches)
    },
  })

  const [mutateUpdateSearch] = useMutation(UPDATE_SEARCH, {
    onCompleted: () => {
      getSavedSearches()
    },
  })

  type SavedSearchesType = typeof savedSearchData.getSavedSearches
  const searchFieldRef = useRef<HTMLInputElement>(null)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [savedSearches, setSavedSearches] = useState<SavedSearchesType>([])
  const [loadedSearch, setLoadedSearch] = useState({ name: '', id: '' })
  const { isOpen: isOpenSaveModal, onOpen: onOpenSaveModal, onClose: onCloseSaveModal } = useDisclosure()
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false })
  const dateFormat = useBreakpointValue({ base: 'MM/dd/yy', md: 'MM/dd/yyyy', xl: 'MMMM d, yyyy' }) || 'MM/dd/yyyy'

  // STYLES
  const menuItemStyles = {
    bg: 'inherit',
    color: 'textBlack',
    fontFamily: 'Inter',
    fontWeight: 500,

    _hover: { bg: 'menuBackground', color: 'textHighlight', fill: 'textHighlight' },
    _focusVisible: { bg: 'menuBackground', color: 'textHighlight' },
  }

  // INITIAL SAVED SEARCHES LOAD
  useEffect(() => {
    getSavedSearches()
  }, [])

  // PROJECT SELECT SIDE EFFECT
  useEffect(() => {
    if (project) {
      const filteredSearchItems = searchItems.filter((item) => item.type !== 'Project')
      const newProjectItem = {
        item: project.name,
        bool: SEARCH_BOOLS.OR,
        type: 'Project',
        indexes: [],
        id: crypto.randomUUID(),
      }
      setSearchItems([newProjectItem, ...filteredSearchItems])
    } else {
      const filteredSearchItems = searchItems.filter((item) => item.type !== 'Project')
      setSearchItems(filteredSearchItems)
    }
  }, [project])

  // TAG SELECT SIDE EFFECT
  useEffect(() => {
    handleUpdateTags()
  }, [selectedTags.length])

  // GET SEARCH SUGGESTIONS
  useEffect(() => {
    if (!searchTerm) return
    if (searchTerm.length < 2) {
      return setSuggestions(emptySuggestionsObject)
    } else {
      getSearchSuggestions({
        variables: {
          term: searchTerm,
          indexes: ['NODES', 'FLOWS', 'USERS', 'BLOCKS', 'TAGS'],
        },
      })
    }
  }, [searchTerm.length])

  // HANDLER FUNCTIONS

  const handleUpdateTags = () => {
    const project = searchItems.find((item) => item.type === 'Project')
    const filteredSearchItems = searchItems.filter((item) => item.type !== 'Tag' && item.type !== 'Project')
    if (selectedTags.length > 0) {
      const newTagItems = selectedTags.map((tag) => ({
        item: tag.name,
        bool: SEARCH_BOOLS.AND,
        type: 'Tag',
        indexes: [],
        id: crypto.randomUUID(),
      }))

      const updatedSearchItems = project
        ? [project, ...newTagItems, ...filteredSearchItems]
        : [...newTagItems, ...filteredSearchItems]

      setSearchItems(updatedSearchItems)
    } else {
      setSearchItems(project ? [project, ...filteredSearchItems] : filteredSearchItems)
    }
  }

  const handleSubmitSearchItem = (category = 'Free Text', term = '') => {
    let mutableSearchTerm = term || searchTerm.trim()
    if (!mutableSearchTerm) return
    let boolValue = isFlows ? SEARCH_BOOLS.AND : SEARCH_BOOLS.OR
    if (searchTerm.startsWith('AND ')) {
      boolValue = SEARCH_BOOLS.AND
      mutableSearchTerm = searchTerm.slice(4)
    } else if (searchTerm.startsWith('OR ')) {
      boolValue = SEARCH_BOOLS.OR
      mutableSearchTerm = searchTerm.slice(3)
    } else if (searchTerm.startsWith('NOT ')) {
      boolValue = SEARCH_BOOLS.NOT
      mutableSearchTerm = searchTerm.slice(4)
    }

    let indexes
    switch (category) {
      case 'Free Text':
      case 'User':
      case 'Tag':
        indexes = ['NODES', 'BLOCKS', 'FLOWS', 'USERS', 'MESSAGES']
        break
      case 'File':
        indexes = ['BLOCKS']
        break
      case 'Flow':
        indexes = ['FLOWS']
        break
      default:
        console.error('Invalid category')
        return
    }

    const newTerm = {
      item: mutableSearchTerm,
      type: category,
      indexes,
      id: crypto.randomUUID(),
      bool: boolValue,
    }
    setSearchItems([...searchItems, newTerm])
    setSearchTerm('')
  }

  const handleRemoveSearchItem = (id) => {
    const itemToRemove = searchItems.find((item) => item.id === id)
    if (itemToRemove && itemToRemove.type === 'Project') {
      handleChangeProject(undefined)
    }
    if (itemToRemove && itemToRemove.type === 'Tag') {
      const updatedTags = selectedTags.filter((t) => t.name !== itemToRemove.item)
      setSelectedTags(updatedTags)
    }
    const updatedSearchTerms = searchItems.filter((item) => item.id !== id)
    setSearchItems(updatedSearchTerms)
  }

  const handleBoolean = (event, id) => {
    const updatedSearchItems = searchItems.map((item) => {
      if (item.id === id) {
        return { ...item, bool: event.target.value }
      }
      return item
    })
    setSearchItems(updatedSearchItems)
  }

  const handleBackspace = (event: React.KeyboardEvent) => {
    if (event.key !== 'Backspace') return
    if (searchTerm.length > 0) return
    if (searchItems.length === 1) {
      const updatedSearchTerms = searchItems.slice(0, -1)
      setSearchItems(updatedSearchTerms)
      setLoadedSearch({ name: '', id: '' })
      setSelectedTags([])
      return handleChangeProject(undefined)
    }
    const updatedSearchTerms = searchItems.slice(0, -1)
    setSearchItems(updatedSearchTerms)
  }

  const handleLoadSearch = (s) => {
    if (!s) return
    const { name, id } = s
    const { timeframe, urgency, searchItems } = s.searchParameters
    setTimeframe(
      timeframe[0] && timeframe[1]
        ? [new Date(timeframe[0]), new Date(timeframe[1])]
        : timeframe[0] && !timeframe[1]
        ? [new Date(timeframe[0]), undefined]
        : [undefined, undefined],
    )
    setUrgency(urgency || undefined)
    setLoadedSearch({ name, id })
    setSearchItems(searchItems)
  }

  const handleClearSearch = () => {
    setSearchItems([])
    setLoadedSearch({ name: '', id: '' })
    setTimeframe([undefined, undefined])
    setSelectedTags([])
    setUrgency('')
    handleChangeProject(undefined)
  }

  // COMPONENTS
  const BoolDropdown = ({ bool, id }) => {
    const menuOptions = ['AND', 'OR', 'NOT']
    const menuItemStyle = {
      bg: 'menuBackground',
      px: 2,
      py: 1,
      color: 'textHighlight',
    }

    return (
      <Menu matchWidth>
        <MenuButton
          bg="black"
          fontSize={14}
          py={'2px'}
          px={2}
          roundedLeft="lg"
          _focusVisible={{ boxShadow: '0 0 0 1px var(--chakra-colors-borderFocus)' }}
        >
          <Text display={'flex'} alignItems={'center'} gap={1}>
            {bool}
            <icons.FaChevronDown />
          </Text>
        </MenuButton>
        <MenuList minW="max" roundedTopRight={0} bg="menuBackground" py={1} sx={{ marginTop: '-34px' }}>
          {menuOptions.map((option) => (
            <MenuItem
              key={option}
              value={option}
              {...menuItemStyle}
              onClick={(e) => handleBoolean(e, id)}
              _focusVisible={{ color: 'white', fontWeight: 500 }}
            >
              {option}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    )
  }

  const SearchItem = ({ category, term, id, index, bool }) => {
    const termWithCorrectCase = category === 'User' ? term.charAt(0).toUpperCase() + term.slice(1) : term

    return (
      <Flex gap="2px">
        {!!index && <BoolDropdown bool={bool} id={id} />}
        <Tooltip
          hasArrow
          fontSize={'13px'}
          rounded="lg"
          fontWeight={400}
          px=".75rem"
          py=".25rem"
          placement="top"
          label={
            <Text color="textRegular">
              Remove <span style={{ color: 'white', fontWeight: 500 }}>{termWithCorrectCase}</span>?
            </Text>
          }
          bg="black"
          color="white"
          zIndex={11}
        >
          <Text
            as="button"
            onClick={() => handleRemoveSearchItem(id)}
            bg="black"
            fontSize={14}
            py={'2px'}
            px={2}
            roundedLeft={index ? 'initial' : 'lg'}
            roundedRight="lg"
          >
            {category}:{' '}
            <span style={{ color: 'white' }}>
              {term.length < 32 ? termWithCorrectCase : termWithCorrectCase.substring(0, 32) + '...'}
            </span>
          </Text>
        </Tooltip>
      </Flex>
    )
  }

  const SearchPageDropdown = ({ children, title, styles = {} }) => {
    return (
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              as={Button}
              minH="43px"
              px={useBreakpointValue({ base: 2, sm: 0, md: 4 }, { ssr: false })}
              rightIcon={!isMobile ? <AnimatedMenuChevron isOpen={isOpen} /> : undefined}
              borderColor="transparent"
              color="textRegular"
              _hover={{
                color: 'textHighlight',
              }}
              _active={{
                color: 'textHighlight',
              }}
              {...styles}
              fontSize={{ base: '.85rem', md: '15px' }}
              fontWeight={400}
              _focusVisible={{ boxShadow: '0 0 0 1px var(--chakra-colors-borderFocus)' }}
            >
              {title}
            </MenuButton>
            <MenuList minWidth="240px" bg="white" px="1px" py={4}>
              {children}
            </MenuList>
          </>
        )}
      </Menu>
    )
  }

  const TimeframeMenu = () => {
    const [specificDateMenu, toggleSpecificDateMenu] = useState<boolean>(false)
    const [dateRangeMenu, toggleDateRangeMenu] = useState<boolean>(false)
    const [fromDate, setFromDate] = useState<Date | undefined>(undefined)

    const closeSpecificDateMenu = () => {
      if (specificDateMenu) {
        toggleSpecificDateMenu(false)
      }
    }
    const closeDateRangeMenu = () => {
      if (dateRangeMenu) {
        toggleDateRangeMenu(false)
      }
    }

    return (
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              as={Button}
              variant="outline"
              rightIcon={!isMobile ? <AnimatedMenuChevron isOpen={isOpen} marginTop="3px" /> : undefined}
              minH="43px"
              borderColor="transparent"
              color={timeframe[0] ? 'textHighligh' : 'textRegular'}
              _hover={{ color: 'white' }}
              _active={{ color: 'white' }}
              _focusVisible={{ boxShadow: '0 0 0 1px var(--chakra-colors-borderFocus)' }}
              px={useBreakpointValue({ base: 2, md: 4 }, { ssr: false })}
              fontSize={{ base: '.85rem', md: '15px' }}
              fontWeight={400}
              overflow={'hidden'}
            >
              {!timeframe[0] && 'Timeframe'}
              {timeframe[0] && format(timeframe[0], dateFormat)}
              {timeframe[0] && timeframe[1] && ` - ${format(timeframe[1], dateFormat)}`}
            </MenuButton>
            <MenuList minWidth="200px" bg="textHighlight" px="1px" py={4} maxW={'max'}>
              <MenuItem
                {...menuItemStyles}
                px={8}
                value="Today"
                onClick={() => {
                  setTimeframe([undefined, undefined])
                  const newTime = new Date()
                  newTime.setHours(0, 0, 0, 0)
                  setTimeframe([newTime, undefined])
                }}
              >
                Today
              </MenuItem>
              <MenuItem
                {...menuItemStyles}
                px={8}
                onClick={() => {
                  setTimeframe([undefined, undefined])
                  const newTime = new Date()
                  newTime.setHours(23, 59, 59, 999)
                  setTimeframe([startOfWeek(newTime), newTime])
                }}
              >
                This Week
              </MenuItem>
              <MenuItem
                {...menuItemStyles}
                px={8}
                onClick={() => {
                  setTimeframe([undefined, undefined])
                  const newTime = new Date()
                  newTime.setHours(23, 59, 59, 999)
                  setTimeframe([subDays(newTime, 14), newTime])
                }}
              >
                Last 14 days
              </MenuItem>
              <MenuItem
                {...menuItemStyles}
                px={8}
                onClick={() => {
                  setTimeframe([undefined, undefined])
                  const newTime = new Date()
                  newTime.setHours(23, 59, 59, 999)
                  setTimeframe([startOfMonth(newTime), endOfMonth(newTime)])
                }}
              >
                This month
              </MenuItem>
              {/* Specific Submenu */}
              <Menu
                isOpen={specificDateMenu}
                placement={isMobile ? 'bottom' : 'right-start'}
                onClose={closeSpecificDateMenu}
              >
                <MenuButton
                  {...menuItemStyles}
                  icon={<icons.FaChevronRight />}
                  iconSpacing={1}
                  display="flex"
                  flexDir="row-reverse"
                  pl={!isMobile ? '2rem' : 0}
                  closeOnSelect={false}
                  as={MenuItem}
                  onClick={() => toggleSpecificDateMenu(!specificDateMenu)}
                >
                  Specific Date
                </MenuButton>
                <MenuList maxW={'min-content'} minH="min-content" p={0} bg="transparent" border="none" shadow={'none'}>
                  <DatePicker
                    onChange={(newDate) => {
                      if (newDate) {
                        setTimeframe([newDate, undefined])
                      }
                    }}
                    inline
                    selected={timeframe[0]}
                  />
                </MenuList>
              </Menu>
              {/* Range Submenu */}
              <Menu isOpen={dateRangeMenu} placement={isMobile ? 'bottom' : 'left-start'} onClose={closeDateRangeMenu}>
                {({ isOpen }) => (
                  <>
                    <MenuButton
                      {...menuItemStyles}
                      icon={<icons.FaChevronRight />}
                      iconSpacing={1}
                      display="flex"
                      flexDir="row-reverse"
                      pl="2rem"
                      closeOnSelect={false}
                      as={MenuItem}
                      onClick={() => toggleDateRangeMenu(!dateRangeMenu)}
                    >
                      Date Range
                    </MenuButton>
                    <MenuList
                      maxW={'min-content'}
                      minH="min-content"
                      p={0}
                      bg="transparent"
                      border="none"
                      shadow={'none'}
                      minWidth="240px"
                      py="1px"
                      display={isOpen ? 'block' : 'none'}
                    >
                      <Flex
                        gap={{ base: '.5rem', md: '1.75rem' }}
                        flexDir={{ base: 'column', xl: 'row' }}
                        alignItems={'center'}
                        bg="textHighlight"
                        rounded="lg"
                        py={6}
                        px={{ base: 0, sm: 1, lg: 6 }}
                      >
                        <Flex flexDir="column" gap={2}>
                          <Text fontWeight={600} color="textBlack">
                            From
                          </Text>
                          <DueDatePopover
                            selected={fromDate || timeframe[0]}
                            noMinDate
                            onChange={(newDate) => {
                              if (newDate) {
                                setFromDate(newDate)
                              }
                            }}
                          />
                        </Flex>
                        <Flex flexDir="column" gap={2}>
                          <Text fontWeight={600} color="textBlack">
                            To
                          </Text>
                          <DueDatePopover
                            selected={timeframe[1]}
                            noMinDate
                            onChange={(newDate) => {
                              if (newDate) {
                                const newTimeframe: DateRange = fromDate ? [fromDate, newDate] : [timeframe[0], newDate]
                                setTimeframe(newTimeframe)
                              }
                            }}
                          />
                        </Flex>
                      </Flex>
                    </MenuList>
                  </>
                )}
              </Menu>
              <MenuDivider color="borderRegular" mx={8} mb={4} />
              <MenuItem
                {...menuItemStyles}
                px={8}
                onClick={() => {
                  setTimeframe([undefined, undefined])
                }}
              >
                Clear
              </MenuItem>
            </MenuList>
          </>
        )}
      </Menu>
    )
  }

  const SearchItemCreateButton = ({ category, term }) => {
    return (
      <Button
        variant="unstyled"
        display="flex"
        justifyContent="flex-start"
        rounded="lg"
        textAlign={'left'}
        pl="1.25rem"
        fontWeight={400}
        pr="2.25rem"
        py=".5rem"
        gap={'.75rem'}
        _focusVisible={{ boxShadow: '0 0 0 1px var(--chakra-colors-borderFocus)' }}
        onClick={(e) => {
          e.preventDefault()
          handleSubmitSearchItem(category, term)
        }}
      >
        <Text>{category}:</Text>
        <Text color="white">{category === 'User' ? term.charAt(0).toUpperCase() + term.slice(1) : term}</Text>
      </Button>
    )
  }

  const SuggestedSearch = ({ list, category = 'Free Text' }) => {
    if (category === 'Free Text' && list.includes(searchTerm)) return null
    if (list.includes(searchTerm)) {
      return <SearchItemCreateButton category={category} term={searchTerm} />
    }
    return (
      <Box
        display="flex"
        justifyContent="flex-start"
        rounded="lg"
        textAlign={'left'}
        pl="1.25rem"
        fontWeight={400}
        pr="2.25rem"
        py=".5rem"
      >
        <Text mr=".75rem">{category}:</Text>
        <Flex gap=".5rem">
          <Text color="white" fontStyle="italic">
            did you mean
          </Text>
          {list.slice(0, 4).map((term, index) => (
            <Button
              key={index}
              display="flex"
              gap=".5rem"
              variant="unstyled"
              h={'27px'}
              _focusVisible={{ boxShadow: '0 0 0 1px var(--chakra-colors-borderFocus)' }}
              onClick={(e) => {
                e.preventDefault()
                handleSubmitSearchItem(category, term)
              }}
            >
              {index === list.length - 1 && list.length !== 1 && (
                <span style={{ fontWeight: 400, fontStyle: 'italic  ' }}>or</span>
              )}
              <Text color="white">
                <span style={{ borderBottom: '1px solid white' }}>
                  {category === 'User' ? term.charAt(0).toUpperCase() + term.slice(1) : term}
                </span>
              </Text>
              {index !== list.length - 1 && list.length !== 2 && (
                <span style={{ fontWeight: 400, marginLeft: '-6px' }}>,</span>
              )}
            </Button>
          ))}
          ?
        </Flex>
      </Box>
    )
  }
  // ELASTIC SEARCH COMPONENT
  return (
    <>
      <Grid
        bg="menuBackground"
        rounded="lg"
        px="1rem"
        gridTemplateRows={'auto auto'}
        gap=".35rem"
        py="10px"
        borderWidth="1px"
        borderStyle="solid"
        borderColor="borderRegular"
        aria-label="Search Container"
      >
        <Flex gap=".75rem" w="100%">
          <Flex
            aria-label="Search Input"
            minH="43px"
            flex={!isMobile ? 1 : 0}
            mr={0}
            gap={!isMobile ? 2 : 1}
            bg="background"
            rounded="lg"
            alignItems="center"
            pl="1.25rem"
            pr="2.25rem"
            py="6px"
            onClick={() => searchFieldRef.current && searchFieldRef.current.focus()}
            cursor="text"
            pos="relative"
          >
            <Icon h="1.25rem" w="1.25rem" as={icons.search} color={useColorModeValue('black', 'textRegular')} />
            {searchItems.map((item, idx) => (
              <SearchItem
                term={item.item}
                category={item.type}
                key={item.id}
                id={item.id}
                index={idx}
                bool={item.bool}
              />
            ))}
            <FormControl
              onSubmit={(event) => {
                event.preventDefault()
                handleSubmitSearchItem()
              }}
              width={'100%'}
            >
              <Input
                flex={1}
                w="auto"
                variant="unstyled"
                color="textHighlight"
                type="text"
                ref={searchFieldRef}
                value={searchTerm}
                _selection={{ bg: 'cardBackground', color: 'textHighlight' }}
                onKeyDown={handleBackspace}
                onChange={(event) => {
                  setSearchTerm(event.target.value)
                }}
                placeholder={placeholder}
                fontSize={isMobile ? '14px' : '16px'}
                _placeholder={!isMobile ? { color: 'textSoft' } : { color: 'textSoft', fontSize: '14px' }}
                autoFocus
              />
              {!!searchTerm.length && (
                <Flex
                  zIndex={1}
                  h={'auto'}
                  flexDir="column"
                  w="100%"
                  left={0}
                  roundedBottom="lg"
                  pos={'absolute'}
                  bg="background"
                >
                  <SearchItemCreateButton category="Free Text" term={searchTerm} />
                  {nodes.length > 0 && <SuggestedSearch category="Free Text" list={nodes} />}
                  {flows.length > 0 && <SuggestedSearch category="Flow" list={flows} />}
                  {tags.length > 0 && <SuggestedSearch category="Tag" list={tags} />}
                  {blocks.length > 0 && <SuggestedSearch category="File" list={blocks} />}
                  {users.length > 0 && <SuggestedSearch category="User" list={users} />}
                </Flex>
              )}
            </FormControl>
            {!!searchItems.length && (
              <Tooltip
                hasArrow
                fontSize={'13px'}
                rounded="lg"
                fontWeight={400}
                px=".75rem"
                py=".25rem"
                placement="top"
                label={<Text color="white">Clear search?</Text>}
                bg="black"
                color="white"
                zIndex={11}
              >
                <Box as="button" pos="absolute" right={'1.25rem'} onClick={handleClearSearch} top={'.85rem'}>
                  <Image src={images.xGreyCircle} />
                </Box>
              </Tooltip>
            )}
          </Flex>
          {/* SAVED SEARCHES */}
          <SearchPageDropdown
            title={loadedSearch.name || `Saved${!isMobile ? ' searches' : ''}`}
            styles={{ mr: 'auto', backgroundColor: 'var(--chakra-colors-background)' }}
          >
            <MenuItem {...menuItemStyles} px={8} value="#" onClick={onOpenSaveModal}>
              Save or Edit Searches
            </MenuItem>
            {/* Update Loaded Search */}
            {loadedSearch.name && (
              <MenuItem
                {...menuItemStyles}
                px={8}
                value="#"
                onClick={() =>
                  mutateUpdateSearch({
                    variables: {
                      name: loadedSearch.name,
                      updateSavedSearchId: loadedSearch.id,
                      searchParameters: {
                        searchItems,
                        timeframe,
                        urgency,
                      },
                    },
                  })
                }
              >
                Update {loadedSearch.name}?
              </MenuItem>
            )}
            <MenuDivider color="borderRegular" mx={8} mb={4} />
            <MenuGroup title="Saved searches" px={4} color="textBlack" fontWeight={300} fontSize={'.75rem'}>
              {savedSearches.map((s) => (
                <MenuItem
                  key={s.id}
                  {...menuItemStyles}
                  px={8}
                  onClick={() => {
                    handleLoadSearch(s)
                  }}
                >
                  {s.name}
                </MenuItem>
              ))}
            </MenuGroup>
            <MenuDivider color="borderRegular" mx={8} mb={4} />
            <MenuItem {...menuItemStyles} px={8} onClick={handleClearSearch}>
              Clear
            </MenuItem>
          </SearchPageDropdown>
        </Flex>
        <Flex gap={!isMobile ? 2 : 0} pl={!isMobile ? 2 : 0} aria-label={'Tags Container'}>
          <TagsMenu
            tags={tagsToList}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            menuItemStyles={menuItemStyles}
            aria-label={'Tags Menu'}
          />
          <TimeframeMenu />
          <SearchPageDropdown title={urgency || 'Task Status'}>
            <MenuItem {...menuItemStyles} px={8} value="Urgent" onClick={() => setUrgency('Urgent')}>
              Urgent
            </MenuItem>
            <MenuItem {...menuItemStyles} px={8} value="Not Urgent" onClick={() => setUrgency('Not Urgent')}>
              Not Urgent
            </MenuItem>
            <MenuItem {...menuItemStyles} px={8} value="Overdue" onClick={() => setUrgency('Overdue')}>
              Overdue
            </MenuItem>
            <MenuDivider color="borderRegular" mx={8} mb={4} />
            <MenuItem {...menuItemStyles} px={8} value="Overdue" onClick={() => setUrgency(undefined)}>
              Clear
            </MenuItem>
          </SearchPageDropdown>
          <Button
            minH="43px"
            ml={!isMobile ? 2 : 0}
            fontSize={{ base: '.85rem', md: '15px' }}
            fontWeight={400}
            color="textRegular"
            _hover={{ color: 'white' }}
            onClick={handleClearSearch}
            _focusVisible={{ boxShadow: '0 0 0 1px var(--chakra-colors-borderFocus)' }}
          >
            Reset All
          </Button>
        </Flex>
      </Grid>
      <ElasticSearchSaveModal
        getSavedSearches={getSavedSearches}
        searchItems={searchItems}
        savedSearches={savedSearches}
        isOpenSaveModal={isOpenSaveModal}
        onCloseSaveModal={onCloseSaveModal}
        loadedSearch={loadedSearch}
        setLoadedSearch={setLoadedSearch}
        timeframe={timeframe}
        urgency={urgency}
      />
    </>
  )
}
export default ElasticSearch
