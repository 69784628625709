import { useState, useEffect } from 'react'
import { isNil } from 'lodash'
import {
  Box,
  Flex,
  Avatar,
  Text as ChakraText,
  Grid,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useDisclosure,
  Icon,
} from '@chakra-ui/react'
import { Button, IconButton } from 'components'
import { motion } from 'framer-motion'
import { icons, customIcons } from 'theme'
import { formatTimeSince } from 'helpers/utils'
import { getResizedImage } from 'helpers/storage'
import { useMutation } from '@apollo/client'
import { EDIT_COMMENT } from 'features/message/message.schema'
import { EditorContent, useEditor } from '@tiptap/react'
import Document from '@tiptap/extension-document'
import Mention from '@tiptap/extension-mention'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import { MentionSuggestion, mentionSuggestionOptions } from './mentionSuggestionOptions'
import './CommentCard.scss'

const CommentCard = ({ user, mobile, commentObject, handleDeleteComment, sharedUsers }) => {
  const { message, createdAt, sender, id } = commentObject
  const [signedUrl, setSignedUrl] = useState('')
  const [enableEditComment, setEnableEditComment] = useState(false)
  const [editedComment, setEdittedComment] = useState({ message, mentions: [] })
  const edited = commentObject.createdAt !== commentObject.updatedAt
  const { isOpen, onOpen, onClose } = useDisclosure()
  const isEditable = sender.id === user.id
  const senderName = `${sender.firstName} ${sender.lastName}`

  const [mutateEditComment, { loading: isSubmitting }] = useMutation(EDIT_COMMENT, {
    onCompleted: (data) => {
      if (data) {
        setEdittedComment({ message: data.editComment.message, mentions: [] })
      }
    },
  })

  const handleEditComment = async () => {
    if (!editor || isSubmitting) return

    const parseMentions = (html: string) => {
      const doc = new DOMParser().parseFromString(html, 'text/html')
      return Array.from(doc.querySelectorAll('[data-id]')).map((element) => element.getAttribute('data-id'))
    }

    const mentionedUserNames = parseMentions(editor.getHTML())
    const matchingUserIds = mentionedUserNames.length
      ? sharedUsers.filter((user) => mentionedUserNames.includes(user.display)).map((user) => user.id)
      : []

    const input = {
      messageId: commentObject.id,
      message: editor.getHTML(),
      mentions: matchingUserIds,
      messageType: 'COMMENT',
    }

    await mutateEditComment({ variables: { input } })
  }

  const editor = useEditor(
    {
      content: editedComment.message,
      editable: enableEditComment,
      autofocus: true,
      onFocus: () => editor?.commands.focus('end'),
      onUpdate: () => setEdittedComment({ message: editor?.getHTML(), mentions: [] }),
      extensions: [
        Document,
        Paragraph,
        Text,
        Mention.configure({
          HTMLAttributes: {
            class: 'mention',
          },
          suggestion: {
            ...mentionSuggestionOptions,
            items: async ({ query }): Promise<MentionSuggestion[]> => {
              const users: MentionSuggestion[] = sharedUsers.map((user) => ({
                mentionLabel: user.display,
                id: user.id ?? '',
              }))

              return Promise.resolve(
                [...users]
                  .filter((item) => item.mentionLabel.toLowerCase().startsWith(query.toLowerCase()))
                  .slice(0, 5),
              )
            },
          },
        }),
      ],
    },
    [enableEditComment],
  )

  useEffect(() => {
    if (isNil(sender.avatarSecureUrl)) return
    setSignedUrl(getResizedImage(sender.avatarSecureUrl, 'profile.main') || '#')
  }, [signedUrl, sender.avatarSecureUrl])

  const onEditComment = async () => {
    setEnableEditComment(false)
    await handleEditComment()
  }

  return (
    <motion.li
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: 'auto' }}
      exit={{ opacity: 0, height: 0, transition: { opacity: 0.1, height: { delay: 0.4 } } }}
      transition={{ duration: 0.3, opacity: { delay: 0.2 } }}
      key={commentObject.id}
    >
      <Grid
        border="2px solid var(--chakra-colors-borderRegular)"
        rounded={6}
        p="1rem"
        gridTemplateColumns={['2.5rem auto 20px']}
        gridTemplateRows={['auto auto']}
        bg="cardBackground"
        shadow={'md'}
        mb={'1rem'}
      >
        {/* Comment Header */}
        <Box gridColumn="1/2" gridRow="1/2">
          <Avatar name={senderName} size="sm" src={signedUrl} />
        </Box>
        <Flex
          direction="column"
          justify="space-between"
          alignSelf={['flex-start', null, null, 'center']}
          flex={1}
          gridColumn={isEditable ? '2/3' : '2/4'}
          gridRow="1/2"
        >
          <ChakraText fontWeight={600} fontSize={14} noOfLines={1} color="textSoft">
            {senderName}
          </ChakraText>
          <ChakraText color="textSoft" fontWeight={400} fontSize={12}>
            {formatTimeSince(createdAt, 'time')}
          </ChakraText>
        </Flex>

        {/* Edit Menu */}
        {isEditable ? (
          <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement="bottom-end">
            <PopoverTrigger>
              <button style={{ marginTop: '-23px' }}>
                <Icon as={icons.menuIcon} w={[4, null, null, 5]} h={[4, null, null, 5]} color="textHighlight" />
              </button>
            </PopoverTrigger>
            <PopoverContent bg="textHighlight" w="15rem" px={0}>
              <PopoverBody display="flex" flexDir="column" px="3px">
                <IconButton
                  ariaLabel="Edit Comment"
                  onClick={() => {
                    setEnableEditComment(true)
                    onClose()
                  }}
                  icon={icons.rename}
                  iconSize={{ width: 5, height: 4 }}
                  label="Edit"
                  variant="dropdownOptionButton"
                  iconMarginRight={2}
                />
                <IconButton
                  ariaLabel="Delete Comment"
                  onClick={() => {
                    handleDeleteComment(id)
                    onClose()
                  }}
                  icon={icons.deleteIcon}
                  variant="dropdownOptionButton"
                  iconSize={{ width: 5, height: 4 }}
                  label="Delete"
                  iconMarginRight={2}
                />
                {/* </Flex> */}
              </PopoverBody>
            </PopoverContent>
          </Popover>
        ) : (
          <></>
        )}

        {/* Comment Body */}
        <Flex
          alignItems="center"
          justifyContent="space-between"
          alignContent="center"
          gridColumn="1/-1"
          mt="1rem"
          p={enableEditComment ? 2 : undefined}
          outline={enableEditComment ? '2px solid var(--chakra-colors-borderRegular)' : undefined}
        >
          <EditorContent
            className="tiptapCommentCard"
            editor={editor}
            onKeyDown={(e) => {
              if (e.key === 'Escape') {
                setEnableEditComment(false)
              }
            }}
          />
          {mobile && enableEditComment && (
            <Flex pl={2} align="center" justifySelf="flex-end">
              <IconButton
                ariaLabel="Edit Comment"
                onClick={onEditComment}
                variant="sendCommentBtn"
                style={{
                  background: message ? 'accent' : 'cardBackground',
                  width: '30px',
                  height: '30px',
                  minWidth: '30px',
                }}
                icon={customIcons.sendComment}
                iconSize={{ width: '10px', height: '10px' }}
                iconColor={message ? 'white' : 'textRegular'}
                isDisable={!message}
              />
            </Flex>
          )}
        </Flex>
        {/* <Flex gap={2} align="center"> */}
        {enableEditComment && (
          <Flex mt={4} gap={2} align="center">
            <Button
              fontSize="14px"
              px={3}
              py={1}
              minW="max"
              variant="reject"
              onClick={() => {
                setEdittedComment({ message, mentions: [] })
                setEnableEditComment(false)
              }}
              label="Cancel"
            />
            <Button
              minW="max"
              fontSize="14px"
              px={3}
              py={1}
              variant="defaultButton"
              onClick={onEditComment}
              label="Update"
            />
          </Flex>
        )}
        {/* </Flex> */}
        {edited && !enableEditComment && (
          <ChakraText fontSize=".75rem" fontWeight={400} color="folder.darkGray.body" minW="min-content">
            (edited)
          </ChakraText>
        )}
      </Grid>
    </motion.li>
  )
}

export default CommentCard
