import React from 'react'
import { AnimatePresence } from 'framer-motion'
import { Flex } from '@chakra-ui/layout'
import CommentCard from './CommentCard'
import FlowItem from './FlowItem'
import { Flow, Message, User } from 'types/graphqlSchema'
import { scrollbar } from 'theme'

interface CommentListProps {
  comments: Message[]
  user: User
  mobile?: boolean
  sharedUsers: {
    id: string | undefined
    display: string
  }[]
  commentListRef: React.RefObject<HTMLDivElement>
  handleDeleteComment: (id: string) => void
  flows?: Flow[]
}

const CommentList = ({
  comments,
  user,
  mobile,
  sharedUsers,
  commentListRef,
  handleDeleteComment,
  flows = [],
}: CommentListProps) => {
  const combinedItems = [
    ...comments.map((comment) => ({
      objectType: 'comment' as const,
      item: comment,
      createdAt: new Date(comment.createdAt),
    })),
    ...flows.map((flow) => ({
      objectType: 'flow' as const,
      item: flow,
      createdAt: new Date(flow.createdAt),
    })),
  ]

  const sortedItems = combinedItems.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())

  return (
    <Flex as="ul" minH={0} ref={commentListRef} flex={1} overflow="auto" flexDir="column" pr="1rem" sx={scrollbar}>
      <AnimatePresence mode="sync">
        {sortedItems.map(({ objectType, item }) => {
          if (objectType === 'comment') {
            return (
              <CommentCard
                key={item.id}
                user={user}
                mobile={mobile}
                commentObject={item}
                handleDeleteComment={handleDeleteComment}
                sharedUsers={sharedUsers}
              />
            )
          }
          return <FlowItem key={item.id} flow={item} />
        })}
      </AnimatePresence>
    </Flex>
  )
}

export default CommentList
