import { useState } from 'react'
import { useBreakpointValue, useDisclosure } from '@chakra-ui/react'
import { useNodeMenuActions } from 'features/node'
import { UserOrGuest } from '../types/flow-form-types'
import { Node } from 'types/graphqlSchema'

interface UseFlowFormOptions {
  flowRecipientEmails?: string[]
  flowRecipientMembers?: UserOrGuest[]
  flowState: {
    due: Date | null
    name?: string
    type?: string
  }
  attachedProject?: Node
  recipientGroupsLength: number
}

export const useFlowForm = ({
  flowRecipientEmails,
  flowRecipientMembers,
  flowState,
  attachedProject,
  recipientGroupsLength,
}: UseFlowFormOptions) => {
  // Mobile responsiveness
  const isMobile = useBreakpointValue({ base: true, md: false })

  // Pane visibility states
  const [showProjectsPane, setShowProjectsPane] = useState(false)
  const [showTagsPane, setShowTagsPane] = useState(false)
  const [showMessageForm, setShowMessageForm] = useState(false)
  const { isOpen: usersSelectOpen, onOpen: onUsersSelectOpen, onClose: onUsersSelectClose } = useDisclosure()

  // Submission-related states
  const [isSubmitting, setIsSubmitting] = useState(false)

  // Project creation modal
  const { setOpenModal: setOpenCreateProject, openModal } = useNodeMenuActions()

  // Submit button disabled logic
  const submitDisabled =
    (!flowRecipientEmails?.length && !flowRecipientMembers?.length && recipientGroupsLength === 0) ||
    !flowState.due ||
    !attachedProject ||
    !flowState.name ||
    isSubmitting

  return {
    // Responsiveness
    isMobile,

    // Pane visibility
    showProjectsPane,
    setShowProjectsPane,
    showTagsPane,
    setShowTagsPane,
    showMessageForm,
    setShowMessageForm,
    usersSelectOpen,
    onUsersSelectOpen,
    onUsersSelectClose,

    // Secondary Modals
    setOpenCreateProject,
    openModal,

    // Submission and Disabling the Button
    isSubmitting,
    setIsSubmitting,
    submitDisabled,
  }
}
