import React from 'react'
import { Flex } from '@chakra-ui/react'
import { UserGroup } from 'pages/Admin/hooks/useAdmin'
import { flowRecipientTypes, FlowRecipientTypeValue } from '../types/flow-form-types'
import { GroupItem } from 'components'

interface FlowFormGroupListsProps {
  flowType: string
  edit: (string: FlowRecipientTypeValue) => Promise<void>
  inTheLoopGroups: UserGroup[]
  recipientGroups: UserGroup[]
}

const FlowFormGroupLists: React.FC<FlowFormGroupListsProps> = ({
  flowType,
  recipientGroups = [],
  inTheLoopGroups = [],
  edit,
}) => {
  if (recipientGroups.length === 0 && inTheLoopGroups.length === 0) {
    return null
  }

  return (
    <Flex id="Flow Group Lists" flexDir="column" px={6} gap={2}>
      {recipientGroups.map((group) => (
        <GroupItem
          flowEdit={edit}
          key={`recipient-${group.name}`}
          group={group}
          type={flowRecipientTypes[flowType].primary}
        />
      ))}

      {inTheLoopGroups.map((group) => (
        <GroupItem
          flowEdit={edit}
          key={`intheloop-${group.name}`}
          group={group}
          type={flowRecipientTypes[flowType].watcher}
        />
      ))}
    </Flex>
  )
}

export default FlowFormGroupLists
