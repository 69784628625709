import { useCallback, useState, useRef, useEffect } from 'react'
import { pdfjs } from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

const useReactPdf = () => {
  const [numPages, setNumPages] = useState<number | null>(null)
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const containerRef = useRef<HTMLDivElement>(null)
  const pageRefs = useRef<{ [key: number]: HTMLDivElement | null }>({})

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages)
  }
  const toggleFullscreen = useCallback(() => {
    setIsFullscreen(!isFullscreen)
  }, [isFullscreen])

  const updateCurrentPage = useCallback(() => {
    if (!containerRef.current) return

    const container = containerRef.current
    const containerHeight = container.clientHeight

    // Find the page that's most visible in the viewport
    let closestPage = 1
    let closestDistance = Infinity

    Object.entries(pageRefs.current).forEach(([pageNum, ref]) => {
      if (ref) {
        const rect = ref.getBoundingClientRect()
        const pageMiddle = rect.top + rect.height / 2
        const distance = Math.abs(pageMiddle - containerHeight / 2)

        if (distance < closestDistance) {
          closestDistance = distance
          closestPage = parseInt(pageNum)
        }
      }
    })

    setCurrentPage(closestPage)
  }, [])

  useEffect(() => {
    const container = containerRef.current
    if (!container) return

    container.addEventListener('scroll', updateCurrentPage)
    window.addEventListener('resize', updateCurrentPage)

    return () => {
      container.removeEventListener('scroll', updateCurrentPage)
      window.removeEventListener('resize', updateCurrentPage)
    }
  }, [updateCurrentPage, isFullscreen])

  // Update current page after layout changes
  useEffect(() => {
    setTimeout(updateCurrentPage, 100)
  }, [isFullscreen, updateCurrentPage])

  return {
    numPages,
    isFullscreen,
    currentPage,
    toggleFullscreen,
    containerRef,
    pageRefs,
    onDocumentLoadSuccess,
  }
}

export default useReactPdf
